import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { Field, Form, Formik, FormikValues } from "formik";
import { FileTrigger, Button } from "react-aria-components";
import { format, isBefore } from "date-fns";
import * as Yup from "yup";
import ButtonCom from "../../components/Button";
import { publicVisitorApi, visitorApi } from "../../api/visitor";
import { PublicMeeting, type Meeting } from "../../api/meeting";
import ButtonLoad from "../../components/ButtonLoad";
import FieldError from "../../components/FieldError";

import { ReactComponent as IdIcon } from "../../assets/icons/identity_document.svg";
import { ReactComponent as CameraIcon } from "../../assets/icons/photo_camera.svg";
import Checkbox from "../../components/Checkbox";
import Popup, { PopupVariant } from "../../components/Popup";
import { getSessionAccessToken } from "../../lib/session";

interface RegistrationSetting {
  canRegister?: string;
  isRequiredIdSelfie?: boolean;
  id?: string;
}
const RegistrationForm = ({
  meeting,
  participant,
  registrationSetting,
}: {
  meeting: PublicMeeting | undefined;
  participant: string;
  registrationSetting: RegistrationSetting;
}) => {
  const navigate = useNavigate();
  const initialValues = {
    companyId: null,
    selfie: null,
    email: meeting ? participant : "",
    firstName: "",
    lastName: "",
    company: "",
    timeStart: meeting
      ? format(meeting?.timeStart, "yyyy-MM-dd'T'HH:mm")
      : format(new Date(), "yyyy-MM-dd'T'HH:mm"),
    timeEnd: meeting
      ? format(meeting?.timeEnd, "yyyy-MM-dd'T'HH:mm")
      : format(new Date(), "yyyy-MM-dd'T'HH:mm"),
    vehiclePlateNumber: "",
    contactPerson: meeting ? meeting.host : "",
    reason: meeting ? meeting.title : "",
    allowSendImages: false,
  };

  const validationSchema = (values: FormikValues) =>
    Yup.object().shape({
      companyId: Yup.mixed().when([], (companyId, schema) => {
        if (companyId) {
          const isRequiredIdSelfie = registrationSetting.isRequiredIdSelfie;
          return isRequiredIdSelfie
            ? Yup.mixed()
                .test(
                  "fileType",
                  "File must be an jpeg/png type",
                  (value: any) => {
                    if (!value) return true;
                    return (
                      value.type === "image/jpeg" || value.type === "image/png"
                    );
                  },
                )
                .test(
                  "fileSize",
                  "File size must be less than or equal to 10MB",
                  (value: any) => {
                    if (!value) return true;
                    return value.size <= 10485760;
                  },
                )
            : Yup.mixed()
                .nullable()
                .test(
                  "fileType",
                  "File must be an jpeg/png type",
                  (value: any) => {
                    if (!value) return true;
                    return (
                      value.type === "image/jpeg" || value.type === "image/png"
                    );
                  },
                )
                .test(
                  "fileSize",
                  "File size must be less than or equal to 10MB",
                  (value: any) => {
                    if (!value) return true;
                    return value.size <= 10485760;
                  },
                );
        }
        return schema;
      }),
      selfie: Yup.mixed().when([], (selfie, schema) => {
        if (selfie) {
          const isRequiredIdSelfie = registrationSetting.isRequiredIdSelfie;
          return isRequiredIdSelfie
            ? Yup.mixed()
                .test(
                  "fileType",
                  "File must be an jpeg/png type",
                  (value: any) => {
                    if (!value) return true;
                    return (
                      value.type === "image/jpeg" || value.type === "image/png"
                    );
                  },
                )
                .test(
                  "fileSize",
                  "File size must be less than or equal to 10MB",
                  (value: any) => {
                    if (!value) return true;
                    return value.size <= 10485760;
                  },
                )
            : Yup.mixed()
                .nullable()
                .test(
                  "fileType",
                  "File must be an jpeg/png type",
                  (value: any) => {
                    if (!value) return true;
                    return (
                      value.type === "image/jpeg" || value.type === "image/png"
                    );
                  },
                )
                .test(
                  "fileSize",
                  "File size must be less than or equal to 10MB",
                  (value: any) => {
                    if (!value) return true;
                    return value.size <= 10485760;
                  },
                );
        }
        return schema;
      }),
      email: Yup.string()
        .email("Must be a valid email")
        .required("Email is required"),
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      company: Yup.string().optional(),
      vehiclePlateNumber: Yup.string().optional(),
      timeStart: Yup.string().required("Start time is required"),
      timeEnd: Yup.string().required("End time is required"),
      contactPerson: Yup.string().required("Contact Person is required"),
      reason: Yup.string()
        .required("Reason is required")
        .test(
          "time",
          "End Time should be later than the Start Time",
          function (value) {
            const { timeStart, timeEnd } = this.parent;
            if (timeStart && timeEnd) {
              return isBefore(timeStart, timeEnd);
            }
            return true;
          },
        ),
      isAgree: Yup.boolean().optional(),
    });

  const handleSubmit = async (
    values: FormikValues,
    { setSubmitting, resetForm }: any,
  ) => {
    const data = new FormData();

    data.append("timeStart", new Date(values.timeStart).toISOString());
    data.append("timeEnd", new Date(values.timeEnd).toISOString());
    data.append("contactPerson", values.contactPerson);
    data.append("reason", values.reason);
    if (meeting) data.append("meetingId", meeting.id);
    if (values.email !== "") data.append("email", values.email);
    data.append("firstName", values.firstName);
    data.append("lastName", values.lastName);
    data.append("company", values.company);

    if (values.vehiclePlateNumber !== "")
      data.append("vehiclePlateNumber", values.vehiclePlateNumber);
    if (values.allowSendImages) {
      if (values.companyId instanceof File)
        data.append("companyId", values.companyId);
      if (values.selfie instanceof File) data.append("selfie", values.selfie);
    }
    try {
      // @ts-ignore
      const result = await publicVisitorApi.createPublicVisitor(data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "multipart/form-data",
        },
      });

      if (result.success) {
        const sendEmailData = {
          email: [values.email],
          subject: "DHL Visit Confirmation",
          visitorId: result.visitorId as string,
          statusURL: result.statusUrl as string,
          meetingDetails: meeting
            ? {
                meetingId: meeting.id as string,
                title: meeting.title as string,
                host: meeting.host as string,
                timeStart: meeting.timeStart as Date,
                timeEnd: meeting.timeEnd as Date,
                location: meeting.venue as string,
                description: meeting.description as string,
              }
            : undefined,
        };
        const emailResult =
          await publicVisitorApi.sendPublicVisitorEmail(sendEmailData);

        if (emailResult.success) {
          Popup(PopupVariant.SUCCESS, "Successfully Registered");
          navigate("/invited/confirm-email");
        }
      }

      setSubmitting(false);
      resetForm();
    } catch (err: any) {
      setSubmitting(false);
      console.error(err);
      Popup(PopupVariant.ERROR, JSON.stringify(err.response.data));
    }
    setSubmitting(false);
  };
  const formikRef = useRef(initialValues);
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.25 }}
        exit={{ opacity: 0 }}
        className="mb-10 mt-16 w-full gap-2 p-5 text-sm md:w-10/12 lg:w-1/2"
      >
        <Formik
          innerRef={formikRef as any}
          initialValues={initialValues}
          validationSchema={validationSchema(initialValues)}
          onSubmit={handleSubmit}
        >
          {({
            values,
            setFieldValue,
            isSubmitting,
            resetForm,
            submitForm,
            errors,
          }) => (
            <Form action="" className="w-full">
              <div className="flex w-full flex-col gap-2 pb-2">
                <label className="label text-3xl font-bold text-gray-50 drop-shadow-md">
                  Registration Details
                </label>
              </div>
              <div className="flex w-full flex-col gap-4">
                <div className="flex flex-col gap-1">
                  <h3 className="label text-lg font-semibold tracking-wide text-white drop-shadow-md">
                    Basic Information
                  </h3>
                  <hr className="mb-2 h-0.5 border-t-0 bg-primary-100 opacity-100 dark:opacity-50" />
                </div>

                <div className="flex w-full flex-col gap-4">
                  <div className="flex w-full gap-4">
                    <FileTrigger
                      acceptedFileTypes={["image/jpeg", "image/png"]}
                      onSelect={(e: FileList | null) => {
                        if (e) {
                          const files = Array.from(e);
                          let filenames = files.map((file) => file.name);
                          setFieldValue("companyId", files[0]);
                        }
                      }}
                    >
                      <Button className="justify-cente flex w-full flex-col items-center gap-4 rounded-lg border border-gray-300 bg-white p-4 shadow-md">
                        <div className="flex h-full w-full items-center justify-center">
                          {values?.companyId ? (
                            <img
                              alt="Government Issued ID"
                              height={312}
                              width={312}
                              src={`${URL.createObjectURL(values?.companyId)}`}
                            />
                          ) : (
                            <IdIcon className="h-16 min-h-10 w-16 min-w-10 fill-gray-400" />
                          )}
                        </div>
                        <p className="text-sm font-semibold tracking-wide text-gray-500 max-lg:text-xs">
                          Government-Issued ID
                        </p>
                        {errors.companyId && (
                          <FieldError error={errors.companyId} />
                        )}
                      </Button>
                    </FileTrigger>
                    <FileTrigger
                      acceptedFileTypes={["image/jpeg", "image/png"]}
                      onSelect={(e: FileList | null) => {
                        if (e) {
                          const files = Array.from(e);
                          let filenames = files.map((file) => file.name);
                          setFieldValue("selfie", files[0]);
                        }
                      }}
                    >
                      <Button className="card flex w-full flex-col items-center justify-center gap-4 rounded-lg border border-gray-300 bg-white p-4 shadow-md">
                        <div className="flex h-full w-full items-center justify-center">
                          {values?.selfie ? (
                            <img
                              alt="Selfie"
                              height={312}
                              width={312}
                              src={`${URL.createObjectURL(values?.selfie)}`}
                            />
                          ) : (
                            <CameraIcon className="h-16 min-h-10 w-16 min-w-10 fill-gray-400" />
                          )}
                        </div>
                        <p className="text-sm font-semibold tracking-wide text-gray-500 max-lg:text-xs">
                          Selfie
                        </p>
                        {errors.selfie && <FieldError error={errors.selfie} />}
                      </Button>
                    </FileTrigger>
                  </div>
                  <div className="form-control flex flex-col gap-2">
                    <Field
                      id="email"
                      name="email"
                      type="text"
                      className="text-md h-12 rounded-md border border-gray-300 bg-white p-2 font-semibold tracking-wide shadow-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-200 disabled:text-gray-500"
                      placeholder="Email"
                      disabled={meeting}
                    />
                    {errors.email && <FieldError error={errors.email} />}
                  </div>
                  <div className="form-control flex flex-col gap-2">
                    <Field
                      id="firstName"
                      name="firstName"
                      type="text"
                      className="text-md h-12 rounded-md border border-gray-300 bg-white p-2 font-semibold tracking-wide shadow-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-200 disabled:text-gray-500"
                      placeholder="First Name"
                    />
                    {errors.firstName && (
                      <FieldError error={errors.firstName} />
                    )}
                  </div>
                  <div className="form-control flex flex-col gap-2">
                    <Field
                      id="lastName"
                      name="lastName"
                      type="text"
                      className="text-md h-12 rounded-md border border-gray-300 bg-white p-2 font-semibold tracking-wide shadow-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-200 disabled:text-gray-500"
                      placeholder="Last Name"
                    />
                    {errors.lastName && <FieldError error={errors.lastName} />}
                  </div>
                  <div className="form-control flex flex-col gap-2">
                    <Field
                      id="company"
                      name="company"
                      type="text"
                      className="text-md h-12 rounded-md border border-gray-300 bg-white p-2 font-semibold tracking-wide shadow-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-200 disabled:text-gray-500"
                      placeholder="Company"
                    />
                    {errors.company && <FieldError error={errors.company} />}
                  </div>
                </div>

                <div className="flex w-full flex-col gap-4">
                  <div className="flex flex-col gap-1">
                    <h3 className="label text-lg font-semibold tracking-wide text-white drop-shadow-md">
                      Visit Information
                    </h3>
                    <hr className="mb-2 h-0.5 border-t-0 bg-primary-100 opacity-100 dark:opacity-50" />
                  </div>
                  <div className="form-control">
                    <label className="label text-md font-semibold tracking-wide text-white drop-shadow-md md:text-lg">
                      Time of Visit
                    </label>
                    <div className="mt-1 flex flex-col gap-x-4 gap-y-3 md:flex-row">
                      <div className="form-control flex flex-grow flex-col">
                        <label
                          className="label text-md font-semibold tracking-wide text-white drop-shadow-md md:text-lg"
                          htmlFor="timeStart"
                        >
                          Start
                        </label>
                        <Field
                          id="timeStart"
                          name="timeStart"
                          type="datetime-local"
                          className="text-md h-12 rounded-md border border-gray-300 bg-white p-2 font-semibold tracking-wide shadow-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-200 disabled:text-gray-500"
                          disabled={meeting}
                        />
                      </div>
                      <div className="form-control flex flex-grow flex-col">
                        <label
                          className="label text-md font-semibold tracking-wide text-white drop-shadow-md md:text-lg"
                          htmlFor="timeEnd"
                        >
                          End
                        </label>
                        <Field
                          id="timeEnd"
                          name="timeEnd"
                          type="datetime-local"
                          className="text-md h-12 rounded-md border border-gray-300 bg-white p-2 font-semibold tracking-wide shadow-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-200 disabled:text-gray-500"
                          disabled={meeting}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col gap-2">
                      {errors.timeStart && (
                        <FieldError error={errors.timeStart} />
                      )}
                      {errors.timeEnd && <FieldError error={errors.timeEnd} />}
                    </div>
                  </div>

                  <div className="form-control flex flex-col gap-2">
                    <Field
                      id="contactPerson"
                      name="contactPerson"
                      type="text"
                      className="text-md h-12 rounded-md border border-gray-300 bg-white p-2 font-semibold tracking-wide shadow-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-200 disabled:text-gray-500"
                      placeholder="Contact Person"
                      disabled={meeting}
                    />
                    {errors.contactPerson && (
                      <FieldError error={errors.contactPerson} />
                    )}
                  </div>
                  <div className="form-control flex flex-col gap-2">
                    <Field
                      id="reason"
                      name="reason"
                      type="text"
                      className="text-md h-12 rounded-md border border-gray-300 bg-white p-2 font-semibold tracking-wide shadow-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-200 disabled:text-gray-500"
                      placeholder="Reason"
                      disabled={meeting}
                    />
                    {errors.reason && <FieldError error={errors.reason} />}
                  </div>
                  <div className="form-control flex flex-col gap-2">
                    <Field
                      id="vehiclePlateNumber"
                      name="vehiclePlateNumber"
                      type="text"
                      className="text-md h-12 rounded-md border border-gray-300 bg-white p-2 font-semibold tracking-wide shadow-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-200 disabled:text-gray-500"
                      placeholder="Vehicle Plate Number"
                    />
                    {errors.vehiclePlateNumber && (
                      <FieldError error={errors.vehiclePlateNumber} />
                    )}
                  </div>
                </div>

                <div className="form-control text-md flex items-start justify-center gap-x-4 rounded-md border border-gray-300 bg-white p-3 shadow-md">
                  <Checkbox id="c-1">
                    <Checkbox.Indicator
                      disabled={false}
                      onChange={(value) =>
                        setFieldValue("allowSendImages", value)
                      }
                    />
                    <Checkbox.Label className="mr-auto max-w-[45em] lg:ml-8">
                      I acknowledge and agree to the following: I consent to the
                      use of my photos solely for verification purposes only. I
                      understand that these photos will not be used for any
                      other purposes without my explicit consent. I further
                      understand that my photos will be securely stored and
                      processed in accordance with applicable privacy laws and
                      regulations.
                    </Checkbox.Label>
                  </Checkbox>
                  {errors.allowSendImages && (
                    <FieldError error={errors.allowSendImages} />
                  )}
                </div>
              </div>

              <div className="mt-20 flex h-14 w-full justify-center gap-10 md:mt-12">
                <ButtonCom
                  className={`border border-accent-600 bg-accent-500 text-white hover:bg-accent-600`}
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? <ButtonLoad /> : <p>Register</p>}
                </ButtonCom>
              </div>
            </Form>
          )}
        </Formik>
      </motion.div>
    </AnimatePresence>
  );
};

export default RegistrationForm;
