import { Outlet } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";

const defaultHeaderItems = [
  // {
  //   to: "/registration-details",
  //   name: "Register",
  //   hidden: false,
  // },
  {
    icon: undefined,
    to: "/login",
    name: "Login",
    hidden: false,
    onItemClick: undefined,
  },
];

const defaultFooterItems = [
  {
    to: "/dashboard",
    name: "Dashboard",
  },
  {
    to: "https://www.dhl.com/ph-en/home/footer/terms-of-use.html",
    name: "Terms of Service",
  },
  {
    to: "https://www.dhl.com/ph-en/home/footer/privacy-notice.html",
    name: "Privacy Policy",
  },
];

const Layout = () => {
  return (
    <div className="relative min-h-dvh">
      <Header
        headerItems={defaultHeaderItems}
        burgerMenuItems={defaultHeaderItems}
      />
      <Outlet />
      <Footer footerItems={defaultFooterItems} />
    </div>
  );
};

export default Layout;
