import { useState, useEffect } from "react";
import {
  useNavigate,
  useParams,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { isAfter } from "date-fns";
import { type Visitor, visitorApi, publicVisitorApi } from "../../api/visitor";
import {
  Meeting,
  PublicMeeting,
  meetingsApi,
  publicMeetingApi,
} from "../../api/meeting";
import {
  type AllowedArea,
  type Area,
  areasApi,
  publicAreaApi,
} from "../../api/areas";
import Loading from "../../components/Loading";
import { formatDatetimeRange } from "../../lib/utils";
import DashboardStatus from "./DashboardStatus";
import PublicStatus from "./PublicStatus";
import Popup, { PopupVariant } from "../../components/Popup";
import { getSessionAccessToken } from "../../lib/session";

const Status = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [visitor, setVisitor] = useState<Visitor | undefined>(undefined);
  const [meeting, setMeeting] = useState<Meeting | PublicMeeting | undefined>(
    undefined,
  );
  const [areas, setAreas] = useState<Area[]>([]);
  const [allowedAreas, setAllowedAreas] = useState<AllowedArea[]>([]);
  const { id } = useParams();
  let [searchParams] = useSearchParams();
  const [isFetching, setIsFetching] = useState(true);
  const [isExpired, setIsExpired] = useState(false);
  const [timeValidity, setTimeValidity] = useState("");

  const fetchData = async () => {
    const visitor = await publicVisitorApi.getPublicVisitor({
      params: { id: id as string },
    });
    if (visitor) {
      setVisitor(visitor);
    }

    setIsExpired(isAfter(new Date(), visitor.timeEnd));

    const areas = await publicAreaApi.getPublicAreas();

    if (areas) {
      setAreas(areas);
    }

    const _allowedAreas = await publicAreaApi.getPublicAllowedAreasByVisitorId({
      params: { id: id as string },
    });

    if (_allowedAreas) {
      setAllowedAreas(_allowedAreas);
    }

    if (!location.pathname.startsWith("/dashboard/status")) {
      if (visitor.meetingId) {
        const meetingDetails = await publicMeetingApi.getPublicMeeting({
          params: { id: visitor.meetingId },
        });
        if (meetingDetails) {
          setMeeting(meetingDetails);
          setTimeValidity(
            formatDatetimeRange(
              meetingDetails.timeStart,
              meetingDetails.timeEnd,
            ),
          );
        }
      }
    }

    setIsFetching(false);
  };

  const confirmVisitor = async () => {
    if (searchParams.has("confirmKey")) {
      const response = await publicVisitorApi.confirmVisitor(undefined, {
        params: { confirmKey: searchParams.get("confirmKey") as string },
      });

      if (response.success) {
        Popup(PopupVariant.SUCCESS, "Successfully Verified");
        fetchData();
      }
    }
  };

  useEffect(() => {
    if (!id) {
      navigate("/");
    }
    fetchData().catch((err: any) => {
      console.error(err);
      Popup(PopupVariant.ERROR, err.response?.data?.error?.message);
    });
    confirmVisitor().catch((err: any) => {
      console.error(err);
      Popup(PopupVariant.ERROR, err.response?.data?.error?.message);
    });
  }, []);

  const goBack = () => navigate(-1);

  return (
    <>
      {isFetching ? (
        <Loading />
      ) : location.pathname.startsWith("/dashboard/status/") ? (
        <DashboardStatus
          visitor={visitor as Visitor}
          areas={areas}
          allowedAreas={allowedAreas}
          setAllowedAreas={setAllowedAreas}
          isExpired={isExpired}
          fetchData={fetchData}
        />
      ) : (
        <PublicStatus
          visitor={visitor as Visitor}
          meeting={meeting}
          areas={areas}
          allowedAreas={allowedAreas}
          isExpired={isExpired}
          timeValidity={timeValidity}
          goBack={goBack}
        />
      )}
    </>
  );
};

export default Status;
