import { useRef, useState, useEffect } from "react";
import { Formik, Form, FormikValues } from "formik";
import * as Yup from "yup";
import ButtonCom from "../../../components/Button";
import { FileTrigger, Button } from "react-aria-components";
import { format } from "date-fns";
import Table from "../../../components/Table";
import ButtonLoad from "../../../components/ButtonLoad";
import { EmailTemplate, emailTemplateApi } from "../../../api/emailTemplate";
import { getSessionAccessToken } from "../../../lib/session";
import Popup, { PopupVariant } from "../../../components/Popup";

const SignInEmail = () => {
  const singInEmailColumns = [
    {
      accessorKey: "fileName",
      header: "File Name",
      cell: (props: any) => <p>{props.getValue()}</p>,
    },
    {
      accessorKey: "dateAdded",
      header: "Date Added",
      cell: (props: any) => (
        <p>{format(props.getValue(), "MMMM dd, yyyy hh:mm a")}</p>
      ),
    },
    {
      header: "Actions",
      cell: (props: any) => (
        <div className="flex flex-row place-items-center justify-center gap-2">
          <div className="w-40">
            {!props.row.original.isCurrent ? (
              <ButtonCom
                className={`bg-accent-500 text-sm
               text-white
              hover:bg-accent-600`}
                onClick={() =>
                  newTemplate(
                    props.row.original.id,
                    props.row.original.fileName,
                  )
                }
              >
                Use As Template
              </ButtonCom>
            ) : (
              ""
            )}
          </div>
          <div className="w-40">
            <ButtonCom
              className="border border-gray-300 bg-gray-100 text-sm text-black hover:bg-gray-200"
              onClick={() =>
                deleteTemplate(
                  props.row.original.id,
                  props.row.original.fileName,
                )
              }
            >
              Delete
            </ButtonCom>
          </div>
        </div>
      ),
    },
  ];
  const [signInEmailTemplates, setSignInEmailTemplates] = useState<
    EmailTemplate[]
  >([]);
  const initialValues = { emailTemplateFile: null };
  const validationSignInEmailTemplateSchema = Yup.object().shape({
    signInEmailTemplateFile: Yup.mixed()
      .test("fileType", "File must be an html type", (value: any) => {
        if (!value) return true;
        return value.type === "text/html";
      })
      .test(
        "fileSize",
        "File size must be less than or equal to 10MB",
        (value: any) => {
          if (!value) return true;
          return value.size <= 10485760;
        },
      )
      .required("Email Template is required"),
  });
  const fetchSignInEmailTemplateData = async () => {
    try {
      const response = await emailTemplateApi.getSignInTemplate({
        headers: getSessionAccessToken(),
      });
      if (response) {
        setSignInEmailTemplates(response);
      }
    } catch (err) {
      console.error(err);
      Popup(PopupVariant.ERROR);
    }
  };
  const newTemplate = async (id: string, fileName: string) => {
    const result = await Popup(
      PopupVariant.CONFIRM,
      `Do you want to use ${signInEmailTemplates.find((signInEmailTemplate) => signInEmailTemplate.id === id)?.fileName} as the default template?`,
    );
    if (result.isConfirmed) {
      try {
        const data = {
          id: id,
          fileName: fileName,
        };

        const response = await emailTemplateApi.useAsSignInTemplate(
          JSON.parse(JSON.stringify(data)),
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
              ...getSessionAccessToken(),
            },
          },
        );
        if (response.success) {
          fetchSignInEmailTemplateData();
          Popup(PopupVariant.SUCCESS, "Successfully Used.");
        }
      } catch (err) {
        console.log(err);
        Popup(PopupVariant.ERROR);
      }
    }
  };

  const deleteTemplate = async (id: string, fileName: string) => {
    const result = await Popup(
      PopupVariant.CONFIRM,
      `Do you want to delete ${signInEmailTemplates.find((signInEmailTemplate) => signInEmailTemplate.id === id)?.fileName}?`,
    );

    if (result.isConfirmed) {
      try {
        const data = {
          id: id,
          fileName: fileName,
        };
        const response = await emailTemplateApi.deleteSignInTemplate(
          JSON.parse(JSON.stringify(data)),
          {
            headers: getSessionAccessToken(),
          },
        );
        if (response.success) {
          fetchSignInEmailTemplateData();
          Popup(PopupVariant.SUCCESS, "Successfully Deleted.");
        }
      } catch (err) {
        console.log(err);
        Popup(PopupVariant.ERROR);
      }
    }
  };

  const submitSignInEmailTemplate = async (
    values: FormikValues,
    { setSubmitting, resetForm }: any,
  ) => {
    try {
      const data = new FormData();
      data.append("emailTemplateFile", values.signInEmailTemplateFile);
      // @ts-ignore
      const response = await emailTemplateApi.addSignInTemplate(data, {
        headers: getSessionAccessToken(),
      });
      if (response.success) {
        Popup(PopupVariant.SUCCESS, "Successfully Added.");

        fetchSignInEmailTemplateData();
        resetForm();
      }
    } catch (err) {
      console.log(err);
      Popup(PopupVariant.ERROR);
    }
    setSubmitting(false);
  };
  const downloadTemplate = async () => {
    try {
      const response = await emailTemplateApi.downloadSignInTemplate({
        headers: getSessionAccessToken(),
      });
      if (response.success) {
        const file = new Blob([response.file], {
          type: "application/octet-stream",
        });
        const url = window.URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "sign-in-email-template.html");
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
      }
    } catch (err) {
      console.log(err);
      Popup(PopupVariant.ERROR);
    }
  };

  useEffect(() => {
    fetchSignInEmailTemplateData();
  }, []);

  const formikRef = useRef(initialValues);
  return (
    <div>
      <Formik
        innerRef={formikRef as any}
        initialValues={initialValues}
        validationSchema={validationSignInEmailTemplateSchema}
        onSubmit={submitSignInEmailTemplate}
      >
        {({
          values,
          setFieldValue,
          isSubmitting,
          resetForm,
          submitForm,
          errors,
        }: any) => (
          <Form className="w-full">
            <div className="flex flex-col gap-3">
              <div className="flex place-items-center justify-between">
                <p className="text-xl font-semibold">Sign-In Email Template</p>
                <div className="w-40">
                  <ButtonCom
                    type="button"
                    className="bg-accent-500 text-sm text-white hover:bg-accent-600"
                    onClick={() => downloadTemplate()}
                  >
                    Download Template
                  </ButtonCom>
                </div>
              </div>
              <div className="flex flex-col gap-x-5 gap-y-2 lg:flex-row lg:place-items-center">
                <label
                  htmlFor="signInEmailTemplateFile"
                  className="label text-md font-semibold text-gray-400"
                >
                  Add Email Template :
                </label>
                <div className="flex gap-x-5 md:gap-x-10 lg:gap-x-20">
                  <div className="flex flex-col">
                    <FileTrigger
                      acceptedFileTypes={["text/html"]}
                      onSelect={(e: FileList | null) => {
                        if (e) {
                          const files = Array.from(e);
                          let filenames = files.map((file) => file.name);
                          setFieldValue("signInEmailTemplateFile", files[0]);
                        }
                      }}
                    >
                      <Button
                        className={
                          "w-52 rounded-lg border border-gray-300 bg-gray-100 px-5 py-2 text-sm text-black hover:bg-gray-200"
                        }
                      >
                        {values.signInEmailTemplateFile ? (
                          values.signInEmailTemplateFile.name
                        ) : (
                          <p className="text-md font-semibold text-gray-400">
                            Select Html File
                          </p>
                        )}
                      </Button>
                    </FileTrigger>
                    {errors.signInEmailTemplateFile && (
                      <p className="text-red-500">
                        {errors.signInEmailTemplateFile}
                      </p>
                    )}
                  </div>
                  <div className="w-28 md:w-52">
                    <ButtonCom
                      type="submit"
                      className="bg-accent-500 text-white hover:bg-accent-600"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? <ButtonLoad /> : <p>Submit</p>}
                    </ButtonCom>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <Table
        data={signInEmailTemplates}
        columns={singInEmailColumns}
        onItemClicked={() => {}}
        noDataElement={<p>No Email Templates Found.</p>}
      />
    </div>
  );
};

export default SignInEmail;
