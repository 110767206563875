import { z } from "zod";
import { Zodios, makeApi, makeEndpoint } from "@zodios/core";
import { stringToValidDate } from "../lib/utils";
import { VMS_API_SERVER_URL } from "../lib/env";
import { errors } from "../types/Errors";

const fileUploadSchema = z.custom((value) => {
  // Check if the value is a Blob or FormData
  if (value instanceof Blob || value instanceof FormData) {
    return { success: true, data: value };
  } else {
    return { success: false, message: "Invalid file upload data type" };
  }
});

export const visitorSchema = z.object({
  id: z.string().cuid2(),
  email: z.string().email().optional(),
  firstName: z.string(),
  lastName: z.string(),
  timeStart: stringToValidDate,
  timeEnd: stringToValidDate,
  arrivalTime: stringToValidDate.optional(),
  departureTime: stringToValidDate.optional(),
  company: z.string().optional(),
  vehiclePlateNumber: z.string().optional(),
  contactPerson: z.string(),
  reason: z.string(),
  companyId: z.string().optional(),
  selfie: z.string().optional(),
  status: z.number().optional(),
  createdAt: stringToValidDate.optional(),
  meetingId: z.string().cuid2().optional(),
  participantId: z.string().cuid2().optional(),
});

export type Visitor = z.infer<typeof visitorSchema>;

export const addVisitorSchema = z.object({
  email: z.string().email().optional(),
  firstName: z.string(),
  lastName: z.string(),
  timeStart: stringToValidDate,
  timeEnd: stringToValidDate,
  company: z.string().optional(),
  vehiclePlateNumber: z.string().optional(),
  contactPerson: z.string(),
  reason: z.string(),
  companyId: fileUploadSchema.optional(),
  selfie: fileUploadSchema.optional(),
  meetingId: z.string().cuid2().optional(),
  participantId: z.string().cuid2().optional(),
});

export type AddVisitor = z.infer<typeof addVisitorSchema>;

export const sendEmailSchema = z.object({
  email: z.array(z.string().email()),
  subject: z.string(),
  visitorId: z.string().cuid2(),
  statusURL: z.string(),
  meetingDetails: z
    .object({
      meetingId: z.string().cuid2().optional(),
      title: z.string().optional(),
      host: z.string().optional(),
      timeStart: stringToValidDate.optional(),
      timeEnd: stringToValidDate.optional(),
      location: z.string().optional(),
      description: z.string().optional(),
    })
    .optional(),
});
export type SendEmail = z.infer<typeof sendEmailSchema>;

export const visitorApi = new Zodios(VMS_API_SERVER_URL, [
  {
    method: "get",
    path: "/visitors",
    alias: "getVisitors",
    description: "Get all visitors",
    response: z.array(visitorSchema),
    errors: errors,
  },
  {
    method: "get",
    path: "/visitors/:id",
    alias: "getVisitorById",
    description: "Get a Visitor by ID",
    parameters: [
      {
        name: "id",
        type: "Path",
        description: "Visitor ID",
        schema: z.string().cuid2(),
      },
    ],
    response: visitorSchema,
    errors: errors,
  },
  {
    method: "post",
    path: "/visitors",
    alias: "addVisitor",
    description: "Add New Visitor",
    response: z.object({
      success: z.boolean(),
      visitorId: z.string().cuid2(),
      statusUrl: z.string(),
    }),
    errors: errors,
  },
  {
    method: "post",
    path: "/visitors/sendEmail",
    alias: "sendEmail",
    description: "Send Email",
    parameters: [
      {
        name: "sendEmail",
        type: "Body",
        description: "Email Details",
        schema: sendEmailSchema,
      },
    ],
    response: z.object({
      success: z.boolean(),
    }),
    errors: errors,
  },
  {
    method: "post",
    path: "/visitors/confirm/:confirmKey",
    alias: "confirmVisitor",
    description: "Confirm Visitor",
    parameters: [
      {
        name: "confirmKey",
        type: "Path",
        description: "Confirmation Key",
        schema: z.string(),
      },
    ],
    response: z.object({
      success: z.boolean(),
      visitorId: z.string().cuid2(),
    }),
    errors: errors,
  },
  {
    method: "put",
    path: "/visitor/update",
    alias: "updateVisitor",
    description: "Update Visitor",
    parameters: [
      {
        name: "Visitor",
        type: "Body",
        description: "Visitor Details",
        schema: z.object({
          id: z.string().cuid2(),
          status: z.number(),
        }),
      },
    ],
    response: z.object({
      success: z.boolean(),
    }),
    errors: errors,
  },
]);

// Public API

//Define Schemas
export const createPublicVisitorSchema = addVisitorSchema;

export type CreatePublicVisitor = z.infer<typeof createPublicVisitorSchema>;

export const sendPublicVisitorEmailSchema = sendEmailSchema;

export type SendPublicVisitorEmail = z.infer<
  typeof sendPublicVisitorEmailSchema
>;

const getPublicVisitorEndpoint = makeEndpoint({
  method: "get",
  path: "/public/visitors/:id",
  alias: "getPublicVisitor",
  description: "Get public Visitor by ID",
  parameters: [
    {
      name: "id",
      type: "Path",
      description: "Visitor ID",
      schema: z.string().cuid2(),
    },
  ],
  response: visitorSchema,
  errors: errors,
});

const createPublicVisitorEndpoint = makeEndpoint({
  method: "post",
  path: "/public/visitors",
  alias: "createPublicVisitor",
  description: "Add New Public Visitor",
  response: z.object({
    success: z.boolean(),
    visitorId: z.string().cuid2(),
    statusUrl: z.string(),
  }),
  errors: errors,
});

const sendPublicVisitorEmailEndpoint = makeEndpoint({
  method: "post",
  path: "/public/visitors/sendEmail",
  alias: "sendPublicVisitorEmail",
  description: "Send Email",
  parameters: [
    {
      name: "sendEmail",
      type: "Body",
      description: "Email Details",
      schema: sendPublicVisitorEmailSchema,
    },
  ],
  response: z.object({
    success: z.boolean(),
  }),
  errors: errors,
});

const confirmVisitorEndpoint = makeEndpoint({
  method: "post",
  path: "/public/visitors/confirm/:confirmKey",
  alias: "confirmVisitor",
  description: "Confirm Visitor",
  parameters: [
    {
      name: "confirmKey",
      type: "Path",
      description: "Confirmation Key",
      schema: z.string(),
    },
  ],
  response: z.object({
    success: z.boolean(),
    visitorId: z.string().cuid2(),
  }),
  errors: errors,
});

const publicApi = makeApi([
  getPublicVisitorEndpoint,
  createPublicVisitorEndpoint,
  sendPublicVisitorEmailEndpoint,
  confirmVisitorEndpoint,
]);

export const publicVisitorApi = new Zodios(VMS_API_SERVER_URL, publicApi, {
  axiosConfig: {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  },
});
