import {
  CheckCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/20/solid";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Button from "./Button";

export enum PopupVariant {
  SUCCESS = "success",
  ERROR = "error",
  INFO = "info",
  CONFIRM = "confirm",
  FORM = "form",
}

export default async function Popup(
  variant: PopupVariant,
  text: string | undefined = undefined,
) {
  let html;
  switch (variant) {
    case PopupVariant.SUCCESS:
      html = (
        <div className="rounded-md bg-green-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <CheckCircleIcon
                className="h-5 w-5 text-green-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium text-green-800">{text}</p>
            </div>
          </div>
        </div>
      );
      break;
    case PopupVariant.ERROR:
      html = (
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon
                className="h-5 w-5 text-red-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium text-red-800">
                {text || "Something went wrong. Please try again."}
              </p>
            </div>
          </div>
        </div>
      );
      break;
    case PopupVariant.INFO:
      html = (
        <div className="rounded-md bg-gray-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <InformationCircleIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium text-gray-800">{text}</p>
            </div>
          </div>
        </div>
      );
      break;
    case PopupVariant.CONFIRM:
      // return await Swal.fire({
      //   title: "Confirmation",
      //   text: text,
      //   showCancelButton: true,
      //   confirmButtonColor: "#D40511",
      //   cancelButtonColor: colors.gray[200],
      //   confirmButtonText: "Confirm",
      //   cancelButtonText: `<p style="color:black; font-weight:600;">Cancel</p>`,
      //   reverseButtons: true,
      // });
      html = (
        <div className="rounded-md bg-white p-8">
          <div className="flex flex-col items-center gap-4">
            <div className="flex flex-shrink-0 flex-col items-center">
              <ExclamationTriangleIcon
                className="h-12 w-12 text-yellow-400"
                aria-hidden="true"
              />
              <h1 className="text-2xl font-black text-yellow-800">
                Confirmation
              </h1>
            </div>
            <div className="ml-3">
              <p className="font-medium text-gray-800">{text}</p>
            </div>
            <div className="flex w-full gap-4">
              <Button
                className="border border-gray-300 bg-white text-black hover:bg-gray-300"
                onClick={Swal.clickCancel}
              >
                Cancel
              </Button>
              <Button
                className="bg-accent-500 text-white hover:bg-accent-600"
                onClick={Swal.clickConfirm}
              >
                Confirm
              </Button>
            </div>
          </div>
        </div>
      );
      return withReactContent(Swal).fire({
        html: html,
        background: "transparent",
        showCancelButton: false,
        showConfirmButton: false,
      });
    case PopupVariant.FORM:
      break;
    default:
      break;
  }

  return withReactContent(Swal).fire({
    html: html,
    background: "transparent",
    backdrop: false,
    showCancelButton: false,
    showConfirmButton: false,
    position: "top",
    timer: 3000,
  });
}
