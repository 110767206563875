import { z } from "zod";
import { makeApi, makeEndpoint, Zodios } from "@zodios/core";
import { VMS_API_SERVER_URL } from "../lib/env";
import { errors } from "../types/Errors";

// Define schemas
export const settingsSchema = z.object({
  canRegister: z.string(),
  isRequiredIdSelfie: z.boolean(),
  isAutoAcceptInvitedVisitor: z.boolean(),
  serverUrl: z.string().url(),
  domainName: z.string().url(),
  smtpHost: z.string(),
  smtpName: z.string(),
  smtpUsername: z.string(),
  smtpPassword: z.string(),
  msTenantId: z.string(),
  msClientId: z.string(),
  msClientSecret: z.string(),
});

export type Settings = z.infer<typeof settingsSchema>;

// Define endpoints

// Get settings endpoint
const getSettingsEndpoint = makeEndpoint({
  method: "get",
  path: "/settings",
  alias: "getSettings",
  description: "Get Settings",
  response: z.object({
    success: z.boolean(),
    settings: settingsSchema,
  }),
  errors: errors,
});

// Update settings endpoint
const updateSettingsEndpoint = makeEndpoint({
  method: "put",
  path: "/settings",
  alias: "updateSettings",
  description: "Update Settings",
  parameters: [
    {
      name: "Settings",
      type: "Body",
      description: "Settings",
      schema: settingsSchema,
    },
  ],
  response: z.object({
    success: z.boolean(),
    message: z.string(),
  }),
  errors: errors,
});

// Combine all endpoints into the API
const api = makeApi([getSettingsEndpoint, updateSettingsEndpoint]);

export const settingsApi = new Zodios(VMS_API_SERVER_URL, api, {
  axiosConfig: {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  },
});

// Public

// Define schemas
export const publicSettingsSchema = settingsSchema.pick({
  canRegister: true,
  isRequiredIdSelfie: true,
  serverUrl: true,
  domainName: true,
});

// Get public settings endpoint
const getPublicSettingsEndpoint = makeEndpoint({
  method: "get",
  path: "/public/settings",
  alias: "getPublicSettings",
  description: "Get Public Settings",
  response: z.object({
    success: z.boolean(),
    settings: publicSettingsSchema,
  }),
  errors: errors,
});

// Combine all public endpoints into the API
const publicApi = makeApi([getPublicSettingsEndpoint]);

export const publicSettingsApi = new Zodios(VMS_API_SERVER_URL, publicApi, {
  axiosConfig: {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  },
});
