import { useState, useEffect } from "react";
import { format } from "date-fns";

import { type Visitor, visitorApi } from "../../../api/visitor";
import Table from "../../../components/Table";
import { useNavigate } from "react-router-dom";
import Popup, { PopupVariant } from "../../../components/Popup";
import { getSession, getSessionAccessToken } from "../../../lib/session";

const columns = [
  {
    accessorKey: "email",
    header: "Email",
    cell: (props: any) => <p>{props.getValue()}</p>,
  },
  {
    accessorKey: "firstName",
    header: "First Name",
    cell: (props: any) => <p>{props.getValue()}</p>,
  },
  {
    accessorKey: "lastName",
    header: "Last Name",
    cell: (props: any) => <p>{props.getValue()}</p>,
  },
  {
    accessorKey: "timeStart",
    header: "Start Time",
    cell: (props: any) => <p>{format(props.getValue(), "MM/dd/yyyy HH:mm")}</p>,
  },
  {
    accessorKey: "timeEnd",
    header: "End Time",
    cell: (props: any) => <p>{format(props.getValue(), "MM/dd/yyyy HH:mm")}</p>,
  },
  {
    accessorKey: "company",
    header: "Company",
    cell: (props: any) => <p>{props.getValue()}</p>,
  },
  {
    accessorKey: "vehiclePlateNumber",
    header: "Vehicle Plate Number",
    cell: (props: any) => <p>{props.getValue()}</p>,
  },
  {
    accessorKey: "contactPerson",
    header: "Contact Person",
    cell: (props: any) => <p>{props.getValue()}</p>,
  },
  {
    accessorKey: "reason",
    header: "Reason",
    cell: (props: any) => <p>{props.getValue()}</p>,
  },
  {
    accessorKey: "status",
    header: "Status",
    cell: (props: any) => (
      <>
        {props.getValue() === 0 && (
          <span className="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">
            Rejected
          </span>
        )}
        {props.getValue() === 1 && (
          <span className="inline-flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-600">
            Approved
          </span>
        )}
        {props.getValue() === -1 && (
          <span className="inline-flex items-center rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-600">
            Rejected
          </span>
        )}
      </>
    ),
  },
];
const Visitors = () => {
  const [session, setSession] = useState(getSession());
  const [visitors, setVisitors] = useState<Visitor[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchVisitors = async () => {
      try {
        const response = await visitorApi.getVisitors({
          headers: getSessionAccessToken(),
        });
        if (response) {
          setVisitors(response);
        }
      } catch (err: any) {
        console.error(err);
        Popup(PopupVariant.ERROR, err.response?.data?.error?.message);
      }
    };
    fetchVisitors();
  }, []);

  return (
    <div className="w-full p-2 md:p-5">
      <h1 className="text-3xl font-semibold">
        {session.role === "visitor" ? "Visits" : "Visitor Requests"}
      </h1>

      <Table
        data={visitors}
        columns={columns}
        onItemClicked={(visitor) =>
          session.role === "visitor"
            ? navigate(["/invited/status", visitor.id].join("/"))
            : navigate(["/dashboard/status", visitor.id].join("/"))
        }
        noDataElement={<p>No Visitors Found.</p>}
      />
    </div>
  );
};

export default Visitors;
