import { useState, useEffect } from "react";
import Table from "../../../components/Table";
import ButtonCom from "../../../components/Button";
import { MeetingRoom, meetingRoomApi } from "../../../api/meetingRooms";
import Popup, { PopupVariant } from "../../../components/Popup";
import { ErrorMessage, Field, Form, Formik, FormikValues } from "formik";
import Swal from "sweetalert2";
import Button from "../../../components/Button";
import ButtonLoad from "../../../components/ButtonLoad";
import withReactContent from "sweetalert2-react-content";
import * as Yup from "yup";
import { getSession, getSessionAccessToken } from "../../../lib/session";

const MeetingRooms = () => {
  const meetingRoomsColumns = [
    {
      accessorKey: "name",
      header: "Name",
      cell: (props: any) => <p>{props.getValue()}</p>,
    },
    {
      header: "Actions",
      cell: (props: any) => (
        <div className="flex flex-row place-items-center justify-center gap-2">
          <div className="w-40">
            {props.row.original.name !== "admin" &&
              props.row.original.username !== "admin" && (
                <ButtonCom
                  className="border border-gray-300 bg-gray-100 text-sm text-black hover:bg-gray-200"
                  onClick={() => deleteMeetingRoom(props.row.original.id)}
                >
                  Delete
                </ButtonCom>
              )}
          </div>
        </div>
      ),
    },
  ];

  const MySwal = withReactContent(Swal);

  const addMeetingRoomSchema = Yup.object().shape({
    meetingRoom: Yup.string().required("Meeting Room is required"),
  });

  const handleSubmit = async (values: FormikValues) => {
    try {
      const response = await meetingRoomApi.addMeetingRoom(
        {
          name: values.meetingRoom,
        },
        { headers: getSessionAccessToken() },
      );

      if (response) {
        fetchMeetingRooms();
        MySwal.close();
      }
    } catch (err: any) {
      console.error(err);
      Popup(PopupVariant.ERROR, err.response?.data?.error?.message);
    }
  };

  const [session, setSession] = useState(getSession());
  const [meetingRooms, setMeetingRooms] = useState<MeetingRoom[]>([]);
  const fetchMeetingRooms = async () => {
    try {
      const response = await meetingRoomApi.getMeetingRooms({
        headers: getSessionAccessToken(),
      });
      if (response) {
        setMeetingRooms(response);
      }
    } catch (err) {
      console.error(err);
      Popup(PopupVariant.ERROR);
    }
  };
  const deleteMeetingRoom = async (id: string) => {
    const result = await Popup(
      PopupVariant.CONFIRM,
      `Do you want to delete ${meetingRooms.find((meetingRoom) => meetingRoom.id === id)?.name}?`,
    );
    if (result.isConfirmed) {
      try {
        const response = await meetingRoomApi.deleteMeetingRoom(undefined, {
          queries: {
            id: id as string,
          },
          headers: getSessionAccessToken(),
        });
        if (response.success) {
          fetchMeetingRooms();
          Popup(PopupVariant.SUCCESS, "Successfully Deleted.");
        }
      } catch (err: any) {
        console.log(err);
        Popup(PopupVariant.ERROR, err.response?.data?.error?.message);
      }
    }
  };

  useEffect(() => {
    fetchMeetingRooms();
  }, []);

  const addMeetingRoom = async () =>
    await MySwal.fire({
      title: "Add Meeting Room",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: () => !Swal.isLoading(),
      html: (
        <Formik
          initialValues={{
            meetingRoom: "",
          }}
          validationSchema={addMeetingRoomSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ isSubmitting, values }) => (
            <Form className="space-y-3">
              <div>
                <Field
                  id="meetingRoom"
                  name="meetingRoom"
                  type="text"
                  placeholder="Enter Meeting Room"
                  className="w-full rounded-lg border border-gray-300 p-3 font-semibold placeholder-gray-400 shadow outline-none focus:border-gray-400"
                  required
                />
                <ErrorMessage
                  name="meetingRoom"
                  component="div"
                  className="text-sm text-red-500"
                />
              </div>

              <div className="flex justify-center gap-3">
                <div className="w-1/3">
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    className="rounded-md bg-accent-500 py-3 font-semibold text-white hover:bg-accent-600"
                  >
                    {isSubmitting ? <ButtonLoad /> : "Submit"}
                  </Button>
                </div>
                <div className="w-1/3">
                  <Button
                    type="button"
                    onClick={() => MySwal.close()}
                    className="rounded-md bg-gray-100 py-3 font-semibold text-black hover:bg-gray-200"
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ),
    });

  return (
    <div className="w-full p-5">
      <div className="flex flex-row justify-between">
        <p className="text-xl font-semibold">Meeting Rooms</p>
        {session.role === "admin" && (
          <div className=" w-50 ">
            <Button
              type="button"
              className=" bg-accent-500 px-11 text-white hover:bg-accent-600"
              onClick={addMeetingRoom}
            >
              Create Meeting Room
            </Button>
          </div>
        )}
      </div>

      <Table
        data={meetingRooms}
        columns={meetingRoomsColumns}
        onItemClicked={() => {}}
        noDataElement={<p>No Meeting Room Found.</p>}
      />
    </div>
  );
};

export default MeetingRooms;
