import { z } from "zod";
import { Zodios, makeApi, makeEndpoint } from "@zodios/core";
import { VMS_API_SERVER_URL } from "../lib/env";
import { errors } from "../types/Errors";
import { stringToValidDate } from "../lib/utils";

export const meetingRoomSchema = z.object({
  id: z.string().cuid2(),
  name: z.string(),
});

export type MeetingRoom = z.infer<typeof meetingRoomSchema>;

export const addMeetingRoomSchema = z.object({
  name: z.string(),
});

export type AddMeetingRoom = z.infer<typeof addMeetingRoomSchema>;

// Define endpoints

// Get all meeting rooms endpoint
const getMeetingRoomsEndpoint = makeEndpoint({
  method: "get",
  path: "/meetingRooms",
  alias: "getMeetingRooms",
  description: "Get all Meeting Rooms",
  response: z.array(meetingRoomSchema),
  errors: errors,
});

// Get meeting room by ID endpoint
const getMeetingRoomByIdEndpoint = makeEndpoint({
  method: "get",
  path: "/meetingRooms/:id",
  alias: "getMeetingRoomById",
  description: "Get Meeting Room by Id",
  parameters: [
    {
      name: "id",
      type: "Path",
      description: "Meeting Room Id",
      schema: z.string().cuid2(),
    },
  ],
  response: z.array(meetingRoomSchema),
  errors: errors,
});

// Create meeting room endpoint
const addMeetingRoomEndpoint = makeEndpoint({
  method: "post",
  path: "/meetingRooms",
  alias: "addMeetingRoom",
  description: "Create meeting room",
  parameters: [
    {
      name: "meetingRoom",
      type: "Body",
      description: "Meeting Room",
      schema: addMeetingRoomSchema,
    },
  ],
  response: z.array(meetingRoomSchema),
  errors: errors,
});

// Delete meeting room endpoint
const deleteMeetingRoomEndpoint = makeEndpoint({
  method: "delete",
  path: "/meetingRooms",
  alias: "deleteMeetingRoom",
  description: "Delete Meeting Room",
  parameters: [
    {
      name: "id",
      type: "Query",
      description: "Room Id",
      schema: z.string(),
    },
  ],
  response: z.object({
    success: z.boolean(),
  }),
  errors: errors,
});

// Get all available meeting rooms based on time endpoint
const getAvailableMeetingRoomsByTimeEndpoint = makeEndpoint({
  method: "post",
  path: "/meetingRooms/available",
  alias: "getAvailableMeetingRoomsByTime",
  description: "Get all available Meeting Rooms by time",
  parameters: [
    {
      name: "time",
      type: "Body",
      description: "Start and End Time",
      schema: z.object({ start: stringToValidDate, end: stringToValidDate }),
    },
  ],
  response: z.array(meetingRoomSchema),
  errors: errors,
});

// Combine all endpoints into the API
export const api = makeApi([
  getMeetingRoomsEndpoint,
  getMeetingRoomByIdEndpoint,
  addMeetingRoomEndpoint,
  deleteMeetingRoomEndpoint,
  getAvailableMeetingRoomsByTimeEndpoint,
]);

export const meetingRoomApi = new Zodios(VMS_API_SERVER_URL, api, {
  axiosConfig: {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  },
});
