import { useEffect, useState } from "react";
import {
  Routes,
  Route,
  Navigate,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import Layout from "./Layout";
import Meetings from "./meetings/Meetings";
import Visitors from "./visitors/Visitors";
import Status from "../status/Status";
import Participants from "./meetings/Participants";
import SettingsPage from "./settings/Settings";
import DashboardNotFound from "./dashboardnotfound/DashboardNotFound";
import EmailTemplate from "./emailtemplate/EmailTemplate";
import CreateMeeting from "./meetings/CreateMeeting";
import PendingAccount from "./pendingaccount/PendingAccount";
import Users from "./users/Users";
import { authApi } from "../../api/auth";
import Loading from "../../components/Loading";
import MeetingRooms from "./meetingRooms/MeetingRooms";
import Popup, { PopupVariant } from "../../components/Popup";
import {
  getSession,
  getSessionAccessToken,
  setSession,
} from "../../lib/session";
export function DashboardRoutes() {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [session, setSessionState] = useState(getSession());

  const isAuthenticated = async () => {
    try {
      const response = await authApi.verify({
        headers: getSessionAccessToken(),
      });

      if (response.success) {
        setSession(response.user);
        return true;
      }
    } catch (error) {
      Popup(
        PopupVariant.ERROR,
        "Please login first or check your credentials.",
      );

      return false;
    }
  };

  useEffect(() => {
    isAuthenticated().then((authenticated) => {
      setAuthenticated(authenticated as boolean);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <Loading />;
  }
  return (
    <Routes>
      <Route
        element={authenticated ? <Layout /> : <Navigate to="/login" replace />}
      >
        {session.role !== "pending" ? (
          <>
            <Route index element={<Meetings />} />
            <Route index path="/meetings" element={<Meetings />} />
            {session.role !== "visitor" && (
              <Route path="/meetings/create" element={<CreateMeeting />} />
            )}
            <Route path="/meetings/:id" element={<Participants />} />
            {session.role !== "users" && (
              <Route path="/visitors" element={<Visitors />} />
            )}
            <Route path="/status/:id" element={<Status />} />
            {session.role === "admin" && (
              <Route path="/emailtemplate" element={<EmailTemplate />} />
            )}
            {session.role === "admin" && (
              <Route path="/users" element={<Users />} />
            )}
            {session.role === "admin" && (
              <Route path="/settings" element={<SettingsPage />} />
            )}
            {session.role === "admin" && (
              <Route path="/meetingRooms" element={<MeetingRooms />} />
            )}

            <Route path="*" element={<DashboardNotFound />} />
          </>
        ) : (
          <Route path="/" element={<PendingAccount />} />
        )}
      </Route>
    </Routes>
  );
}
