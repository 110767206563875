import z from "zod";
import { Zodios, makeApi, makeEndpoint } from "@zodios/core";
import { stringToValidDate } from "../lib/utils";
import { VMS_API_SERVER_URL } from "../lib/env";
import { visitorSchema } from "./visitor";
import { errors } from "../types/Errors";

export const participantSchema = z.object({
  id: z.string().cuid2(),
  email: z.string().email(),
});

export type Participant = z.infer<typeof participantSchema>;

export const meetingSchema = z.object({
  id: z.string().cuid2(),
  title: z.string(),
  timeStart: stringToValidDate,
  timeEnd: stringToValidDate,
  venue: z.string(),
  host: z.string(),
  description: z.string(),
  url: z.string().optional(),
  createdAt: stringToValidDate,
  participants: participantSchema.array(),
  allowedAreas: z.array(z.string().cuid2()),
  isCancelled: z.boolean(),
});

export type Meeting = z.infer<typeof meetingSchema>;

export const addMeetingSchema = z.object({
  title: z.string(),
  timeStart: stringToValidDate,
  timeEnd: stringToValidDate,
  venue: z.string(),
  description: z.string(),
  participants: z.string().array(),
  allowedAreas: z.array(z.string().cuid2()),
  createMeetingLink: z.boolean(),
});

export type AddMeeting = z.infer<typeof addMeetingSchema>;

// Define endpoints

// Get all meetings endpoint
const getMeetingsEndpoint = makeEndpoint({
  method: "get",
  path: "/meetings",
  alias: "getMeetings",
  description: "Get All Meetings",
  response: z.array(meetingSchema),
  errors: errors,
});

// Get meeting details by ID endpoint
const getMeetingEndpoint = makeEndpoint({
  method: "get",
  path: "/meetings/:id",
  alias: "getMeeting",
  description: "Get Meeting By Id",
  parameters: [
    {
      name: "id",
      type: "Path",
      description: "Meeting ID",
      schema: z.string().cuid2(),
    },
  ],
  response: meetingSchema,
  errors: errors,
});

// Get all meeting participants by meeting ID endpoint
const getVisitorsByMeetingIdEndpoint = makeEndpoint({
  method: "get",
  path: "/meetings/:id/participants",
  alias: "getVisitorsByMeetingId",
  description: "Get All Meeting Participants",
  parameters: [
    {
      name: "id",
      type: "Path",
      description: "Meeting ID",
      schema: z.string().cuid2(),
    },
  ],
  response: z.object({
    participants: z.array(visitorSchema),
    meeting: meetingSchema,
  }),
  errors: errors,
});

// Create meeting endpoint
const createMeetingEndpoint = makeEndpoint({
  method: "post",
  path: "/meetings",
  alias: "createMeeting",
  description: "Create Meeting",
  parameters: [
    {
      name: "meeting",
      type: "Body",
      description: "Meeting",
      schema: addMeetingSchema,
    },
  ],
  response: z.object({
    success: z.boolean(),
    message: z.string(),
  }),
  errors: errors,
});

// Cancel meeting endpoint
const cancelMeetingEndpoint = makeEndpoint({
  method: "put",
  path: "/meetings/:id/cancel",
  alias: "cancelMeeting",
  description: "Cancel Meeting",
  parameters: [
    {
      name: "id",
      type: "Path",
      description: "Meeting ID",
      schema: z.string().cuid2(),
    },
  ],
  response: z.object({
    success: z.boolean(),
    message: z.string(),
  }),
  errors: errors,
});

// Get meeting sample endpoint
const getMeetingSampleEndpoint = makeEndpoint({
  method: "get",
  path: "/meetingsSample",
  alias: "getMeetingSample",
  description: "Get Meeting Sample",
  response: z.string(),
  errors: errors,
});

// Combine endpoints into an API
const api = makeApi([
  getMeetingsEndpoint,
  getMeetingEndpoint,
  getVisitorsByMeetingIdEndpoint,
  createMeetingEndpoint,
  cancelMeetingEndpoint,
  getMeetingSampleEndpoint,
]);

export const meetingsApi = new Zodios(VMS_API_SERVER_URL, api, {
  axiosConfig: {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  },
});

// Public

// Define schemas
export const publicMeetingSchema = meetingSchema.omit({ createdAt: true });

export type PublicMeeting = z.infer<typeof publicMeetingSchema>;

// Get public meeting details by ID endpoint
const getPublicMeetingEndpoint = makeEndpoint({
  method: "get",
  path: "/public/meetings/:id",
  alias: "getPublicMeeting",
  description: "Get Meeting By Id",
  parameters: [
    {
      name: "id",
      type: "Path",
      description: "Meeting ID",
      schema: z.string().cuid2(),
    },
  ],
  response: publicMeetingSchema,
  errors: errors,
});

// Combine all public endpoints into the API
const publicApi = makeApi([getPublicMeetingEndpoint]);

export const publicMeetingApi = new Zodios(VMS_API_SERVER_URL, publicApi, {
  axiosConfig: {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  },
});
