const DashboardNotFound = () => {
  return (
    <div className="h-[65vh] px-10 py-32 lg:px-52">
      <p className="text-5xl font-extrabold text-accent-500">Sorry!</p>
      <hr className="my-6 h-[3px] w-16 bg-black" />
      <p className="text-lg text-gray-600">
        Unfortunately the page you are looking for is unavailable or no longer
        exists.
      </p>
    </div>
  );
};

export default DashboardNotFound;
