import { authApi } from "../api/auth";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import * as Yup from "yup";
import { Field, Form, Formik, ErrorMessage } from "formik";
import Button from "./Button";
import ButtonLoad from "./ButtonLoad";
import { userApi } from "../api/users";
const MySwal = withReactContent(Swal);
let account: any;
const resetPasswordSchema = Yup.object().shape({
  userName: Yup.string().required("Username is required"),
  recoveryCode: Yup.string().required("Recovery code is required"),
  resetError: Yup.string(),
});

const passwordUpdateSchema = Yup.object().shape({
  password: Yup.string().required("Password is required"),
  confirmPassword: Yup.string().required("Confirm password is required"),
  checkPassword: Yup.string()
    .test("checkPassword", "Passwords must match", function (value) {
      const { password, confirmPassword }: any = this.parent;
      if (password && confirmPassword) return password === confirmPassword;
      return true;
    })
    .test(
      "checkPassword",
      "Password must be at least 6 characters",
      function (value) {
        const { password, confirmPassword } = this.parent;
        if (password && confirmPassword) return password.length >= 6;
        return true;
      },
    )
    .test(
      "checkPassword",
      "New password must not be the same as old password",
      function (value) {
        const { password, confirmPassword } = this.parent;
        if (password && confirmPassword && account.password)
          return password !== account?.password;
        return true;
      },
    ),
  passwordUpdateError: Yup.string(),
});
const handleResetPasswordSubmit = async (
  values: any,
  { setSubmitting, resetForm, setErrors }: any,
) => {
  try {
    setSubmitting(true);
    const response = await userApi.getAccount({
      queries: {
        userName: values.userName,
        recoveryCode: values.recoveryCode,
      },
    });

    if (response.success) {
      account = {
        userId: response.userId,
        password: response.password,
      };
      setSubmitting(false);
      resetForm();
      handlePasswordUpdate();
    }
  } catch (err) {
    console.log(err);
    setErrors({
      resetError: "Something went wrong, please try again",
    });
    setSubmitting(false);
  }
};
const handlePasswordUpdateSubmit = async (
  values: any,
  { setSubmitting, resetForm, setErrors }: any,
) => {
  try {
    const updateAccount = {
      userId: account?.userId,
      password: values.password as string,
    };
    const response = await userApi.updateAccount(
      JSON.parse(JSON.stringify(updateAccount)),
    );
    if (response.success) {
      Swal.fire({
        title: "Password Updated",
        showCancelButton: false,
        showConfirmButton: false,
        position: "top",
        icon: "success",
        timer: 1500,
      });
      resetForm();
    }
  } catch (err) {
    console.log(err);
    setErrors({
      passwordUpdateError: "Something went wrong, please try again",
    });
    setSubmitting(false);
  }
};
export const handleForgetPassword = async () => {
  await MySwal.fire({
    title: "Reset Password?",
    text: "Enter your username and recovery code",
    showCancelButton: false,
    showConfirmButton: false,
    allowOutsideClick: () => !Swal.isLoading(),
    html: (
      <Formik
        initialValues={{
          userName: "",
          recoveryCode: "",
          resetError: "",
        }}
        validationSchema={resetPasswordSchema}
        onSubmit={handleResetPasswordSubmit}
        enableReinitialize
      >
        {({ isSubmitting, values }) => (
          <Form className="space-y-3">
            <div>
              <Field
                id="username"
                name="userName"
                type="text"
                placeholder="Enter Username"
                className="w-full rounded-lg border border-gray-300 p-3 font-semibold placeholder-gray-400 shadow outline-none focus:border-gray-400"
                required
              />
              <ErrorMessage
                name="userName"
                component="div"
                className="text-sm text-red-500"
              />
            </div>
            <div>
              <Field
                id="recoveryCode"
                name="recoveryCode"
                type="text"
                placeholder="Enter Recovery Code"
                className="w-full rounded-lg border border-gray-300 p-3 font-semibold placeholder-gray-400 shadow outline-none focus:border-gray-400"
                required
              />
              <ErrorMessage
                name="recoveryCode"
                component="div"
                className="text-sm text-red-500"
              />
            </div>

            <ErrorMessage
              name="resetError"
              component="div"
              className="text-sm text-red-500"
            />

            <div className="flex justify-center gap-3">
              <div className="w-1/3">
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  className="rounded-md bg-accent-500 py-3 font-semibold text-white hover:bg-accent-600"
                >
                  {isSubmitting ? <ButtonLoad /> : "Submit"}
                </Button>
              </div>
              <div className="w-1/3">
                <Button
                  type="button"
                  onClick={() => MySwal.close()}
                  className="rounded-md bg-gray-100 py-3 font-semibold text-black hover:bg-gray-200"
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    ),
  });
};
const handlePasswordUpdate = async () => {
  await MySwal.fire({
    title: "Update Password?",
    text: "Enter your new password",
    showCancelButton: false,
    showConfirmButton: false,
    allowOutsideClick: () => !Swal.isLoading(),
    html: (
      <Formik
        initialValues={{
          password: "",
          confirmPassword: "",
          checkPassword: "",
          passwordUpdateError: "",
        }}
        validationSchema={passwordUpdateSchema}
        onSubmit={handlePasswordUpdateSubmit}
      >
        {({ isSubmitting, values, errors }: any) => (
          <Form className="space-y-3">
            <div>
              <Field
                id="password"
                name="password"
                type="password"
                placeholder="Enter New Password"
                className="w-full rounded-lg border border-gray-400 p-3 font-semibold placeholder-gray-400"
                required
              />
              <ErrorMessage
                name="password"
                component="div"
                className="text-sm text-red-500"
              />
            </div>
            <div>
              <Field
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                placeholder="Confirm New Password"
                className="w-full rounded-lg border border-gray-400 p-3 font-semibold placeholder-gray-400"
                required
              />
              <ErrorMessage
                name="confirmPassword"
                component="div"
                className="text-sm text-red-500"
              />
            </div>
            <ErrorMessage
              name="checkPassword"
              component="div"
              className="text-sm text-red-500"
            />
            <ErrorMessage
              name="passwordUpdateError"
              component="div"
              className="text-sm text-red-500"
            />
            <div className="flex justify-center gap-3">
              <div className="w-1/3">
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  className="rounded-md bg-accent-500 py-3 font-semibold text-white hover:bg-accent-600"
                >
                  {isSubmitting ? <ButtonLoad /> : "Reset"}
                </Button>
              </div>
              <div className="w-1/3">
                <Button
                  type="button"
                  onClick={() => MySwal.close()}
                  className="rounded-md bg-gray-100 py-3 font-semibold text-black hover:bg-gray-200"
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    ),
  });
};
