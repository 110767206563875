import { z } from "zod";
import { Zodios, makeApi, makeEndpoint } from "@zodios/core";
import { VMS_API_SERVER_URL } from "../lib/env";
import { errors } from "../types/Errors";

// Define schemas
const microsoftUserSchema = z.object({
  provider: z.string(),
  name: z.object({
    familyName: z.string(),
    givenName: z.string(),
  }),
  id: z.string(),
  displayName: z.string(),
  userPrincipalName: z.string(),
  emails: z.object({ type: z.string(), value: z.string() }).array(),
  _json: z.object({
    "@odata.context": z.string(),
    userPrincipalName: z.string(),
    id: z.string(),
    displayName: z.string(),
    surname: z.string(),
    givenName: z.string(),
    preferredLanguage: z.string(),
    mail: z.string(),
    mobilePhone: z.string().nullable(),
    jobTitle: z.string().nullable(),
    officeLocation: z.string().nullable(),
    businessPhones: z.string().array(),
  }),
});

// Define endpoints

// Login endpoint
const loginEndpoint = makeEndpoint({
  method: "post",
  path: "/login",
  alias: "login",
  description: "Login",
  parameters: [
    {
      name: "credentials",
      type: "Body",
      description: "User Credentials",
      schema: z.object({ username: z.string(), password: z.string() }),
    },
    {
      name: "from",
      type: "Query",
      description: "From URL",
      schema: z.string().url().optional(),
    },
    {
      name: "fallback",
      type: "Query",
      description: "Fallback URL",
      schema: z.string().url().optional(),
    },
  ],
  response: z.object({
    success: z.boolean(),
    type: z.string(),
    user: z.object({
      username: z.string(),
      name: z.string(),
      role: z.string(),
      accessToken: z.string(),
    }),
  }),
  errors: errors,
});

// Login using magic link endpoint
const loginMagicLinkEndpoint = makeEndpoint({
  method: "post",
  path: "/login/magic",
  alias: "loginMagicLink",
  description: "Login using Magic Link",
  parameters: [
    {
      name: "email",
      type: "Body",
      description: "User Email",
      schema: z.object({ email: z.string() }),
    },
    {
      name: "from",
      type: "Query",
      description: "From URL",
      schema: z.string().url().optional(),
    },
  ],
  response: z.object({
    success: z.boolean(),
    type: z.string(),
  }),
  errors: errors,
});

// Logout endpoint
const logoutEndpoint = makeEndpoint({
  method: "get",
  path: "/logout",
  alias: "logout",
  description: "Logout",
  response: z.object({
    success: z.boolean(),
  }),
  errors: errors,
});

// Verify endpoint
const verifyEndpoint = makeEndpoint({
  method: "get",
  path: "/verify",
  alias: "verify",
  description: "Verify",
  response: z.object({
    success: z.boolean(),
    user: z.object({
      username: z.string(),
      name: z.string(),
      role: z.string(),
    }),
  }),
  errors: errors,
});

// Log in with Microsoft endpoint
const msLoginEndpoint = makeEndpoint({
  method: "get",
  path: "/login/ms",
  alias: "msLogin",
  description: "Microsoft Log in",
  response: z.object({
    success: z.boolean(),
    msLoginUrl: z.string().url(),
  }),
  errors: errors,
});

// Log in with Microsoft endpoint redirecr
const msLoginRedirectEndpoint = makeEndpoint({
  method: "get",
  path: "/login/ms/redirect",
  alias: "msLoginPassport",
  description: "Microsoft Log in Redirect",
  response: z.any(),
  errors: errors,
});

// Login callback endpoint using Passport
const msLoginCbPassportEndpoint = makeEndpoint({
  method: "get",
  path: "/login/msCb",
  alias: "msLoginCallback",
  description: "Microsoft Log In Callback",
  parameters: [
    {
      name: "code",
      type: "Query",
      description: "Code from Microsoft",
      schema: z.string(),
    },
    {
      name: "from",
      type: "Query",
      description: "From URL",
      schema: z.string().url(),
    },
    {
      name: "fallback",
      type: "Query",
      description: "Fallback URL",
      schema: z.string().url(),
    },
  ],
  response: z.object({
    success: z.boolean(),
    redirectUrl: z.string().url(),

    user: z.object({
      username: z.string(),
      name: z.string(),
      role: z.string(),
      accessToken: z.string(),
      refreshToken: z.string(),
    }),
  }),
  errors: errors,
});

// Microsoft Verify endpoint
const msVerifyEndpoint = makeEndpoint({
  method: "get",
  path: "/verify/ms",
  alias: "msVerify",
  description: "Microsoft Verify",
  response: z.object({
    success: z.boolean(),
  }),
  errors: errors,
});

// Combine endpoints into an API
const api = makeApi([
  loginEndpoint,
  loginMagicLinkEndpoint,
  logoutEndpoint,
  verifyEndpoint,
  msLoginEndpoint,
  msLoginRedirectEndpoint,
  msLoginCbPassportEndpoint,
  msVerifyEndpoint,
]);

export const authApi = new Zodios(VMS_API_SERVER_URL, api, {
  axiosConfig: {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  },
});
