const ConfirmEmail = () => {
  return (
    <div className="h-[65vh] px-10 py-32 lg:px-52">
      <p className="text-5xl font-extrabold text-accent-500">
        Confirm Your Email.
      </p>
      <hr className="my-6 h-[3px] w-16 bg-black" />
      <p className="text-lg text-gray-600">
        Please check your email and click "Confirm Email" to verify your email
        address and view your status.
      </p>
    </div>
  );
};

export default ConfirmEmail;
