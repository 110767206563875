import { ReactComponent as ExceptionIcon } from "../assets/icons/exception.svg";

function FieldError({ error }: { error: string }) {
  return (
    <div className="flex items-center gap-2 rounded-md bg-red-600 p-2">
      <ExceptionIcon className="h-6 min-h-6 w-6 min-w-6 fill-white" />
      <p className="text-white">{error}</p>
    </div>
  );
}

export default FieldError;
