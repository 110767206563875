import { z } from "zod";
import { errors } from "../types/Errors";
import { Zodios, makeApi, makeEndpoint } from "@zodios/core";
import { VMS_API_SERVER_URL } from "../lib/env";

// Define schemas
export const userSchema = z.object({
  id: z.string().cuid2(),
  username: z.string(),
  password: z.string().optional(),
  name: z.string(),
  role: z.string(),
  recoveryCode: z.string().optional(),
  isPending: z.boolean().optional(),
});

export type User = z.infer<typeof userSchema>;

export const addUserSchema = z.object({
  username: z.string(),
  password: z.string(),
  name: z.string(),
  role: z.string(),
});

export type AddUser = z.infer<typeof addUserSchema>;

export const changeUserRoleSchema = z.object({
  id: z.string().cuid2(),
  role: z.string(),
});

export type ChangeUserRole = z.infer<typeof changeUserRoleSchema>;

// Define endpoints

// Add user endpoint
const addUserEndpoint = makeEndpoint({
  method: "post",
  path: "/users",
  alias: "addUser",
  description: "Add user",
  parameters: [
    {
      name: "User",
      type: "Body",
      description: "User Details",
      schema: addUserSchema,
    },
  ],
  response: z.object({
    success: z.boolean(),
    recoveryCode: z.string(),
  }),
  errors: errors,
});

// Change user role endpoint
const changeUserRoleEndpoint = makeEndpoint({
  method: "put",
  path: "/users/changeRole",
  alias: "changeUserRole",
  description: "Change user role",
  parameters: [
    {
      name: "User",
      type: "Body",
      description: "User",
      schema: changeUserRoleSchema,
    },
  ],
  response: z.object({
    success: z.boolean(),
  }),
  errors: errors,
});

// Get account endpoint
const getAccountEndpoint = makeEndpoint({
  method: "get",
  path: "/account",
  alias: "getAccount",
  description: "Get account",
  parameters: [
    {
      name: "userName",
      type: "Query",
      description: "User Name",
      schema: z.string(),
    },
    {
      name: "recoveryCode",
      type: "Query",
      description: "Recovery Code",
      schema: z.string(),
    },
  ],
  response: z.object({
    success: z.boolean(),
    userId: z.string().cuid2(),
    password: z.string(),
  }),
  errors: errors,
});

// Update account endpoint
const updateAccountEndpoint = makeEndpoint({
  method: "put",
  path: "/account",
  alias: "updateAccount",
  description: "Update account",
  response: z.object({
    success: z.boolean(),
  }),
  errors: errors,
});

// Get users endpoint
const getUsersEndpoint = makeEndpoint({
  method: "get",
  path: "/users",
  alias: "getUsers",
  description: "Get users",
  response: z.array(userSchema),
  errors: errors,
});

// Delete user endpoint
const deleteUserEndpoint = makeEndpoint({
  method: "delete",
  path: "/users",
  alias: "deleteUser",
  description: "Delete user",
  parameters: [
    {
      name: "id",
      type: "Query",
      description: "User Id",
      schema: z.string(),
    },
  ],
  response: z.object({
    success: z.boolean(),
  }),
  errors: errors,
});

// Update user endpoint
const updateUserEndpoint = makeEndpoint({
  method: "put",
  path: "/users/update",
  alias: "updateUser",
  description: "Update user",
  parameters: [
    {
      name: "id",
      type: "Query",
      description: "User Id",
      schema: z.string(),
    },
  ],
  response: z.object({
    success: z.boolean(),
  }),
  errors: errors,
});

// Combine endpoints into an API
const api = makeApi([
  addUserEndpoint,
  changeUserRoleEndpoint,
  getAccountEndpoint,
  updateAccountEndpoint,
  getUsersEndpoint,
  deleteUserEndpoint,
  updateUserEndpoint,
]);

export const userApi = new Zodios(VMS_API_SERVER_URL, api, {
  axiosConfig: {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  },
});
