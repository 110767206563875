import React from "react";
import { Routes, Route } from "react-router-dom";
import MeetingDetails from "./pages/meetingDetails/MeetingDetails";
import RegistrationDetails from "./pages/registrationDetails/RegistrationDetails";
import Status from "./pages/status/Status";
import Layout from "./Layout";
import NotFound from "./pages/notFound/NotFound";
import ConfirmEmail from "./pages/confirm-email/confirmEmail";
const InvitedRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route
          path="/registration-details/*"
          element={<RegistrationDetails />}
        />
        <Route
          path="/meeting-details/:id/:participantId"
          element={<MeetingDetails />}
        ></Route>
        <Route path="/confirm-email" element={<ConfirmEmail />} />
        <Route path="/status/:id" element={<Status />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default InvitedRoutes;
