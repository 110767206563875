import { z } from "zod";
import { Zodios } from "@zodios/core";
import { stringToValidDate } from "../lib/utils";
import { VMS_API_SERVER_URL } from "../lib/env";
import { errors } from "../types/Errors";

const fileUploadSchema = z.custom((value) => {
  // Check if the value is a Blob or FormData
  if (value instanceof Blob || value instanceof FormData) {
    return { success: true, data: value };
  } else {
    return { success: false, message: "Invalid file upload data type" };
  }
});

export const addEmailTemplateSchema = z.object({
  id: z.string().cuid2().optional(),
  emailTemplateFile: fileUploadSchema,
  fileName: z.string().optional(),
  dateAdded: stringToValidDate.optional(),
  isCurrent: z.boolean().optional(),
});

export type AddEmailTemplate = z.infer<typeof addEmailTemplateSchema>;

export const emailTemplateSchema = z.object({
  id: z.string().cuid2().optional(),
  emailTemplateFile: z.string().optional(),
  fileName: z.string().optional(),
  dateAdded: stringToValidDate.optional(),
  isCurrent: z.boolean().optional(),
});

export type EmailTemplate = z.infer<typeof emailTemplateSchema>;
export const emailTemplateApi = new Zodios(VMS_API_SERVER_URL, [
  {
    method: "get",
    path: "/emailtemplate/invitedEmailTemplate",
    alias: "getInvitedEmailTemplate",
    description: "Get Email Template",
    response: z.array(emailTemplateSchema),
    errors: errors,
  },

  {
    method: "post",
    path: "/emailtemplate/invitedEmailTemplate",
    alias: "addInvitedEmailTemplate",
    description: "Add Email Template",
    response: z.object({
      success: z.boolean(),
      message: z.string(),
    }),
    errors: errors,
  },
  {
    method: "put",
    path: "/emailtemplate/useAsInvitedTemplate",
    alias: "useAsInvitedTemplate",
    description: "Use as template",

    response: z.object({
      success: z.boolean(),
      message: z.string(),
    }),
    errors: errors,
  },
  {
    method: "delete",
    path: "/emailtemplate/deleteInvitedTemplate",
    alias: "deleteInvitedTemplate",
    description: "Delete Invited Template",
    response: z.object({
      success: z.boolean(),
      message: z.string(),
    }),
    errors: errors,
  },

  {
    method: "get",
    path: "/emailtemplate/publicTemplate",
    alias: "getPublicTemplate",
    description: "Get Not Invited Confirmation Email Template",
    response: z.array(emailTemplateSchema),
    errors: errors,
  },

  {
    method: "post",
    path: "/emailtemplate/publicTemplate",
    alias: "addPublicEmailTemplate",
    description: "Add Not Invited Confirmation Email Template",
    response: z.object({
      success: z.boolean(),
      message: z.string(),
    }),
    errors: errors,
  },
  {
    method: "put",
    path: "/emailtemplate/useAsPublicTemplate",
    alias: "useAsPublicTemplate",
    description: "Use as template",

    response: z.object({
      success: z.boolean(),
      message: z.string(),
    }),
    errors: errors,
  },
  {
    method: "delete",
    path: "/emailtemplate/deletePublicTemplate",
    alias: "deletePublicTemplate",
    description: "Delete Public Template",
    response: z.object({
      success: z.boolean(),
      message: z.string(),
    }),
    errors: errors,
  },
  {
    method: "get",
    path: "/emailtemplate/invitationTemplate",
    alias: "getInvitationTemplate",
    description: "Get Meeting Invitation Email Template",
    response: z.array(emailTemplateSchema),
    errors: errors,
  },
  {
    method: "post",
    path: "/emailtemplate/invitationTemplate",
    alias: "addInvitationEmailTemplate",
    description: "Add Meeting Invitation Email Template",
    response: z.object({
      success: z.boolean(),
      message: z.string(),
    }),
    errors: errors,
  },
  {
    method: "put",
    path: "/emailtemplate/useAsInvitationTemplate",
    alias: "useAsInvitationTemplate",
    description: "Use as template for meeting invitation",
    response: z.object({
      success: z.boolean(),
      message: z.string(),
    }),
    errors: errors,
  },

  {
    method: "delete",
    path: "/emailtemplate/deleteInvitationTemplate",
    alias: "deleteInvitationTemplate",
    description: "Delete Meeting Invitation Template",
    response: z.object({
      success: z.boolean(),
      message: z.string(),
    }),
    errors: errors,
  },
  {
    method: "get",
    path: "/emailtemplate/downloadInvitedConfirmationEmailTemplate",
    alias: "downloadInvitedConfirmationEmailTemplate",
    description: "Download Invited Confirmation Email Template",
    response: z.object({
      success: z.boolean(),
      message: z.string(),
      file: z.any(),
    }),
    errors: errors,
  },

  {
    method: "get",
    path: "/emailtemplate/downloadPublicConfirmationEmailTemplate",
    alias: "downloadPublicConfirmationEmailTemplate",
    description: "Download Public Confirmation Email Template",
    response: z.object({
      success: z.boolean(),
      message: z.string(),
      file: z.any(),
    }),
    errors: errors,
  },
  {
    method: "get",
    path: "/emailtemplate/downloadInvitationEmailTemplate",
    alias: "downloadInvitationEmailTemplate",
    description: "Download Invitation Email Template",
    response: z.object({
      success: z.boolean(),
      message: z.string(),
      file: z.any(),
    }),
    errors: errors,
  },
  {
    method: "get",
    path: "/emailtemplate/signInTemplate",
    alias: "getSignInTemplate",
    description: "Get Email Template",
    response: z.array(emailTemplateSchema),
    errors: errors,
  },
  {
    method: "post",
    path: "/emailtemplate/signInTemplate",
    alias: "addSignInTemplate",
    description: "Add Email Template",
    response: z.object({
      success: z.boolean(),
      message: z.string(),
    }),
    errors: errors,
  },
  {
    method: "put",
    path: "/emailtemplate/useAsSignInTemplate",
    alias: "useAsSignInTemplate",
    description: "Use as template",
    response: z.object({
      success: z.boolean(),
      message: z.string(),
    }),
    errors: errors,
  },
  {
    method: "delete",
    path: "/emailtemplate/deleteSignInTemplate",
    alias: "deleteSignInTemplate",
    description: "Delete SignIn Template",
    response: z.object({
      success: z.boolean(),
      message: z.string(),
    }),
    errors: errors,
  },
  {
    method: "get",
    path: "/emailtemplate/downloadSignInTemplate",
    alias: "downloadSignInTemplate",
    description: "Download SignIn Template",
    response: z.object({
      success: z.boolean(),
      message: z.string(),
      file: z.any(),
    }),
    errors: errors,
  },
]);
