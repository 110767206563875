import { motion } from "framer-motion";
import { twMerge } from "tailwind-merge";

const Button = ({ children, className, ...props }: any) => {
  return (
    <motion.button
      whileTap={{ scale: 1.025 }}
      className={twMerge(
        "flex w-full items-center justify-center rounded-md px-3 py-1.5 text-lg font-semibold leading-6 shadow-md focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-600",
        className,
      )}
      {...props}
    >
      {children}
    </motion.button>
  );
};

export default Button;
