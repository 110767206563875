const Logo = () => {
  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-sm">
      <img
        className="mx-auto h-10 w-auto"
        src="/images/logo.png"
        alt="Brand Logo"
      />
    </div>
  );
};

export default Logo;
