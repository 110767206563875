import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { startOfToday, subDays } from "date-fns";

import DayView from "./DayView";
import MonthView from "./MonthView";
import YearView from "./YearView";

import CalendarHeader from "./CalendarHeader";

import { motion } from "framer-motion";
import { Meeting } from "../../api/meeting";
import { MeetingRoom } from "../../api/meetingRooms";

function Calendar({
  meetings,
  meetingRooms,
  setSelectedMeeting,
}: {
  meetings: Meeting[];
  meetingRooms: MeetingRoom[];
  setSelectedMeeting: Dispatch<SetStateAction<Meeting | undefined>>;
}) {
  const [selectedView, setSelectedView] = useState("Month");

  let today = startOfToday();
  const [selectedDate, setSelectedDate] = useState(today);
  const [currentDate, setCurrentDate] = useState(today);
  const [selectedMeetingRoom, setSelectedMeetingRoom] = useState<
    MeetingRoom | undefined
  >(meetingRooms.find((meetingRoom) => meetingRoom !== undefined));
  const [filteredMeetings, setFilteredMeetings] = useState<Meeting[]>(meetings);

  useEffect(() => {
    setFilteredMeetings(
      meetings.filter((meeting) =>
        selectedMeetingRoom
          ? meeting.venue === selectedMeetingRoom.name && !meeting.isCancelled
          : false,
      ),
    );
  }, [selectedMeetingRoom]);

  return (
    <div className="relative isolate flex h-full w-full flex-col">
      <CalendarHeader
        today={today}
        currentDate={currentDate}
        setCurrentDate={setCurrentDate}
        selectedView={selectedView}
        setSelectedView={setSelectedView}
        meetingRooms={meetingRooms}
        selectedMeetingRoom={selectedMeetingRoom}
        setSelectedMeetingRoom={setSelectedMeetingRoom}
      />
      <div className="flex h-full max-h-dvh w-full overflow-y-auto">
        {selectedView === "Year" && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="h-full w-full"
          >
            <YearView
              today={today}
              meetings={filteredMeetings}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
              setSelectedView={setSelectedView}
              setSelectedMeeting={setSelectedMeeting}
            />
          </motion.div>
        )}
        {selectedView === "Month" && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="h-full w-full"
          >
            <MonthView
              today={today}
              meetings={filteredMeetings}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
              setSelectedView={setSelectedView}
              setSelectedMeeting={setSelectedMeeting}
            />
          </motion.div>
        )}
        {selectedView === "Day" && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="h-full w-full"
          >
            <DayView
              setSelectedView={setSelectedView}
              meetings={filteredMeetings}
              selectedDate={selectedDate}
              currentDate={currentDate}
              setSelectedMeeting={setSelectedMeeting}
            />
          </motion.div>
        )}
      </div>
    </div>
  );
}

export default Calendar;
