const BgImage = () => {
  return (
    <>
      <img
        alt="background"
        src="/images/bg-image-yellow.jpg"
        className="absolute -z-10 h-full w-full object-cover filter lg:object-top"
      />
    </>
  );
};

export default BgImage;
