import { FunctionComponent, ReactNode, SVGProps, useState } from "react";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export type HeaderItem = {
  icon: FunctionComponent<SVGProps<SVGSVGElement>> | undefined;
  to: string;
  name: string;
  hidden: boolean;
  onItemClick: (() => void) | undefined;
};

function Header({
  headerItems,
  burgerMenuItems,
}: {
  headerItems: HeaderItem[];
  burgerMenuItems: HeaderItem[];
}) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header>
      <nav className="sticky flex items-center justify-between bg-gradient-to-r from-primary-500 via-yellow-500 to-yellow-100 p-5 shadow-lg">
        <Link to="/">
          <img src="/images/logo.png" alt="logo" className="h-5" />
        </Link>
        <div className="hidden items-baseline space-x-5 lg:flex">
          {headerItems.map((headerItem) => (
            <Link
              to={headerItem.to}
              key={headerItem.name}
              onClick={headerItem.onItemClick}
              hidden={headerItem.hidden}
              className={twMerge(
                "hover:text-accent-500",
                headerItem.name === "Logout" &&
                  "rounded bg-accent-500 px-4 py-2 font-bold text-white shadow-sm hover:bg-accent-600 hover:text-white",
              )}
            >
              {headerItem.name}
            </Link>
          ))}
        </div>
        <div className="flex lg:hidden">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="text-accent-500 hover:text-accent-600 focus:outline-none"
          >
            <span
              className={`block h-1 w-6  rounded-sm bg-accent-500 
                    transition-all duration-300 ease-out ${
                      isOpen ? "translate-y-1 rotate-45" : "-translate-y-0.5"
                    }`}
            ></span>
            <span
              className={`my-0.5 block h-1 w-6 rounded-sm 
                    bg-accent-500 transition-all duration-300 ease-out ${
                      isOpen ? "opacity-0" : "opacity-100"
                    }`}
            ></span>
            <span
              className={`block h-1 w-6 rounded-sm bg-accent-500 transition-all duration-300 ease-out ${
                isOpen ? "-translate-y-2 -rotate-45" : "translate-y-0.5"
              }`}
            ></span>
          </button>
        </div>
      </nav>
      {isOpen && (
        <div className="top-30 absolute left-0 z-50 flex h-full w-full flex-col space-y-4 bg-white p-5 lg:hidden">
          {burgerMenuItems.map((burgerMenuItem) => (
            <Link
              to={burgerMenuItem.to}
              className="rounded-md border border-gray-300 border-opacity-55 p-3 shadow-md hover:bg-gray-100 hover:text-accent-500"
              type="button"
              onClick={() => {
                setIsOpen(!isOpen);
                if (burgerMenuItem.onItemClick) {
                  burgerMenuItem.onItemClick();
                }
              }}
              key={burgerMenuItem.name}
              hidden={burgerMenuItem.hidden}
            >
              {burgerMenuItem.name}
            </Link>
          ))}
        </div>
      )}
    </header>
  );
}

export default Header;
