import InvitedConfirmationEmail from "./InvitedConfirmationEmail";
import MeetingInvitationEmail from "./MeetingInvitationEmail";
import PublicConfirmationEmail from "./PublicConfirmationEmail";
import SignInEmail from "./SignInEmail";
const EmailTemplate = () => {
  return (
    <div className="w-full space-y-3 p-2 md:p-5">
      <p className="text-3xl font-semibold">Email Templates</p>
      <div className="space-y-24 pb-10">
        <InvitedConfirmationEmail />
        {/* <PublicConfirmationEmail /> */}
        <MeetingInvitationEmail />
        <SignInEmail />
      </div>
    </div>
  );
};

export default EmailTemplate;
