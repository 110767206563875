import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Field, Form, Formik, FormikValues, useFormikContext } from "formik";
import * as Yup from "yup";
import Button from "../../../components/Button";
import { format, isBefore } from "date-fns";
import { areasApi, Area, AllowedArea } from "../../../api/areas";
import { meetingsApi } from "../../../api/meeting";
import ButtonLoad from "../../../components/ButtonLoad";
import { MeetingRoom, meetingRoomApi } from "../../../api/meetingRooms";
import FieldError from "../../../components/FieldError";
import { ReactComponent as CancelIcon } from "../../../assets/icons/cancel.svg";
import Checkbox from "../../../components/Checkbox";
import { MagicMotion } from "react-magic-motion";
import Popup, { PopupVariant } from "../../../components/Popup";
import { getSessionAccessToken } from "../../../lib/session";
import Select from "../../../components/Select";
import { authApi } from "../../../api/auth";
import { twMerge } from "tailwind-merge";

const CreateMeeting = () => {
  const navigate = useNavigate();
  const [currentEmail, setCurrentEmail] = useState("");
  const [currentEditEmail, setCurrentEditEmail] = useState("");
  const [editingIndex, setEditingIndex] = useState(-1);
  const [emails, setEmails] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [areas, setAreas] = useState<Area[]>([]);
  const [allowedAreas, setAllowedAreas] = useState<string[]>([]);
  const [meetingRooms, setMeetingRooms] = useState<MeetingRoom[]>([]);
  const [isMicrosoftAccount, setIsMicrosoftAccount] = useState(false);

  const initialValues = {
    title: "",
    description: "",
    venue: "",
    timeStart: format(new Date(), "yyyy-MM-dd'T'HH:mm"),
    timeEnd: format(new Date(), "yyyy-MM-dd'T'HH:mm"),
    createMeetingLink: false,
  };
  const [formValues, setformValues] = useState(initialValues);

  const onTimeChanged = async (values: FormikValues) => {
    if (values.timeStart && values.timeEnd) {
      try {
        const availableMeetingRooms =
          await meetingRoomApi.getAvailableMeetingRoomsByTime(
            { start: values.timeStart, end: values.timeEnd },
            {
              headers: getSessionAccessToken(),
            },
          );

        setMeetingRooms(availableMeetingRooms);
      } catch (err: any) {
        Popup(PopupVariant.ERROR, err.response?.data?.error?.message);
      }
    }
  };

  useEffect(() => {
    if (formValues) {
      onTimeChanged(formValues);
    }
  }, [formValues]);

  const toggleAllowedAreasCheckbox = (id: string) => {
    if (allowedAreas.some((checkedArea) => checkedArea === id)) {
      // If the area is already allowed, remove it
      const updatedAllowedAreas = allowedAreas.filter(
        (checkedArea) => checkedArea !== id,
      );
      setAllowedAreas(updatedAllowedAreas);
    } else {
      // If the area is not allowed, add it
      const updatedAllowedAreas = [...allowedAreas, id];
      setAllowedAreas(updatedAllowedAreas);
    }
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(
      "It seems you forgot to enter a title. Please provide one.",
    ),
    description: Yup.string().required(
      "It seems you forgot to enter a description. Please provide one.",
    ),
    venue: Yup.string(),
    timeStart: Yup.string().required(
      "It seems you forgot to enter a start time. Please provide one.",
    ),
    timeEnd: Yup.string()
      .required("It seems you forgot to enter an end time. Please provide one.")
      .test(
        "time",
        "Oops! The end time must be later than the start time.",
        function (value) {
          const { timeStart, timeEnd } = this.parent;
          if (timeStart && timeEnd) {
            return isBefore(timeStart, timeEnd);
          }
          return true;
        },
      ),
  });
  const emailSchema = Yup.string().email(
    "Oops! The email doesn't seem to be in the correct format.",
  );
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentEmail(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" || e.key === ",") {
      if (currentEmail.trim() !== "") {
        validateAndAddEmail();
      } else {
        setErrorMessage(
          "It looks like you forgot to enter your participant's email.",
        );
      }
      e.preventDefault();
    }
  };

  const removeEmail = (indexToRemove: number) => {
    setEmails(emails.filter((_, index) => index !== indexToRemove));
  };

  const validateAndAddEmail = async () => {
    try {
      await emailSchema.validate(currentEmail.trim(), { abortEarly: false });
      setEmails([...emails, currentEmail.trim()]);
      setCurrentEmail("");
      setErrorMessage("");
    } catch (error: any) {
      setErrorMessage(error.message);
    }
  };

  const handleOnBlur = () => {
    if (currentEmail.trim() !== "") {
      validateAndAddEmail();
    }
    if (emails.length === 0) {
      setErrorMessage(
        "It looks like you forgot to enter your participant's email.",
      );
    }
    setCurrentEmail("");
  };

  const handleEditEmail = (index: number) => {
    // Make a copy of the emails array
    setEditingIndex(index);
    setCurrentEditEmail(emails[index]);
  };
  const validateEditEmail = async () => {
    try {
      await emailSchema.validate(currentEditEmail.trim(), {
        abortEarly: false,
      });
      const updatedEmails = [...emails];
      updatedEmails[editingIndex] = currentEditEmail;
      // Update the emails state with the updated array
      setEmails(updatedEmails);
      setCurrentEditEmail("");
      setErrorMessage("");
      setEditingIndex(-1);
    } catch (error: any) {
      setErrorMessage(error.message);
    }
  };
  const handleEditEmailBlur = () => {
    if (currentEditEmail.trim() !== "") {
      validateEditEmail();
    }
    if (currentEditEmail.length === 0) {
      setErrorMessage("Email is required");
    }
  };
  const handleEmailEditKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === ",") {
      e.preventDefault();
      if (currentEditEmail.trim() !== "") {
        validateEditEmail();
      } else {
        setErrorMessage("Email is required");
      }
    }
  };
  const handleSubmit = async (
    values: FormikValues,
    { setSubmitting, resetForm }: any,
  ) => {
    if (emails.length === 0 || errorMessage.length > 0) {
      setErrorMessage("Please add at least one email");
      return;
    }

    const newMeeting = {
      title: values.title,
      description: values.description,
      venue: values.venue,
      timeStart: values.timeStart,
      timeEnd: values.timeEnd,
      participants: emails,
      allowedAreas: allowedAreas,
      createMeetingLink: values.createMeetingLink,
    };
    try {
      const response = await meetingsApi.createMeeting(newMeeting, {
        headers: getSessionAccessToken(),
      });
      if (response.success) {
        Popup(PopupVariant.SUCCESS, "Meeting created successfully");
        setEmails([]);
        setSubmitting(false);
        resetForm();
        navigate("/dashboard/meetings");
      }
    } catch (err: any) {
      console.error(err);
      Popup(PopupVariant.ERROR, err.response?.data?.error?.message);
    }
  };
  useEffect(() => {
    const fetchMeetingRoom = async () => {
      try {
        const response = await meetingRoomApi.getMeetingRooms({
          headers: getSessionAccessToken(),
        });
        if (response) {
          setMeetingRooms(response);
        }
      } catch (err: any) {
        console.error(err);
        Popup(PopupVariant.ERROR, err.response?.data?.error?.message);
      }
    };
    const fetchArea = async () => {
      try {
        const response = await areasApi.getAreas({
          headers: getSessionAccessToken(),
        });
        if (response) {
          setAreas(response);
        }
      } catch (err: any) {
        console.error(err);
        Popup(PopupVariant.ERROR, err.response?.data?.error?.message);
      }
    };
    const verifyMicrosoftAccount = async () => {
      try {
        const response = await authApi.msVerify({
          headers: getSessionAccessToken(),
        });
        if (response) {
          setIsMicrosoftAccount(response.success);
        }
      } catch (err: any) {
        console.error(err);
        Popup(PopupVariant.ERROR, err.response?.data?.error?.message);
      }
    };
    fetchMeetingRoom().then(() => {});
    fetchArea().then(() => {});
    verifyMicrosoftAccount().then(() => {});
  }, []);
  const formikRef = useRef(initialValues);

  const FormikOnChange = ({ onChange }: { onChange: React.Dispatch<any> }) => {
    const { values } = useFormikContext();

    useEffect(() => {
      if (
        formValues.timeStart !== (values as any).timeStart ||
        formValues.timeEnd !== (values as any).timeEnd
      ) {
        onChange(values);
      }
    }, [values]);

    return null;
  };

  return (
    <div className="w-full space-y-5 p-2 md:p-5">
      <div className="mb-5 flex w-full justify-center">
        <p className="text-3xl font-semibold">Create Meeting</p>
      </div>

      <Formik
        innerRef={formikRef as any}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          setFieldValue,
          isSubmitting,
          resetForm,
          submitForm,
          errors,
        }) => (
          <>
            <FormikOnChange onChange={setformValues} />
            <Form className="flex w-full lg:place-items-center lg:justify-center">
              <div className="flex w-full flex-col gap-6 lg:w-1/2">
                <MagicMotion>
                  <div className="flex flex-col gap-2">
                    <Field
                      id="title"
                      name="title"
                      type="text"
                      placeholder="Title"
                      className="text-md h-12 rounded-md border border-gray-300 bg-white p-2 font-semibold shadow-md focus:outline-none focus:ring-1 focus:ring-inset focus:ring-gray-200"
                    />
                    {errors.title && <FieldError error={errors.title} />}
                  </div>
                </MagicMotion>
                <MagicMotion>
                  <div className="flex flex-col gap-2">
                    <Field
                      id="description"
                      name="description"
                      as="textarea"
                      placeholder="Description"
                      className="text-md h-28 rounded-md border border-gray-300 bg-white p-2 font-semibold shadow-md focus:outline-none focus:ring-1 focus:ring-inset focus:ring-gray-200"
                    />
                    {errors.description && (
                      <FieldError error={errors.description} />
                    )}
                  </div>
                </MagicMotion>
                <MagicMotion>
                  <div className="flex flex-col gap-2">
                    <label className="label text-md font-semibold text-gray-500">
                      Time
                    </label>
                    <div className="flex flex-col gap-4 lg:gap-2 lg:gap-x-4 xl:flex-row">
                      <div className="form-control flex flex-grow flex-col">
                        <label
                          className="label text-md font-semibold text-gray-500"
                          htmlFor="timeStart"
                        >
                          Start
                        </label>
                        <Field
                          id="timeStart"
                          name="timeStart"
                          type="datetime-local"
                          className="text-md h-12 w-full rounded-md border border-gray-300 bg-white p-2 font-semibold shadow-md focus:outline-none focus:ring-1 focus:ring-inset focus:ring-gray-200"
                        />
                      </div>
                      <div className="form-control flex flex-grow flex-col">
                        <label
                          className="label text-md font-semibold text-gray-500"
                          htmlFor="timeEnd"
                        >
                          End
                        </label>
                        <Field
                          id="timeEnd"
                          name="timeEnd"
                          type="datetime-local"
                          className="text-md h-12 w-full rounded-md border border-gray-300 bg-white p-2 font-semibold shadow-md focus:outline-none focus:ring-1 focus:ring-inset focus:ring-gray-200"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col gap-2">
                      {errors.timeStart && (
                        <FieldError error={errors.timeStart} />
                      )}
                      {errors.timeEnd && <FieldError error={errors.timeEnd} />}
                    </div>
                  </div>
                </MagicMotion>

                <MagicMotion>
                  <div className=" flex w-full flex-col gap-2 rounded-md border border-gray-100  bg-gray-50 p-4 pt-2 shadow-inner">
                    <div className="flex justify-between">
                      <p className="font-bold text-gray-500">
                        Available Meeting Rooms
                      </p>
                      <button
                        className="font-bold text-gray-500 underline"
                        type="button"
                        onClick={() => setFieldValue("venue", undefined)}
                      >
                        Clear
                      </button>
                    </div>
                    <div key="exclude" className="flex w-full flex-wrap gap-4">
                      <Select
                        name="venue"
                        values={meetingRooms.map((meetingRoom) => {
                          return {
                            displayName: meetingRoom.name,
                            value: meetingRoom.name,
                          };
                        })}
                      />
                    </div>
                    <p className="text-gray-500">
                      Note: You can leave this unselected if you want an
                      online-only meeting.
                    </p>
                  </div>
                </MagicMotion>

                <MagicMotion>
                  <div className="flex w-full flex-col gap-2 rounded-md border border-gray-100 bg-gray-50 p-4 pt-2 shadow-inner">
                    <label
                      className="label text-md font-semibold text-gray-500"
                      htmlFor="emails"
                    >
                      Invitees
                    </label>
                    <div className="flex flex-col flex-wrap gap-2">
                      {emails.map((email, index) => (
                        <div
                          key={index}
                          className="flex w-fit items-center rounded-md bg-accent-500 px-4 py-2 font-semibold text-white hover:bg-accent-600"
                        >
                          {editingIndex === index ? (
                            <Field
                              type="email"
                              value={currentEditEmail}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                              ) => setCurrentEditEmail(e.target.value)}
                              onKeyDown={handleEmailEditKeyDown}
                              onBlur={handleEditEmailBlur}
                              placeholder="Enter Email"
                              className="bg-transparent font-bold placeholder:text-white"
                              autoFocus
                            />
                          ) : (
                            <p onClick={() => handleEditEmail(index)}>
                              {email}
                            </p>
                          )}
                          <CancelIcon
                            onClick={() => removeEmail(index)}
                            className="ml-2 h-4 min-h-4 w-4 min-w-4 fill-white text-white hover:cursor-pointer"
                          />
                        </div>
                      ))}
                      <Field
                        type="email"
                        name="emails"
                        value={currentEmail}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        onBlur={handleOnBlur}
                        placeholder="Enter Email"
                        className="text-md w-64 rounded-md border border-gray-300 p-2 font-semibold shadow-sm focus:outline-none focus:ring-1 focus:ring-inset focus:ring-gray-200"
                      />
                    </div>

                    {errorMessage && <FieldError error={errorMessage} />}
                  </div>
                </MagicMotion>

                <MagicMotion>
                  <div className=" flex w-full flex-col gap-2 rounded-md border border-gray-100  bg-gray-50 p-4 pt-2 shadow-inner">
                    <div className="flex justify-between">
                      <p className="font-bold text-gray-500">Allowed Areas</p>
                    </div>
                    <div key="exclude" className="flex w-full flex-wrap gap-4">
                      {areas.map((area) => {
                        return (
                          <div
                            key={area.id}
                            className="group space-x-2 rounded-md border border-gray-300 bg-white p-2 font-semibold shadow-sm"
                          >
                            <Checkbox id={area.id}>
                              <Checkbox.Indicator
                                disabled={false}
                                onChange={() =>
                                  toggleAllowedAreasCheckbox(area.id)
                                }
                              />
                              <Checkbox.Label className="max-w-[45em]">
                                {area.name}
                              </Checkbox.Label>
                            </Checkbox>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </MagicMotion>

                <MagicMotion>
                  <div className="group space-x-2 space-y-2 rounded-md border border-gray-300 bg-white p-4 font-semibold shadow-sm">
                    <Checkbox key="exclude" id="CreateOnlineMeetingLink">
                      <Checkbox.Indicator
                        disabled={!isMicrosoftAccount}
                        key="exclude"
                        onChange={(value) =>
                          setFieldValue("createMeetingLink", value)
                        }
                      />
                      <Checkbox.Label
                        key="exclude"
                        className={twMerge(
                          "max-w-[45em]",
                          !isMicrosoftAccount && "text-gray-300",
                        )}
                      >
                        Create Microsoft Online Meeting Link
                      </Checkbox.Label>
                    </Checkbox>
                    <p className="text-gray-500">
                      Note: You need to log in using Microsoft to enable this
                      feature.
                    </p>
                  </div>
                </MagicMotion>

                <MagicMotion>
                  <div className="flex h-12 gap-4">
                    <Button
                      type="reset"
                      className="h-full border border-gray-300 bg-white text-black hover:bg-gray-300"
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      Cancel
                    </Button>

                    <Button
                      className={`bg-accent-500 text-white hover:bg-accent-600`}
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? <ButtonLoad /> : <p>Create Meeting</p>}
                    </Button>
                  </div>
                </MagicMotion>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default CreateMeeting;
