import { format, isSameDay } from "date-fns";
import { z } from "zod";

export const stringToValidDate = z.coerce
  .date()
  .transform((dateString, ctx) => {
    const date = new Date(dateString);
    if (!z.date().safeParse(date).success) {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_date,
      });
    }
    return date;
  });

export const formatDatetimeRange = (start: Date, end: Date) => {
  const startDate = format(start, "EEE MMM dd, yyyy");
  const startTime = format(start, "hh:mm a");
  const endTime = format(end, "hh:mm a");
  const timezoneString = format(end, "zzz");

  // Check if dates are the same
  if (isSameDay(start, end)) {
    // Same day, combine start and end time
    return `${startDate} ${startTime} - ${endTime} (${timezoneString})`;
  } else {
    // Different days, format separately
    const endDate = format(end, "EEE MMM dd, yyyy");
    const endTime = format(end, "hh:mm a");
    return `${startDate}  ${startTime} - ${endDate} ${endTime} ${timezoneString}`;
  }
};
