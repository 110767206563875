import { useEffect, useState } from "react";
import Logo from "./Logo";
import {
  useSearchParams,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";
import LogInForm from "./LogInForm";
import { authApi } from "../../api/auth";
import Loading from "../../components/Loading";
import { motion } from "framer-motion";
import Popup, { PopupVariant } from "../../components/Popup";
import { VMS_API_SERVER_URL, VMS_APP_URL } from "../../lib/env";
import { deleteSession, setSession } from "../../lib/session";

export default function Login() {
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const accessToken = searchParams.get("token");
  const [isCallBackExecuted, setIsCallBackExecuted] = useState(false);
  const navigate = useNavigate();

  const callBack = async () => {
    deleteSession();
    if (!code || isCallBackExecuted) return;
    try {
      const response = await authApi.msLoginCallback({
        queries: {
          code: code,
          from: VMS_APP_URL + (localStorage.getItem("from") || ""),
          fallback: VMS_APP_URL + "/login",
        },
      });

      console.log(response);

      if (response.success) {
        Popup(PopupVariant.SUCCESS, "Successfully Logged In.");
        if (response.user) {
          setSession(response.user);
          navigate("/dashboard");
        }
      }
      setIsCallBackExecuted(true);
    } catch (err: any) {
      Popup(
        PopupVariant.ERROR,
        (err.response &&
          err.response.status === 403 &&
          err.response.data.error.message) ||
          undefined,
      );

      navigate("/login");
    }
  };

  if (code) {
    callBack();
  }

  const isAuthenticated = async () => {
    try {
      if (accessToken) {
        const response = await authApi.verify({
          headers: { Authorization: `Bearer ${accessToken}` },
        });

        if (response.success) {
          setSession({ ...response.user, accessToken });
          return true;
        }
      }
    } catch (error) {
      Popup(
        PopupVariant.ERROR,
        "Please login first or check your credentials.",
      );

      return false;
    }
  };

  if (accessToken) {
    isAuthenticated().then((res) => {
      if (res) {
        navigate("/dashboard");
      }
    });
  }

  if (code && !isCallBackExecuted) {
    return <Loading />;
  }

  return (
    <div className="flex min-h-dvh flex-row bg-primary-500">
      <img
        alt="background"
        src="/images/bg_image.jpg"
        className="hidden min-h-dvh w-2/3 object-cover lg:block"
      />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="flex min-h-dvh w-full flex-1 flex-col justify-center px-6 py-12 lg:px-8"
      >
        <div className="mt-auto">
          <Logo />
          <LogInForm />
        </div>
        <p className="mt-auto text-center font-gokaizen text-sm text-accent-500">
          Powered by Gokaizen
        </p>
      </motion.div>
    </div>
  );
}
