import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ClockIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/20/solid";

import {
  startOfToday,
  format,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  startOfWeek,
  isSameMonth,
  isToday,
  isEqual,
  parse,
  addMonths,
  subDays,
  isSameDay,
  startOfMonth,
  startOfDay,
} from "date-fns";
import { Dispatch, SetStateAction, useState } from "react";
import { twMerge } from "tailwind-merge";
import { Meeting } from "../../api/meeting";

export default function MonthView({
  today,
  meetings,
  selectedDate,
  setSelectedDate,
  currentDate,
  setCurrentDate,
  setSelectedView,
  setSelectedMeeting,
}: {
  today: Date;
  meetings: Meeting[];
  selectedDate: Date;
  setSelectedDate: Dispatch<SetStateAction<Date>>;
  currentDate: Date;
  setCurrentDate: Dispatch<SetStateAction<Date>>;
  setSelectedView: Dispatch<SetStateAction<string>>;
  setSelectedMeeting: Dispatch<SetStateAction<Meeting | undefined>>;
}) {
  let days = eachDayOfInterval({
    start: startOfWeek(startOfMonth(currentDate)),
    end: endOfWeek(endOfMonth(currentDate)),
  });

  return (
    <div className="lg:flex lg:h-full lg:flex-col">
      <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
        <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
          <div className="bg-white py-2">
            S<span className="sr-only sm:not-sr-only">un</span>
          </div>
          <div className="bg-white py-2">
            M<span className="sr-only sm:not-sr-only">on</span>
          </div>
          <div className="bg-white py-2">
            T<span className="sr-only sm:not-sr-only">ue</span>
          </div>
          <div className="bg-white py-2">
            W<span className="sr-only sm:not-sr-only">ed</span>
          </div>
          <div className="bg-white py-2">
            T<span className="sr-only sm:not-sr-only">hu</span>
          </div>
          <div className="bg-white py-2">
            F<span className="sr-only sm:not-sr-only">ri</span>
          </div>
          <div className="bg-white py-2">
            S<span className="sr-only sm:not-sr-only">at</span>
          </div>
        </div>
        <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
          <div className="hidden w-full lg:grid lg:grid-cols-7 lg:gap-px">
            {days.map((day) => (
              <div
                onClick={() => setSelectedDate(day)}
                key={day.toString()}
                className={twMerge(
                  isSameMonth(day, currentDate)
                    ? "bg-white"
                    : "bg-gray-50 text-gray-500",
                  "relative px-3 py-2",
                )}
              >
                <time
                  dateTime={format(day, "yyyy-MM-dd")}
                  onClick={() => {
                    setCurrentDate(day);
                    setSelectedView("Day");
                  }}
                  className={twMerge(
                    isToday(day) && "text-accent-500",
                    isEqual(startOfDay(selectedDate), startOfDay(day))
                      ? "flex h-6 w-6 items-center justify-center rounded-full bg-primary-500 font-semibold text-white"
                      : "flex h-6 w-6 items-center justify-center",
                    "hover:cursor-pointer",
                  )}
                >
                  {format(day, "d")}
                </time>
                {meetings.filter((meeting) => isSameDay(day, meeting.timeStart))
                  .length > 0 && (
                  <ol className="mt-2">
                    {meetings
                      .filter((meeting) => isSameDay(day, meeting.timeStart))
                      .slice(0, 2)
                      .map((meeting: Meeting) => (
                        <li key={meeting.id}>
                          <a
                            onClick={() => setSelectedMeeting(meeting)}
                            className="group hover:cursor-pointer"
                          >
                            <p className="flex-auto truncate font-semibold text-gray-900 group-hover:text-primary-500">
                              {meeting.title}
                            </p>
                            <div className="hidden flex-none text-gray-500 group-hover:text-primary-500 xl:flex">
                              <time dateTime={meeting.timeStart.toString()}>
                                {format(meeting.timeStart, "HH:mm")}
                              </time>
                              <p> - </p>
                              <time dateTime={meeting.timeEnd.toString()}>
                                {format(meeting.timeEnd, "HH:mm")}
                              </time>
                            </div>
                          </a>
                        </li>
                      ))}
                    {meetings.filter((meeting) =>
                      isSameDay(day, meeting.timeStart),
                    ).length > 2 && (
                      <li
                        onClick={() => {
                          setCurrentDate(day);
                          setSelectedView("Day");
                        }}
                      >
                        <a className="group hover:cursor-pointer">
                          <p className="text-gray-500 hover:text-primary-500">
                            +{" "}
                            {meetings.filter((meeting) =>
                              isSameDay(day, meeting.timeStart),
                            ).length - 2}{" "}
                            more
                          </p>
                        </a>
                      </li>
                    )}
                  </ol>
                )}
              </div>
            ))}
          </div>
          <div className="isolate grid w-full grid-cols-7 grid-rows-6 gap-px lg:hidden">
            {days.map((day) => (
              <button
                key={day.toString()}
                type="button"
                className={twMerge(
                  isSameMonth(day, currentDate) ? "bg-white" : "bg-gray-50",
                  (isEqual(startOfDay(selectedDate), startOfDay(day)) ||
                    isToday(day)) &&
                    "font-semibold",
                  isEqual(startOfDay(selectedDate), startOfDay(day)) &&
                    "text-white",
                  !isEqual(startOfDay(selectedDate), startOfDay(day)) &&
                    isToday(day) &&
                    "text-primary-500",
                  !isEqual(startOfDay(selectedDate), startOfDay(day)) &&
                    isSameMonth(day, currentDate) &&
                    !isToday(day) &&
                    "text-gray-900",
                  !isEqual(startOfDay(selectedDate), startOfDay(day)) &&
                    !isSameMonth(day, currentDate) &&
                    !isToday(day) &&
                    "text-gray-500",
                  "flex h-14 flex-col px-3 py-2 hover:bg-gray-100 focus:z-10",
                )}
              >
                <time
                  dateTime={day.toString()}
                  className={twMerge(
                    isEqual(startOfDay(selectedDate), startOfDay(day)) &&
                      "flex h-6 w-6 items-center justify-center rounded-full",
                    isEqual(startOfDay(selectedDate), startOfDay(day)) &&
                      isToday(day) &&
                      "bg-primary-500",
                    isEqual(startOfDay(selectedDate), startOfDay(day)) &&
                      !isToday(day) &&
                      "bg-gray-900",
                    "ml-auto",
                  )}
                >
                  {format(day, "d")}
                </time>
                <span className="sr-only">
                  {
                    meetings.filter((meeting) =>
                      isSameDay(day, meeting.timeStart),
                    ).length
                  }{" "}
                  events
                </span>
                {meetings.filter((meeting) => isSameDay(day, meeting.timeStart))
                  .length > 0 && (
                  <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                    {meetings
                      .filter((meeting) => isSameDay(day, meeting.timeStart))
                      .map((event: any) => (
                        <span
                          key={event.id}
                          className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-gray-400"
                        />
                      ))}
                  </span>
                )}
              </button>
            ))}
          </div>
        </div>
      </div>
      {meetings.filter((meeting) => isSameDay(selectedDate, meeting.timeStart))
        .length > 0 && (
        <div className="px-4 py-10 sm:px-6 lg:hidden">
          <ol className="divide-y divide-gray-100 overflow-hidden rounded-lg bg-white text-sm shadow ring-1 ring-black ring-opacity-5">
            {meetings
              .filter((meeting) => isSameDay(selectedDate, meeting.timeStart))
              .map((meeting) => (
                <li
                  key={meeting.id}
                  className="group flex p-4 pr-6 focus-within:bg-gray-50 hover:bg-gray-50"
                >
                  <div className="flex-auto">
                    <p className="font-semibold text-gray-900">
                      {meeting.title}
                    </p>
                    <time
                      dateTime={meeting.timeStart.toString()}
                      className="mt-2 flex items-center text-gray-700"
                    >
                      <ClockIcon
                        className="mr-2 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <time dateTime={meeting.timeStart.toString()}>
                        {format(meeting.timeStart, "HH:mm")}
                      </time>
                      <p> - </p>
                      <time dateTime={meeting.timeEnd.toString()}>
                        {format(meeting.timeEnd, "HH:mm")}
                      </time>
                    </time>
                  </div>
                  <a
                    href={meeting.url || ""}
                    className="ml-6 flex-none self-center rounded-md bg-white px-3 py-2 font-semibold text-gray-900 opacity-0 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400 focus:opacity-100 group-hover:opacity-100"
                  >
                    Edit<span className="sr-only">, {meeting.title}</span>
                  </a>
                </li>
              ))}
          </ol>
        </div>
      )}
    </div>
  );
}
