import {
  add,
  differenceInMinutes,
  eachMinuteOfInterval,
  endOfDay,
  format,
  isSameDay,
  startOfDay,
  startOfToday,
} from "date-fns";
import { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { Meeting } from "../../api/meeting";

export default function DayView({
  meetings,
  selectedDate,
  currentDate,
  setSelectedMeeting,
}: {
  setSelectedView: Dispatch<SetStateAction<string>>;
  meetings: Meeting[];
  selectedDate: Date;
  currentDate: Date;
  setSelectedMeeting: Dispatch<SetStateAction<Meeting | undefined>>;
}) {
  let hours = eachMinuteOfInterval(
    {
      start: startOfDay(currentDate),
      end: endOfDay(currentDate),
    },
    { step: 15 },
  );

  const sameTimeElement = useRef<HTMLDivElement>(null);

  useEffect(() => {
    sameTimeElement.current?.scrollIntoView();
  }, [selectedDate]);

  return (
    <div className="flex h-full flex-col">
      <div className="isolate flex flex-auto overflow-hidden bg-white">
        <div className="flex flex-auto flex-col overflow-auto">
          <div className="flex w-full flex-auto">
            <div className="w-16 flex-none bg-white ring-1 ring-gray-100" />
            <div className="grid flex-auto grid-cols-1 grid-rows-1">
              {/* Horizontal lines */}
              <div
                className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100"
                style={{
                  gridTemplateRows: `repeat(${hours.length}, minmax(3.5rem, 1fr))`,
                }}
              >
                <div className="row-end-1 h-7"></div>
                {hours.map((hour) => (
                  <div
                    ref={
                      differenceInMinutes(hour, selectedDate) < 15
                        ? sameTimeElement
                        : undefined
                    }
                  >
                    <div className="sticky left-0 -ml-16 -mt-2.5 w-16 pr-2 text-right text-xs leading-5 text-gray-400">
                      {format(hour, "HH:mm")}
                    </div>
                  </div>
                ))}
                <div />
              </div>

              {/* Meetings */}
              <ol
                className="col-start-1 col-end-2 row-start-1 grid grid-cols-1 overflow-x-hidden"
                style={{
                  gridTemplateRows: "1.75rem repeat(288, minmax(0, 1fr)) auto",
                }}
              >
                {meetings
                  .filter((meeting) =>
                    isSameDay(meeting.timeStart, currentDate),
                  )
                  .map((meeting) => (
                    <li
                      className="relative mt-px flex"
                      style={{
                        gridRow: `${
                          Math.round(
                            differenceInMinutes(
                              meeting.timeStart,
                              startOfDay(currentDate),
                            ) / 5,
                          ) + 2
                        } / span ${Math.round(
                          differenceInMinutes(
                            meeting.timeEnd,
                            meeting.timeStart,
                          ) / 5,
                        )}`,
                      }}
                    >
                      <a
                        onClick={() => setSelectedMeeting(meeting)}
                        className="group absolute inset-1 flex items-center justify-between overflow-y-auto rounded-lg bg-blue-50 p-2 text-xs leading-5 hover:cursor-pointer hover:bg-blue-100"
                      >
                        <p className="font-semibold">{meeting.title}</p>
                        <div className="hidden flex-none text-blue-500 group-hover:text-blue-700 xl:flex">
                          {isSameDay(meeting.timeStart, meeting.timeEnd) ? (
                            <time dateTime={meeting.timeStart.toString()}>
                              {`${format(meeting.timeStart, "HH:mm")} - ${format(meeting.timeEnd, "HH:mm")}`}
                            </time>
                          ) : (
                            <time dateTime={meeting.timeStart.toString()}>
                              {`${format(meeting.timeStart, "MMMM dd HH:mm")} - ${format(meeting.timeEnd, "MMMM dd HH:mm")}`}
                            </time>
                          )}
                        </div>
                      </a>
                    </li>
                  ))}
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
