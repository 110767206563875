import React from "react";
import Button from "./Button";
import { useNavigate } from "react-router-dom";

function NoMeetingsFound() {
  const navigate = useNavigate();
  return (
    <div className="relative isolate flex h-full w-full flex-grow justify-center gap-2 p-4">
      <div className="absolute inset-y-0 right-0 flex w-1/2 items-center justify-center">
        <div className="absolute inset-0 bg-gradient-to-r from-white from-10% to-transparent to-70% max-md:bg-white max-md:bg-opacity-40"></div>
        <img
          src="/images/meeting-room.jpg"
          alt="Your Image"
          className="h-auto min-h-full max-w-full object-cover"
        />
      </div>
      <div className="absolute inset-y-0 left-0 flex h-full w-full items-center justify-start">
        <div className="p-12">
          <h1 className="text-6xl font-black tracking-tight text-gray-800 max-md:text-4xl">
            Schedule a meeting
          </h1>
          <p className="pb-8 text-2xl text-gray-600 max-md:text-xl lg:w-[20em]">
            Boost efficiency by setting up productive discussions.
          </p>
          <Button
            type="button"
            className="max-md:24 w-64 bg-accent-500 p-6 text-xl text-white hover:bg-accent-600 max-md:p-4 max-md:text-lg"
            onClick={() => navigate("/dashboard/meetings/create")}
          >
            Create Meeting
          </Button>
        </div>
      </div>
    </div>
  );
}

export default NoMeetingsFound;
