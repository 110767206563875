import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { format, formatDistanceToNowStrict, isFuture, isPast } from "date-fns";
import Button from "../../../components/Button";
import { Meeting, meetingsApi } from "../../../api/meeting";
import Table from "../../../components/Table";

import { ReactComponent as ListIcon } from "../../../assets/icons/list.svg";
import { ReactComponent as CalendarIcon } from "../../../assets/icons/calendar.svg";
import { twMerge } from "tailwind-merge";
import Calendar from "../../../components/calendar";
import NoMeetingsFound from "../../../components/NoMeetingsFound";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import { EllipsisHorizontalIcon } from "@heroicons/react/20/solid";
import { MeetingRoom, meetingRoomApi } from "../../../api/meetingRooms";
import Popup, { PopupVariant } from "../../../components/Popup";
import { getSession, getSessionAccessToken } from "../../../lib/session";

const columns = [
  {
    accessorKey: "title",
    header: "TITLE",
    cell: (props: any) => <p>{props.getValue()}</p>,
  },
  {
    accessorKey: "timeStart",
    header: "START TIME",
    cell: (props: any) => (
      <p>{format(props.getValue(), "MMMM dd, yyyy hh:mm a")}</p>
    ),
  },
  {
    accessorKey: "timeEnd",
    header: "END TIME",
    cell: (props: any) => (
      <p>{format(props.getValue(), "MMMM dd, yyyy hh:mm a")}</p>
    ),
  },
  {
    accessorKey: "venue",
    header: "VENUE",
    cell: (props: any) => <p>{props.getValue()}</p>,
  },
  {
    accessorKey: "host",
    header: "HOST",
    cell: (props: any) => <p>{props.getValue()}</p>,
  },
  {
    header: "STATUS",
    cell: (props: any) => (
      <>
        {props.row.original.isCancelled ? (
          <span className="inline-flex items-center rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-600">
            Cancelled
          </span>
        ) : (
          <p>
            {isFuture(props.row.original.timeStart) && (
              <span className="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">
                {`Starting in ${formatDistanceToNowStrict(props.row.original.timeStart)}`}
              </span>
            )}
            {isPast(props.row.original.timeStart) &&
              isFuture(props.row.original.timeEnd) && (
                <span className="inline-flex items-center rounded-md bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-600">
                  {`Started ${formatDistanceToNowStrict(props.row.original.timeStart)} ago`}
                </span>
              )}
            {isPast(props.row.original.timeEnd) && (
              <span className="inline-flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-600">
                {`Completed ${formatDistanceToNowStrict(props.row.original.timeEnd)} ago`}
              </span>
            )}
          </p>
        )}
      </>
    ),
  },
];
const Meetings = () => {
  const navigate = useNavigate();
  const [meetings, setMeetings] = useState<Meeting[]>([]);
  const [selectedView, setSelectedView] = useState("Table");
  const [selectedMeeting, setSelectedMeeting] = useState<Meeting | undefined>(
    undefined,
  );
  const [meetingRooms, setMeetingRooms] = useState<MeetingRoom[]>([]);
  const [session, setSession] = useState(getSession());

  const fetchMeetingRooms = async () => {
    try {
      const response = await meetingRoomApi.getMeetingRooms({
        headers: getSessionAccessToken(),
      });
      if (response) {
        setMeetingRooms(response);
      }
    } catch (err: any) {
      console.error(err);
      Popup(PopupVariant.ERROR, err.response?.data?.error?.message);
    }
  };

  useEffect(() => {
    const fetchMeetings = async () => {
      try {
        const response = await meetingsApi.getMeetings({
          headers: getSessionAccessToken(),
        });
        if (response) {
          setMeetings(response);
        }
      } catch (err) {
        console.log(err);
        Popup(PopupVariant.ERROR);
      }
    };
    fetchMeetings();
    fetchMeetingRooms();
  }, []);

  useEffect(() => {
    if (selectedMeeting && session.role !== "visitor")
      navigate(
        `${session.role === "visitor" ? "" : "/dashboard/meetings"}/${selectedMeeting.id}`,
      );
  }, [selectedMeeting]);

  return (
    <>
      {meetings.length === 0 && <NoMeetingsFound />}
      {meetings.length > 0 && (
        <div className="w-full p-4 lg:p-6">
          <div className="flex flex-row justify-between">
            <p className="text-3xl font-semibold">Meetings</p>
            <div className="hidden gap-4 lg:flex">
              {session.role !== "visitor" && (
                <Button
                  type="button"
                  className="bg-accent-500 px-11 text-white hover:bg-accent-600"
                  onClick={() => navigate("/dashboard/meetings/create")}
                >
                  Schedule Meeting
                </Button>
              )}
              <div className="flex items-center divide-x-2 divide-gray-200 rounded-lg border border-gray-100 p-2 text-black shadow-inner">
                <ListIcon
                  onClick={() => {
                    setSelectedView("Table");
                  }}
                  className={twMerge(
                    "h-6 fill-gray-400 hover:cursor-pointer",
                    selectedView === "Table" && "h-7 fill-gray-600",
                  )}
                />
                <CalendarIcon
                  onClick={() => {
                    setSelectedView("Calendar");
                  }}
                  className={twMerge(
                    "h-6 fill-gray-400 hover:cursor-pointer",
                    selectedView === "Calendar" && "h-7 fill-gray-600",
                  )}
                />
              </div>
            </div>
            <Menu as="div" className="relative z-50 ml-6 md:hidden">
              <MenuButton className="-mx-2 flex items-center rounded-full border border-transparent p-2 text-gray-400 hover:text-gray-500">
                <span className="sr-only">Open menu</span>
                <EllipsisHorizontalIcon
                  className="h-5 w-5"
                  aria-hidden="true"
                />
              </MenuButton>

              <Transition
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <MenuItems className="absolute right-0 z-10 mt-3 w-36 origin-top-right divide-y divide-gray-100 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <MenuItem>
                      {({ focus }: { focus: boolean }) => (
                        <button
                          onClick={() => navigate("/dashboard/meetings/create")}
                          className={twMerge(
                            focus
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block w-full px-4 py-2 text-left text-sm",
                          )}
                        >
                          Schedule Meeting
                        </button>
                      )}
                    </MenuItem>
                  </div>
                  <div className="py-1">
                    <MenuItem>
                      {({ focus }: { focus: boolean }) => (
                        <button
                          onClick={() => setSelectedView("Table")}
                          className={twMerge(
                            focus
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block w-full px-4 py-2 text-left text-sm",
                          )}
                        >
                          Table view
                        </button>
                      )}
                    </MenuItem>
                    <MenuItem>
                      {({ focus }: { focus: boolean }) => (
                        <button
                          onClick={() => setSelectedView("Calendar")}
                          className={twMerge(
                            focus
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block w-full px-4 py-2 text-left text-sm",
                          )}
                        >
                          Calendar view
                        </button>
                      )}
                    </MenuItem>
                  </div>
                </MenuItems>
              </Transition>
            </Menu>
          </div>

          {selectedView === "Table" && (
            <Table
              data={meetings}
              columns={columns}
              onItemClicked={(meeting) =>
                navigate(["/dashboard/meetings", meeting.id].join("/"))
              }
              noDataElement={<p>No Meetings Found.</p>}
            />
          )}
          {selectedView === "Calendar" && (
            <div className="mt-6 flex overflow-hidden rounded-lg border border-gray-200 shadow">
              <Calendar
                meetings={meetings}
                meetingRooms={meetingRooms}
                setSelectedMeeting={setSelectedMeeting}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Meetings;
