import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import BgImage from "../../components/BgImage";
import Card from "../../components/Card";
import Button from "../../components/Button";
import { motion, AnimatePresence } from "framer-motion";
import { formatDatetimeRange } from "../../lib/utils";
import { Meeting, meetingsApi, publicMeetingApi } from "../../api/meeting";
import Loading from "../../components/Loading";
import Popup, { PopupVariant } from "../../components/Popup";
import { getSessionAccessToken } from "../../lib/session";

const MeetingDetails = () => {
  const [meeting, setMeeting] = useState<
    Omit<Meeting, "createdAt"> | undefined
  >(undefined);
  const [time, setTime] = useState<string>();
  const { id, participantId } = useParams();

  const fetchMeeting = async () => {
    if (!id) {
      return;
    }
    try {
      const meetingData = await publicMeetingApi.getPublicMeeting({
        params: { id: id },
      });

      if (meetingData) {
        setMeeting(meetingData);

        setTime(
          formatDatetimeRange(meetingData.timeStart, meetingData.timeEnd),
        );
      }
    } catch (err: any) {
      console.error(err);
      Popup(PopupVariant.ERROR, err.response?.data?.error?.message);
    }
  };

  useEffect(() => {
    fetchMeeting();
  }, []);

  return (
    <div className="relative min-h-dvh w-full">
      <div className="">
        <BgImage />
      </div>
      {!meeting ? (
        <Loading />
      ) : (
        <div className="flex min-h-dvh w-full flex-col items-center justify-center px-5 lg:px-40">
          <AnimatePresence>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.25 }}
              exit={{ opacity: 0 }}
              className="w-full gap-2 p-5 text-sm md:w-10/12 lg:w-1/2"
            >
              <h1 className="mb-4 text-3xl font-bold text-gray-50 drop-shadow-lg">
                Meeting Details
              </h1>
              <div className="flex flex-col space-y-10">
                <Card
                  title={meeting.title}
                  time={time}
                  place={meeting.venue}
                  host={meeting.host}
                />
                <Card description={meeting.description} />
              </div>
              <div className="mt-10 flex h-14 justify-center gap-20">
                <Link
                  to={`/invited/registration-details/?meetingId=${id}&participantId=${participantId}`}
                  className="w-full"
                >
                  <Button className="h-full border border-accent-600 bg-accent-500 text-white hover:bg-accent-600">
                    Register
                  </Button>
                </Link>
              </div>
            </motion.div>
          </AnimatePresence>
        </div>
      )}
    </div>
  );
};

export default MeetingDetails;
