import { Component } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Login from "./pages/login/login";
import InvitedRoutes from "./InvitedRoutes";
import { DashboardRoutes } from "./pages/dashboard";
import NotFound from "./pages/notFound/NotFound";
import Layout from "./Layout";

const queryClient = new QueryClient();
class App extends Component {
  render() {
    return (
      <QueryClientProvider client={queryClient}>
        <Router>
          <Routes>
            <Route path="/" element={<Login />}></Route>
            <Route path="/login" element={<Login />}></Route>
            <Route path="/invited/*" element={<InvitedRoutes />} />
            <Route path="/dashboard/*" element={<DashboardRoutes />} />
            <Route path="*" element={<Layout />}>
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </Router>
      </QueryClientProvider>
    );
  }
}

export default App;
