import { useRef, useState, useEffect } from "react";
import { Formik, Form, FormikValues } from "formik";
import * as Yup from "yup";
import ButtonCom from "../../../components/Button";
import {
  type EmailTemplate as emailTemplate,
  emailTemplateApi,
} from "../../../api/emailTemplate";
import { FileTrigger, Button } from "react-aria-components";
import { format } from "date-fns";
import Table from "../../../components/Table";
import ButtonLoad from "../../../components/ButtonLoad";
import Popup, { PopupVariant } from "../../../components/Popup";
import { getSessionAccessToken } from "../../../lib/session";
const MeetingInvitationEmail = () => {
  const invitationEmailColumns = [
    {
      accessorKey: "fileName",
      header: "File Name",
      cell: (props: any) => <p>{props.getValue()}</p>,
    },
    {
      accessorKey: "dateAdded",
      header: "Date Added",
      cell: (props: any) => (
        <p>{format(props.getValue(), "MMMM dd, yyyy hh:mm a")}</p>
      ),
    },
    {
      header: "Actions",
      cell: (props: any) => (
        <div className="flex flex-row place-items-center justify-center gap-2">
          <div className="w-40">
            {!props.row.original.isCurrent ? (
              <ButtonCom
                className={` bg-accent-500 text-sm 
              text-white
              hover:bg-accent-600`}
                onClick={() =>
                  newTemplate(
                    props.row.original.id,
                    props.row.original.fileName,
                  )
                }
              >
                Use As Template
              </ButtonCom>
            ) : (
              ""
            )}
          </div>
          <div className="w-40">
            <ButtonCom
              className="border border-gray-400 bg-gray-100 text-sm text-black hover:bg-gray-200"
              onClick={() =>
                deleteTemplate(
                  props.row.original.id,
                  props.row.original.fileName,
                )
              }
            >
              Delete
            </ButtonCom>
          </div>
        </div>
      ),
    },
  ];
  const [invitationEmailTemplates, setInvitationEmailTemplates] = useState<
    emailTemplate[]
  >([]);
  const initialValues = { emailTemplateFile: null };
  const validationInvitationEmailTemplateSchema = Yup.object().shape({
    invitationEmailTemplateFile: Yup.mixed()
      .test("fileType", "File must be an html type", (value: any) => {
        if (!value) return true;
        return value.type === "text/html";
      })
      .test(
        "fileSize",
        "File size must be less than or equal to 10MB",
        (value: any) => {
          if (!value) return true;
          return value.size <= 10485760;
        },
      )
      .required("Email Template is required"),
  });
  const fetchInvitationEmailTemplateData = async () => {
    try {
      const response = await emailTemplateApi.getInvitationTemplate({
        headers: getSessionAccessToken(),
      });
      if (response) {
        setInvitationEmailTemplates(response);
      }
    } catch (err: any) {
      console.error(err);
      Popup(PopupVariant.ERROR, err.response?.data?.error?.message);
    }
  };
  const newTemplate = async (id: string, fileName: string) => {
    const result = await Popup(
      PopupVariant.CONFIRM,
      `Do you want to use ${invitationEmailTemplates.find((invitationEmailTemplate) => invitationEmailTemplate.id === id)?.fileName} as the default template?`,
    );

    if (result.isConfirmed) {
      try {
        const data = {
          id: id,
          fileName: fileName,
        };

        const response = await emailTemplateApi.useAsInvitationTemplate(
          JSON.parse(JSON.stringify(data)),
          {
            headers: getSessionAccessToken(),
          },
        );
        if (response.success) {
          fetchInvitationEmailTemplateData();
          Popup(PopupVariant.SUCCESS, "Successfully Used");
        }
      } catch (err: any) {
        console.error(err);
        Popup(PopupVariant.ERROR, err.response?.data?.error?.message);
      }
    }
  };

  const deleteTemplate = async (id: string, fileName: string) => {
    const result = await Popup(
      PopupVariant.CONFIRM,
      `Do you want to delete ${invitationEmailTemplates.find((invitationEmailTemplate) => invitationEmailTemplate.id === id)?.fileName}?`,
    );

    if (result.isConfirmed) {
      try {
        const data = {
          id: id,
          fileName: fileName,
        };

        const response = await emailTemplateApi.deleteInvitationTemplate(
          JSON.parse(JSON.stringify(data)),
          {
            headers: getSessionAccessToken(),
          },
        );
        if (response.success) {
          fetchInvitationEmailTemplateData();
          Popup(PopupVariant.SUCCESS, "Successfully Deleted");
        }
      } catch (err: any) {
        console.error(err);
        Popup(PopupVariant.ERROR, err.response?.data?.error?.message);
      }
    }
  };
  const submitInvitationEmailTemplate = async (
    values: FormikValues,
    { setSubmitting, resetForm }: any,
  ) => {
    try {
      const data = new FormData();
      data.append("emailTemplateFile", values.invitationEmailTemplateFile);

      // @ts-ignore
      const response = await emailTemplateApi.addInvitationEmailTemplate(data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "multipart/form-data",
          ...getSessionAccessToken(),
        },
      });
      if (response.success) {
        Popup(PopupVariant.SUCCESS, "Successfully Added");
        fetchInvitationEmailTemplateData();
        resetForm();
      }
    } catch (err: any) {
      console.error(err);
      Popup(PopupVariant.ERROR, err.response?.data?.error?.message);
    }
    setSubmitting(false);
  };

  const downloadTemplate = async () => {
    try {
      const response = await emailTemplateApi.downloadInvitationEmailTemplate({
        headers: getSessionAccessToken(),
      });
      if (response.success) {
        const file = new Blob([response.file], {
          type: "application/octet-stream",
        });
        const url = window.URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "meeting-invitation-email-template.html");
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
      }
    } catch (err) {
      console.log(err);
      Popup(PopupVariant.ERROR);
    }
  };

  useEffect(() => {
    fetchInvitationEmailTemplateData();
  }, []);

  const formikRef = useRef(initialValues);
  return (
    <div>
      <Formik
        innerRef={formikRef as any}
        initialValues={initialValues}
        validationSchema={validationInvitationEmailTemplateSchema}
        onSubmit={submitInvitationEmailTemplate}
      >
        {({
          values,
          setFieldValue,
          isSubmitting,
          resetForm,
          submitForm,
          errors,
        }: any) => (
          <Form className="w-full">
            <div className="flex flex-col gap-3">
              <div className="flex place-items-center justify-between">
                <p className="text-xl font-semibold">
                  Meeting Invitation Email Template
                </p>
                <div className="w-40">
                  <ButtonCom
                    type="button"
                    className="bg-accent-500 text-sm text-white hover:bg-accent-600"
                    onClick={() => downloadTemplate()}
                  >
                    Download Template
                  </ButtonCom>
                </div>
              </div>
              <div className="flex flex-col gap-x-5 gap-y-2 lg:flex-row lg:place-items-center">
                <label
                  htmlFor="invitationEmailTemplateFile"
                  className="label text-md font-semibold text-gray-400"
                >
                  Add Email Template :
                </label>
                <div className="flex gap-x-5 md:gap-x-10 lg:gap-x-20">
                  <div className="flex flex-col">
                    <FileTrigger
                      acceptedFileTypes={["text/html"]}
                      onSelect={(e: FileList | null) => {
                        if (e) {
                          const files = Array.from(e);
                          let filenames = files.map((file) => file.name);
                          setFieldValue(
                            "invitationEmailTemplateFile",
                            files[0],
                          );
                        }
                      }}
                    >
                      <Button
                        className={
                          "w-52 rounded-lg border border-gray-300 bg-gray-100 px-5 py-2 text-sm text-black hover:bg-gray-200"
                        }
                      >
                        {values.invitationEmailTemplateFile ? (
                          values.invitationEmailTemplateFile.name
                        ) : (
                          <p className="text-md font-semibold text-gray-400">
                            Select Html File
                          </p>
                        )}
                      </Button>
                    </FileTrigger>
                    {errors.invitationEmailTemplateFile && (
                      <p className="text-red-500">
                        {errors.invitationEmailTemplateFile}
                      </p>
                    )}
                  </div>
                  <div className="w-28 md:w-52">
                    <ButtonCom
                      type="submit"
                      className="bg-accent-500 text-white hover:bg-accent-600"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? <ButtonLoad /> : <p>Submit</p>}
                    </ButtonCom>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <Table
        data={invitationEmailTemplates}
        columns={invitationEmailColumns}
        onItemClicked={() => {}}
        noDataElement={<p>No Email Templates Found.</p>}
      />
    </div>
  );
};

export default MeetingInvitationEmail;
