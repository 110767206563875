const InvitedOnly = () => {
  return (
    <div className="mb:mx-0 mx-5 flex h-64 place-items-center justify-center rounded-xl border border-gray-300 bg-white p-5 shadow-lg">
      <p className="text-lg font-semibold">
        Sorry. You need to be invited to be able to register.
      </p>
    </div>
  );
};

export default InvitedOnly;
