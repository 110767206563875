import { useState, useEffect } from "react";
import Table from "../../../components/Table";
import ButtonCom from "../../../components/Button";
import Popup, { PopupVariant } from "../../../components/Popup";
import ButtonLoad from "../../../components/ButtonLoad";
import { ErrorMessage, Field, Form, Formik, FormikValues } from "formik";
import * as Yup from "yup";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { User, userApi } from "../../../api/users";
import { getSessionAccessToken } from "../../../lib/session";

const Users = () => {
  const [editingRow, setEditingRow] = useState<string | null>(null);
  const [selectedRole, setSelectedRole] = useState<string>("");
  const [users, setUsers] = useState<User[]>([]);
  const roles = ["admin", "user", "visitor"];

  const usersColumns = [
    {
      accessorKey: "name",
      header: "Name",
      cell: (props: any) => <p>{props.getValue()}</p>,
    },
    {
      accessorKey: "username",
      header: "User Name",
      cell: (props: any) => <p>{props.getValue()}</p>,
    },
    {
      accessorKey: "role",
      header: "Role",
      cell: (props: any) => {
        const isEditing = editingRow === props.row.original.id;

        return isEditing ? (
          <select
            value={selectedRole}
            onChange={(e) => setSelectedRole(e.target.value)}
            className="rounded-md border border-gray-400 p-2 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 "
          >
            {roles.map((role) => (
              <option key={role} value={role} className=" font-delivery">
                {role}
              </option>
            ))}
          </select>
        ) : (
          <p>{props.getValue() !== "pending" ? props.getValue() : "N/A"}</p>
        );
      },
    },
    {
      accessorKey: "isPending",
      header: "Pending",
      cell: (props: any) => <p>{props.getValue() ? "Yes" : "No"}</p>,
    },
    {
      header: "Actions",
      cell: (props: any) => {
        const isEditing = editingRow === props.row.original.id;
        return (
          <>
            <div className="flex flex-row place-items-center justify-center gap-2">
              {props.row.original.isPending ? (
                <div className="w-40">
                  <ButtonCom
                    className="bg-accent-500 text-sm 
              text-white
              hover:bg-accent-600"
                    onClick={() => allowUser(props.row.original.id)}
                  >
                    Allow
                  </ButtonCom>
                </div>
              ) : (
                ""
              )}
              <div className="w-40">
                {props.row.original.name !== "admin" && (
                  <ButtonCom
                    className="border border-gray-300 bg-gray-100 text-sm text-black hover:bg-gray-200"
                    onClick={() =>
                      isEditing
                        ? saveRoleChange(props.row.original.id)
                        : startEditing(
                            props.row.original.id,
                            props.row.original.role,
                          )
                    }
                  >
                    {isEditing ? "Save" : "Change Role"}
                  </ButtonCom>
                )}
              </div>
              <div className="w-40">
                {props.row.original.name !== "admin" &&
                  props.row.original.username !== "admin" && (
                    <ButtonCom
                      className="border border-gray-300 bg-gray-100 text-sm text-black hover:bg-gray-200"
                      onClick={() => deleteUser(props.row.original.id)}
                    >
                      Delete
                    </ButtonCom>
                  )}
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const startEditing = (id: string, currentRole: string) => {
    setEditingRow(id);
    setSelectedRole(currentRole);
  };

  const saveRoleChange = async (id: string) => {
    try {
      await userApi.changeUserRole(
        { id: id, role: selectedRole },
        { headers: getSessionAccessToken() },
      );
      setEditingRow(null);
      fetchUsers();
    } catch (err: any) {
      console.error(err);
      Popup(PopupVariant.ERROR, err.response?.data?.error?.message);
    }
  };

  const MySwal = withReactContent(Swal);

  const addUserSchema = Yup.object().shape({
    username: Yup.string().required("Username is required"),
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .required("Confirm password is required")
      .test("confirmpassword", "Passwords do not match", function (value) {
        return this.parent.password === value;
      }),
    name: Yup.string().required("Name is required"),
    role: Yup.string().required("Role is required"),
  });

  const RecoveryCodeSwalContent = ({
    recoveryCode,
  }: {
    recoveryCode: string;
  }) => {
    const [isCopied, setIsCopied] = useState(false);

    const handleCopy = async () => {
      await navigator.clipboard.writeText(recoveryCode);
      setIsCopied(true);
    };

    return (
      <div className="flex w-full flex-col items-center overflow-hidden">
        <p>Please copy and save the recovery code</p>
        <div className="mt-3 flex flex-row items-center gap-3">
          <p>
            Recovery Code: <span className="font-semibold">{recoveryCode}</span>
          </p>
          <div className="w-30">
            <ButtonCom
              className="mb-3 border-2 border-gray-300 px-5 shadow-lg hover:bg-gray-200"
              onClick={handleCopy}
            >
              Copy
            </ButtonCom>
          </div>
        </div>
        {isCopied && <p className="text-red-600">Recovery code copied!</p>}
      </div>
    );
  };
  const addUserSubmit = async (values: FormikValues) => {
    try {
      const user = {
        username: values.username,
        password: values.password,
        name: values.name,
        role: values.role,
      };
      const response = await userApi.addUser(user, {
        headers: getSessionAccessToken(),
      });
      fetchUsers();
      if (response.success) {
        MySwal.fire({
          icon: "success",
          title: "Successfully Added",
          showCancelButton: false,
          confirmButtonColor: "#D40511",
          allowOutsideClick: false,

          html: (
            <RecoveryCodeSwalContent recoveryCode={response.recoveryCode} />
          ),
        });
      }
    } catch (err: any) {
      console.error(err);
      Popup(PopupVariant.ERROR, err.response?.data?.error?.message);
    }
  };

  const addUserForm = async () => {
    await MySwal.fire({
      title: "Add New User",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: () => !Swal.isLoading(),
      html: (
        <Formik
          initialValues={{
            username: "",
            password: "",
            confirmPassword: "",
            name: "",
            role: "",
          }}
          validationSchema={addUserSchema}
          onSubmit={addUserSubmit}
        >
          {({ isSubmitting, values, errors }: any) => (
            <Form className="space-y-3">
              <div className="flex flex-col items-start">
                <label
                  className="label text-md font-semibold text-gray-500"
                  htmlFor="username"
                >
                  Username
                </label>
                <Field
                  id="username"
                  name="username"
                  type="text"
                  placeholder="Enter User Name"
                  className="w-full rounded-lg border border-gray-400 p-3 font-semibold placeholder-gray-400"
                  required
                />
                <ErrorMessage
                  name="username"
                  component="div"
                  className="text-sm text-red-500"
                />
              </div>
              <div className="flex flex-col items-start">
                <label
                  className="label text-md font-semibold text-gray-500"
                  htmlFor="password"
                >
                  Password
                </label>
                <Field
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Enter New Password"
                  className="w-full rounded-lg border border-gray-400 p-3 font-semibold placeholder-gray-400"
                  required
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-sm text-red-500"
                />
              </div>
              <div className="flex flex-col items-start">
                <label
                  className="label text-md font-semibold text-gray-500"
                  htmlFor="confirmPassword"
                >
                  Confirm Password
                </label>
                <Field
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  placeholder="Confirm New Password"
                  className="w-full rounded-lg border border-gray-400 p-3 font-semibold placeholder-gray-400"
                  required
                />
                <ErrorMessage
                  name="confirmPassword"
                  component="div"
                  className="text-sm text-red-500"
                />
              </div>
              <div className="flex flex-col items-start">
                <label
                  className="label text-md font-semibold text-gray-500"
                  htmlFor="name"
                >
                  Name
                </label>
                <Field
                  id="name"
                  name="name"
                  type="text"
                  placeholder="Enter Name"
                  className="w-full rounded-lg border border-gray-400 p-3 font-semibold placeholder-gray-400"
                  required
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="text-sm text-red-500"
                />
              </div>
              <div className="flex flex-col items-start">
                <label
                  className="label text-md font-semibold text-gray-500"
                  htmlFor="role"
                >
                  Role
                </label>
                <Field
                  id="role"
                  as="select"
                  name="role"
                  placeholder="Confirm New Password"
                  className="text-md focus:shadow-outline block h-12 w-full rounded-md border border-gray-400 bg-white p-2 font-semibold leading-tight shadow-md focus:outline-none focus:ring-1 focus:ring-inset focus:ring-gray-200 "
                  required
                >
                  <option className="font-delivery font-bold" value="">
                    Select Role
                  </option>
                  {roles.map((role) => {
                    return (
                      <option
                        className="font-delivery font-bold"
                        key={role}
                        value={role}
                      >
                        {role}
                      </option>
                    );
                  })}
                </Field>
                <ErrorMessage
                  name="role"
                  component="div"
                  className="text-sm text-red-500"
                />
              </div>
              <div className="flex justify-center gap-3">
                <div className="w-1/3">
                  <ButtonCom
                    type="submit"
                    disabled={isSubmitting}
                    className="rounded-md bg-accent-500 py-3 font-semibold text-white hover:bg-accent-600"
                  >
                    {isSubmitting ? <ButtonLoad /> : "Submit"}
                  </ButtonCom>
                </div>
                <div className="w-1/3">
                  <ButtonCom
                    type="button"
                    onClick={() => MySwal.close()}
                    className="rounded-md bg-gray-100 py-3 font-semibold text-black hover:bg-gray-200"
                  >
                    Cancel
                  </ButtonCom>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ),
    });
  };

  const fetchUsers = async () => {
    try {
      const response = await userApi.getUsers({
        headers: getSessionAccessToken(),
      });
      if (response) {
        setUsers(response);
      }
    } catch (err: any) {
      console.error(err);
      Popup(PopupVariant.ERROR, err.response?.data?.error?.message);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const deleteUser = async (id: string) => {
    const result = await Popup(
      PopupVariant.CONFIRM,
      `Do you want to delete ${users.find((user) => user.id === id)?.name}?`,
    );
    if (result.isConfirmed) {
      try {
        const response = await userApi.deleteUser(undefined, {
          queries: {
            id: id as string,
          },
          headers: getSessionAccessToken(),
        });
        if (response.success) {
          fetchUsers();
          Popup(PopupVariant.SUCCESS, "Successfully Deleted.");
        }
      } catch (err: any) {
        console.error(err);
        Popup(PopupVariant.ERROR, err.response?.data?.error?.message);
      }
    }
  };

  const allowUser = async (id: string) => {
    const result = await Popup(
      PopupVariant.CONFIRM,
      `Do you want to allow ${users.find((user) => user.id === id)?.name}?`,
    );
    if (result.isConfirmed) {
      try {
        const response = await userApi.updateUser(undefined, {
          queries: {
            id: id as string,
          },
          headers: getSessionAccessToken(),
        });
        if (response.success) {
          fetchUsers();
          Popup(PopupVariant.SUCCESS, "Successfully allowed.");
        }
      } catch (err: any) {
        console.error(err);
        Popup(PopupVariant.ERROR, err.response?.data?.error?.message);
      }
    }
  };

  return (
    <div className="w-full p-5">
      <div className="flex flex-row justify-between">
        <p className="text-3xl font-semibold">Users</p>
        <div className="w-30">
          <ButtonCom
            onClick={addUserForm}
            className="bg-accent-500 px-11 text-white hover:bg-accent-600"
          >
            Add User
          </ButtonCom>
        </div>
      </div>

      <Table
        data={users}
        columns={usersColumns}
        onItemClicked={() => {}}
        noDataElement={<p>No Users Found.</p>}
      />
    </div>
  );
};

export default Users;
