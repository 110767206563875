import { ReactNode, useState } from "react";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { twMerge } from "tailwind-merge";
const Table = ({
  data,
  columns,
  onItemClicked,
  noDataElement,
}: {
  data: any;
  columns: any;
  onItemClicked: (row: any) => void;
  noDataElement: ReactNode;
}) => {
  const [sorting, setSorting] = useState([]);
  const [filtering, setFiltering] = useState("");
  const table = useReactTable({
    data: data,
    columns: columns,
    columnResizeMode: "onChange",
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting,
      globalFilter: filtering,
    },
    onSortingChange: setSorting as any,
    onGlobalFilterChange: setFiltering,
  });

  return (
    <>
      <input
        type="text"
        value={filtering}
        onChange={(e) => setFiltering(e.target.value)}
        placeholder="Search all columns"
        className="my-5 w-full rounded-md border border-gray-300 p-2 outline-gray-400"
      ></input>
      {data.length > 0 ? (
        <>
          <div className="max-h-[70dvh] w-full overflow-x-auto overflow-y-auto rounded-lg shadow-sm">
            <table className="md:text-md w-full divide-y divide-gray-300 text-xs">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => {
                  return (
                    <tr key={headerGroup.id} className="bg-gray-200">
                      {headerGroup.headers.map((header) => {
                        return (
                          <th
                            key={header.id}
                            colSpan={header.colSpan}
                            className="text-left hover:bg-gray-300"
                            style={{ width: header.getSize() }}
                          >
                            <div className="flex">
                              <div
                                className="flex w-full cursor-pointer justify-between p-3"
                                onClick={header.column.getToggleSortingHandler()}
                              >
                                <p>
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext(),
                                  )}
                                </p>
                                <p>
                                  {header.column.getIsSorted() &&
                                    { asc: "▲", desc: "▼" }[
                                      header.column.getIsSorted() || "asc"
                                    ]}
                                </p>
                              </div>
                              <div
                                {...{
                                  onDoubleClick: () =>
                                    header.column.resetSize(),
                                  onMouseDown: header.getResizeHandler(),
                                  onTouchStart: header.getResizeHandler(),
                                }}
                                className="w-2 min-w-2 cursor-col-resize hover:bg-gray-400"
                              />
                            </div>
                          </th>
                        );
                      })}
                    </tr>
                  );
                })}
              </thead>
              <tbody className="divide-y divide-gray-300">
                {table.getRowModel().rows.map((row, i) => {
                  return (
                    <tr
                      key={row.id}
                      onClick={() => onItemClicked(row.original)}
                      className={twMerge(
                        "cursor-pointer hover:bg-gray-200",
                        (i + 1) % 2 === 0 && "bg-gray-50",
                      )}
                    >
                      {row.getVisibleCells().map((rowCell: any) => {
                        return (
                          <td key={rowCell.id} className="px-3 py-2">
                            {flexRender(
                              rowCell.column.columnDef.cell,
                              rowCell.getContext(),
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <hr />
          <div className="mt-4 flex flex-wrap items-center justify-between gap-y-2 font-bold">
            <div>
              <p>
                Page {table.getState().pagination.pageIndex + 1} /{" "}
                {table.getPageCount()},{" "}
                {(table.getState().pagination.pageIndex + 1) *
                  table.getState().pagination.pageSize -
                  (table.getState().pagination.pageSize - 1)}{" "}
                -{" "}
                {Math.min(
                  (table.getState().pagination.pageIndex + 1) *
                    table.getState().pagination.pageSize,
                  data.length,
                )}{" "}
                of {data.length}
              </p>
            </div>

            <div className="gpa-x-2 text-md flex flex-nowrap gap-2 text-white">
              <button
                onClick={() => table.setPageIndex(0)}
                className="rounded-md bg-accent-500 px-3 py-1 hover:bg-accent-600"
              >
                {" "}
                {"<<"}
              </button>
              <button
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
                className="rounded-md bg-accent-500 px-3 py-1 hover:bg-accent-600"
              >
                {"<"}
              </button>
              <button
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
                className="rounded-md bg-accent-500 px-3 py-1 hover:bg-accent-600"
              >
                {">"}
              </button>
              <button
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                className="rounded-md bg-accent-500 px-3 py-1 hover:bg-accent-600"
              >
                {">>"}
              </button>
            </div>

            <select
              value={table.getState().pagination.pageSize}
              onChange={(e) => table.setPageSize(Number(e.target.value))}
              className="rounded-md bg-accent-500 px-3 py-1 text-white hover:bg-accent-600"
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option
                  key={pageSize}
                  value={pageSize}
                  className="font-delivery"
                >
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </>
      ) : (
        <>{noDataElement}</>
      )}
    </>
  );
};

export default Table;
