import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { FiMinusCircle } from "react-icons/fi";
import { HiIdentification, HiOutlineXCircle } from "react-icons/hi";
import { BsCameraFill } from "react-icons/bs";
import BgImage from "../../components/BgImage";
import Card from "../../components/Card";
import Button from "../../components/Button";
import { format } from "date-fns";
import { type Visitor } from "../../api/visitor";
import { Meeting, PublicMeeting } from "../../api/meeting";
import { type AllowedArea, type Area } from "../../api/areas";
import { QRCode } from "react-qrcode-logo";

const PublicStatus = ({
  visitor,
  meeting,
  areas,
  allowedAreas,
  isExpired,
  timeValidity,
  goBack,
}: {
  visitor: Visitor;
  meeting: PublicMeeting | undefined;
  areas: Area[];
  allowedAreas: AllowedArea[];
  isExpired: boolean;
  timeValidity: string;
  goBack: any;
}) => {
  const form = () => {
    return (
      <>
        {meeting && (
          <>
            <Card
              title={meeting.title}
              time={timeValidity}
              place={`Venue: ${meeting.venue}`}
              host={`Host: ${meeting.host}`}
            />
            <Card description={meeting.description} />
          </>
        )}
        <div className="flex flex-col space-y-10">
          <div className="flex w-full flex-col">
            {visitor.status === 0 && !isExpired && (
              <div className="flex min-h-28 w-full items-center justify-around rounded-lg border border-gray-300 border-opacity-55 bg-white py-2 shadow-lg">
                <div className="flex flex-col items-center justify-center">
                  <FiMinusCircle className="text-7xl text-gray-400" />
                </div>

                {visitor.createdAt && (
                  <div>
                    <h2 className="text-md font-semibold text-gray-400">
                      Status: <span className="text-gray-500">Pending</span>
                    </h2>
                    <p className="text-md font-semibold text-gray-400">
                      Submitted at{" "}
                      <span className="text-gray-400">
                        {format(visitor.createdAt, "MM/dd/yyyy HH:mm")}
                      </span>
                    </p>
                  </div>
                )}
              </div>
            )}
            {visitor.status === 1 && !isExpired && (
              <>
                <div className="flex min-h-28 w-full items-center justify-around gap-2 rounded-lg border border-gray-300 border-opacity-55 bg-white py-2 shadow-lg md:gap-0">
                  <div className="flex items-center justify-center px-2 py-3">
                    <QRCode
                      value={visitor.id}
                      size={200}
                      fgColor="#D40511"
                      logoImage="/images/qr-code-logo.png"
                      logoPadding={5}
                      logoWidth={68}
                      logoHeight={23}
                      ecLevel="H"
                      removeQrCodeBehindLogo
                    />
                  </div>

                  {visitor.timeStart && visitor.timeEnd && (
                    <div>
                      <h2 className="text-md font-semibold text-gray-400">
                        Status: <span className="text-green-500">Accepted</span>
                      </h2>
                      <div className="flex flex-col gap-1">
                        <p className="text-md font-semibold text-gray-400">
                          Effective Time:
                        </p>
                        <p className="text-md font-semibold text-gray-400 max-lg:text-xs">
                          {format(visitor.timeStart, "MM/dd/yyyy HH:mm")}-{" "}
                          {format(visitor.timeEnd, "MM/dd/yyyy HH:mm")}
                        </p>
                      </div>
                      <div>
                        <p className="text-md font-semibold text-gray-400">
                          Access Credentials:
                        </p>
                        <p className="text-md font-semibold text-gray-400">
                          {/* Add real data here  */}
                          Face, QR Code
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
            {visitor.status === -1 && !isExpired && (
              <>
                <div className="flex min-h-28 w-full items-center justify-around rounded-lg border border-gray-300 border-opacity-55 bg-white py-2 shadow-lg">
                  <div className="flex items-center justify-center">
                    {" "}
                    <HiOutlineXCircle className="text-7xl text-accent-500" />
                  </div>

                  {visitor.createdAt && (
                    <div>
                      <h2 className="text-md font-semibold text-gray-400">
                        Status: <span className="text-accent-500">Denied</span>
                      </h2>
                      <p className="text-md font-semibold text-gray-400 max-lg:text-xs">
                        Submitted at{" "}
                        {format(visitor.createdAt, "MM/dd/yyyy HH:mm")}
                      </p>
                    </div>
                  )}
                </div>
              </>
            )}
            {isExpired && (
              <div className="flex min-h-28 w-full items-center justify-around rounded-lg border border-gray-300 border-opacity-55 bg-white py-2 shadow-lg">
                <FiMinusCircle className="text-7xl text-gray-400" />

                {visitor.createdAt && (
                  <div>
                    <h2 className="text-md font-semibold text-gray-400">
                      Status: <span className="text-gray-500">Expired</span>
                    </h2>
                    <p className="text-md font-semibold text-gray-400 max-lg:text-xs">
                      Submitted at{" "}
                      <span className="text-gray-400">
                        {format(visitor.createdAt, "MM/dd/yyyy HH:mm")}
                      </span>
                    </p>
                  </div>
                )}
              </div>
            )}

            {visitor.status === 1 && !isExpired && (
              <>
                {meeting?.url && (
                  <div className="mt-4 flex w-full items-center justify-start gap-3 rounded-lg border border-gray-300 border-opacity-55 bg-white p-4 shadow-lg">
                    <img src="/images/msteams-icon.png" alt="msteams" />
                    <p className="text-md font-bold text-gray-400">
                      Microsoft Teams:{" "}
                      <Link className="text-blue-500 underline" to="">
                        Join Meeting
                      </Link>
                    </p>
                  </div>
                )}

                {allowedAreas.length > 0 && (
                  <div className="my-2 mt-4 flex min-h-6 w-full flex-col items-start justify-center gap-2 rounded-lg border border-gray-300 border-opacity-55 bg-white p-4 text-gray-400 shadow-lg">
                    <p className="text-md font-bold">Allowed Areas</p>
                    {areas.map((area) => (
                      <>
                        {allowedAreas.some(
                          (allowedArea) => allowedArea.areaId === area.id,
                        ) && (
                          <p className="text-md font-semibold" key={area.id}>
                            {area.name}
                          </p>
                        )}
                      </>
                    ))}
                  </div>
                )}
              </>
            )}
            <div className="mt-3 flex w-full flex-col shadow-lg">
              <div className="flex w-full">
                <div className="justify-cente aspect-w-9 aspect-h-16 flex min-h-6 w-full flex-col items-center gap-4 rounded-l-lg border border-gray-300 border-opacity-55 bg-white p-4">
                  <div className="flex h-full w-full items-center justify-center">
                    {visitor.companyId ? (
                      <img
                        alt="Government Issued ID"
                        height={312}
                        width={312}
                        src={visitor.companyId}
                      />
                    ) : (
                      <HiIdentification className="h-12 w-full text-gray-400" />
                    )}
                  </div>
                  <p className="text-sm font-semibold max-lg:text-xs">
                    Government-Issued ID
                  </p>
                </div>
                <div className="aspect-w-9 aspect-h-16 flex min-h-6 w-full flex-col items-center justify-center gap-4 rounded-r-lg border border-gray-300 border-opacity-55 bg-white p-4">
                  <div className="flex h-full w-full items-center justify-center">
                    {visitor.selfie ? (
                      <img
                        alt="Selfie"
                        height={312}
                        width={312}
                        src={visitor.selfie}
                      />
                    ) : (
                      <BsCameraFill className="h-12 w-full text-gray-400" />
                    )}
                  </div>
                  <p className="text-sm font-semibold max-lg:text-xs">Selfie</p>
                </div>
              </div>
            </div>
            <div className="mt-4 flex min-h-6 w-full flex-col">
              <label
                className="label text-md font-semibold text-white drop-shadow-md md:text-lg"
                htmlFor="email"
              >
                Email
              </label>
              <input
                name="email"
                value={visitor.email}
                disabled
                readOnly
                className="text-md min-h-6 w-full justify-center rounded-lg border border-gray-400 border-opacity-40 bg-white p-2 font-semibold shadow-lg disabled:bg-white"
              />
            </div>
            <div className="mt-4 flex min-h-6 w-full flex-col">
              <label
                className="label text-md font-semibold text-white drop-shadow-md md:text-lg"
                htmlFor="firstName"
              >
                First Name
              </label>
              <input
                name="firstName"
                value={visitor.firstName}
                disabled
                readOnly
                className="text-md min-h-6 w-full justify-center rounded-lg border border-gray-400 border-opacity-40 bg-white p-2 font-semibold shadow-lg disabled:bg-white"
              />
            </div>
            <div className="mt-4 flex min-h-6 w-full flex-col">
              <label
                className="label text-md font-semibold text-white drop-shadow-md md:text-lg"
                htmlFor="lastName"
              >
                Last Name
              </label>
              <input
                name="lastName"
                value={visitor.lastName}
                disabled
                readOnly
                className="text-md min-h-6 w-full justify-center rounded-lg border border-gray-400 border-opacity-40 bg-white p-2 font-semibold shadow-lg disabled:bg-white"
              />
            </div>
            <div className="mt-4 flex min-h-6 w-full flex-col">
              <label
                className="label text-md font-semibold text-white drop-shadow-md md:text-lg"
                htmlFor="company"
              >
                Company
              </label>
              <input
                name="company"
                value={visitor.company ? visitor.company : "N/A"}
                disabled
                readOnly
                className="text-md min-h-6 w-full justify-center rounded-lg border border-gray-400 border-opacity-40 bg-white p-2 font-semibold shadow-lg disabled:bg-white"
              />
            </div>

            <div className="mt-4 flex w-full flex-col">
              <label
                className="label text-md font-semibold text-white drop-shadow-md md:text-lg"
                htmlFor="vehiclePlateNumber"
              >
                Vehicle License Plate
              </label>
              <input
                name="vehiclePlateNumber"
                value={
                  visitor.vehiclePlateNumber
                    ? visitor.vehiclePlateNumber
                    : "N/A"
                }
                readOnly
                disabled
                className="flex min-h-6 w-full items-center justify-center rounded-lg border border-gray-400 border-opacity-40 bg-white p-2 font-semibold shadow-lg disabled:bg-white"
              />
            </div>
            <div className="mt-4 flex w-full flex-col">
              <label
                className="label text-md font-semibold text-white drop-shadow-md md:text-lg"
                htmlFor="contactPerson"
              >
                Contact Person
              </label>
              <input
                name="contactPerson"
                value={visitor.contactPerson}
                readOnly
                disabled
                className="text-md flex w-full items-center justify-center rounded-lg border border-gray-400 border-opacity-40 bg-white p-2 font-semibold shadow-lg disabled:bg-white"
              />
            </div>
            <div className="mt-4 flex w-full flex-col">
              <label
                className="label text-md font-semibold text-white drop-shadow-md md:text-lg"
                htmlFor="reason"
              >
                Reason
              </label>
              <input
                name="reason"
                value={visitor.reason}
                readOnly
                disabled
                className="text-md flex w-full items-center justify-center rounded-lg border border-gray-400 border-opacity-40 bg-white p-2 font-semibold shadow-lg disabled:bg-white"
              />
            </div>
          </div>
          <div className="mt-4 flex h-full w-full items-end justify-around gap-4">
            <Button
              onClick={goBack}
              className={
                "border border-gray-400 border-opacity-55 bg-white py-2 text-black hover:bg-gray-300"
              }
            >
              Go Back
            </Button>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="relative min-h-dvh w-full">
      <BgImage />
      <div className="flex min-h-dvh w-full flex-col items-center justify-center">
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.25 }}
            exit={{ opacity: 0 }}
            className="mb-10 mt-16 w-full gap-2 space-y-4 rounded-lg p-5
           text-sm  md:w-10/12  lg:w-1/2"
          >
            <h1 className="text-xl font-bold text-white drop-shadow-lg">
              Registration Details
            </h1>
            {form()}
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
};

export default PublicStatus;
