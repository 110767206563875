import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { format } from "date-fns";
import { Visitor } from "../../../api/visitor";
import Button from "../../../components/Button";
import { meetingsApi, Meeting } from "../../../api/meeting";
import Table from "../../../components/Table";
import { formatDatetimeRange } from "../../../lib/utils";
import { ReactComponent as GoBackIcon } from "../../../assets/icons/chevron_back.svg";
import { MenuButton, MenuItems } from "@headlessui/react";
import { EllipsisHorizontalIcon } from "@heroicons/react/20/solid";
import { Menu, MenuItem, Transition } from "@headlessui/react";
import { twMerge } from "tailwind-merge";
import Popup, { PopupVariant } from "../../../components/Popup";
import { getSession, getSessionAccessToken } from "../../../lib/session";
const columns = [
  {
    accessorKey: "email",
    header: "Email",
    cell: (props: any) => <p>{props.getValue()}</p>,
  },
  {
    accessorKey: "firstName",
    header: "First Name",
    cell: (props: any) => <p>{props.getValue()}</p>,
  },
  {
    accessorKey: "lastName",
    header: "Last Name",
    cell: (props: any) => <p>{props.getValue()}</p>,
  },
  {
    accessorKey: "timeStart",
    header: "Start Time",
    cell: (props: any) =>
      props.getValue() && <p>{format(props.getValue(), "MM/dd/yyyy HH:mm")}</p>,
  },
  {
    accessorKey: "timeEnd",
    header: "End Time",
    cell: (props: any) =>
      props.getValue() && <p>{format(props.getValue(), "MM/dd/yyyy HH:mm")}</p>,
  },
  {
    accessorKey: "company",
    header: "Company",
    cell: (props: any) => <p>{props.getValue()}</p>,
  },
  {
    accessorKey: "vehiclePlateNumber",
    header: "Vehicle Plate Number",
    cell: (props: any) => <p>{props.getValue()}</p>,
  },
  {
    accessorKey: "contactPerson",
    header: "Contact Person",
    cell: (props: any) => <p>{props.getValue()}</p>,
  },
  {
    accessorKey: "reason",
    header: "Reason",
    cell: (props: any) => <p>{props.getValue()}</p>,
  },
  {
    accessorKey: "status",
    header: "Status",
    cell: (props: any) => (
      <>
        {props.getValue() === 0 && "Pending"}
        {props.getValue() === 1 && "Approved"}
        {props.getValue() === -1 && "Rejected"}
      </>
    ),
  },
];

const Participants = () => {
  const navigate = useNavigate();
  const [visitors, setVisitors] = useState<Partial<Visitor>[]>([]);
  const { id } = useParams();
  const [meeting, setMeeting] = useState<Meeting>({} as Meeting);
  const [time, setTime] = useState(null);
  const [session, setSession] = useState<
    | {
        username: string | null;
        name: string | null;
        role: string | null;
        accessToken: string | null;
        refreshToken: string | null;
      }
    | undefined
  >(undefined);

  useEffect(() => {
    const fetchMeetings = async () => {
      try {
        if (id) {
          const response = await meetingsApi.getVisitorsByMeetingId({
            params: { id: id },
            headers: getSessionAccessToken(),
          });

          if (response) {
            setVisitors([
              ...response.participants,
              ...response.meeting.participants.filter(
                (participant) =>
                  response.participants.findIndex(
                    (_participant) => participant.email === _participant.email,
                  ) === -1,
              ),
            ]);
            setMeeting(response.meeting);
            setTime(
              formatDatetimeRange(
                response.meeting.timeStart,
                response.meeting.timeEnd,
              ) as any,
            );
          }
        }
      } catch (err: any) {
        console.error(err);
        Popup(PopupVariant.ERROR, err.response?.data?.error?.message);
      }
    };

    fetchMeetings();
    setSession(getSession());
  }, []);

  const cancelMeeting = async (meeting: Meeting) => {
    const result = await Popup(
      PopupVariant.CONFIRM,
      `Are you sure you want to cancel ${meeting.title}?`,
    );
    if (result.isConfirmed) {
      try {
        const res = await meetingsApi.cancelMeeting(undefined, {
          params: { id: meeting.id },
          headers: getSessionAccessToken(),
        });
        if (res.success) {
          navigate("/dashboard/meetings");
        }
      } catch (err: any) {
        Popup(PopupVariant.ERROR, err.response?.data?.error?.message);
      }
    }
  };

  return (
    <div className="relative w-full p-2 pt-12 md:p-5 md:pt-12">
      <div className="absolute left-0 top-0 flex h-10 w-16 items-center justify-center rounded-br-md bg-accent-500 fill-white hover:bg-accent-600">
        <GoBackIcon className="h-6 w-6" onClick={() => navigate(-1)} />
      </div>
      <div className="flex flex-row justify-between">
        <p className="text-3xl font-semibold">Meeting Details</p>
        {(session?.role === "admin" ||
          (session?.role === "user" && meeting.host === session?.name)) && (
          <Menu as="div" className="relative z-50 ml-6">
            <MenuButton className="-mx-2 flex items-center rounded-full border border-transparent p-2 text-gray-400 hover:text-gray-500">
              <span className="sr-only">Open menu</span>
              <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
            </MenuButton>
            <Transition
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <MenuItems className="absolute right-0 z-10 mt-3 w-36 origin-top-right divide-y divide-gray-100 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <MenuItem>
                    {({ focus }: { focus: boolean }) => (
                      <button
                        disabled={meeting.isCancelled}
                        onClick={async () => cancelMeeting(meeting)}
                        className={twMerge(
                          focus ? "bg-gray-100 text-gray-900" : "text-gray-700",
                          "block w-full px-4 py-2 text-left text-sm",
                          meeting.isCancelled && "text-gray-300",
                        )}
                      >
                        Cancel Meeting
                      </button>
                    )}
                  </MenuItem>
                </div>
              </MenuItems>
            </Transition>
          </Menu>
        )}
      </div>
      <div className="mb-2 mt-2 flex w-full flex-col gap-4 lg:flex-row">
        <div className="w-full rounded-md border border-gray-300 p-2">
          <p className="text-xl font-semibold">{meeting.title}</p>
          <p>{meeting.venue}</p>
          <p>{meeting.host}</p>
          <p>{time}</p>
        </div>
        <div className="w-full rounded-md border border-gray-300 p-2 ">
          <p>{meeting.description} </p>
        </div>
      </div>
      <h1 className="text-3xl font-semibold max-md:text-center">
        Participants
      </h1>
      <Table
        data={visitors}
        columns={columns}
        onItemClicked={(participant) => {
          if (
            session?.role === "admin" ||
            (session?.role === "user" && meeting.host === session?.name)
          ) {
            if (participant && participant.createdAt) {
              navigate(["/dashboard/status", participant.id].join("/"));
            }
          }
        }}
        noDataElement={<p>No Visitors Found.</p>}
      />
    </div>
  );
};

export default Participants;
