import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Formik, ErrorMessage, Field, FormikValues, Form } from "formik";
import Button from "../../../components/Button";
import { Settings, settingsApi, settingsSchema } from "../../../api/settings";
import ButtonLoad from "../../../components/ButtonLoad";
import { toFormikValidationSchema } from "zod-formik-adapter";
import Popup, { PopupVariant } from "../../../components/Popup";
import { getSessionAccessToken } from "../../../lib/session";

const SettingsPage = () => {
  const [initialValues, setInitialValues] = useState({
    canRegister: "Invited Only",
    isRequiredIdSelfie: false,
    isAutoAcceptInvitedVisitor: false,
    serverUrl: "",
    domainName: "",
    smtpHost: "smtp.gmail.com",
    smtpName: "",
    smtpUsername: "",
    smtpPassword: "",
    msTenantId: "",
    msClientId: "",
    msClientSecret: "",
  });

  const handleSubmit = async (values: Settings) => {
    const result = await Popup(
      PopupVariant.CONFIRM,
      "Do you want to save this settings?",
    );
    try {
      if (result.isConfirmed) {
        const response = await settingsApi.updateSettings(values, {
          headers: getSessionAccessToken(),
        });

        if (response.success) {
          Popup(PopupVariant.SUCCESS, "Successfully Saved");
        }
      }
    } catch (error) {
      console.log(error);
      Popup(PopupVariant.ERROR);
    }
  };

  const fetchDataSetting = async () => {
    try {
      const response = await settingsApi.getSettings({
        headers: getSessionAccessToken(),
      });
      if (response.success) {
        setInitialValues(response.settings);
      }
    } catch (err: any) {
      console.error(err);
      Popup(PopupVariant.ERROR, err.response?.data?.error?.message);
    }
  };

  useEffect(() => {
    fetchDataSetting();
  }, []);

  return (
    <div className="w-full space-y-5 p-2 md:p-5">
      <p className="text-3xl font-semibold">Settings</p>

      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.25 }}
          exit={{ opacity: 0 }}
          className="flex flex-col space-y-4"
        >
          <Formik
            initialValues={initialValues}
            validationSchema={toFormikValidationSchema(settingsSchema)}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({
              values,
              setFieldValue,
              isSubmitting,
              resetForm,
              submitForm,
              errors,
            }: any) => (
              <Form className="space-y-5">
                <p className="text-xl font-semibold">Registration</p>
                <ErrorMessage
                  name="registration"
                  component="div"
                  className="text-red-500"
                />
                <div className="flex flex-row">
                  <div className="flex w-full flex-row md:w-52">
                    <Field
                      id="invitedOnly"
                      name="canRegister"
                      type="radio"
                      className="accent-accent-500"
                      value="Invited Only"
                      checked={values.canRegister === "Invited Only"}
                    />
                    <label htmlFor="invitedOnly" className="ml-2">
                      Invited Only
                    </label>
                  </div>
                  <div className="flex w-full flex-row">
                    <Field
                      id="everyone"
                      name="canRegister"
                      type="radio"
                      className="accent-accent-500"
                      value="Everyone"
                      checked={values.canRegister === "Everyone"}
                    />
                    <label htmlFor="everyone" className="ml-2">
                      Everyone
                    </label>
                  </div>
                </div>
                <p className="text-xl font-semibold">Require ID and Selfie</p>
                <ErrorMessage
                  name="requiredIdSelfie"
                  component="div"
                  className="text-red-500"
                />
                <div className="flex flex-row">
                  <div className="flex w-full flex-row md:w-52">
                    <Field
                      id="RequiredIdSelfie"
                      name="RequiredIdSelfie"
                      className="accent-accent-500"
                      type="radio"
                      value={true}
                      checked={
                        values.isRequiredIdSelfie === true ||
                        values.isRequiredIdSelfie === "true"
                      }
                      onClick={() => {
                        setFieldValue("isRequiredIdSelfie", true);
                      }}
                    />
                    <label htmlFor="RequiredIdSelfie" className="ml-2">
                      Yes
                    </label>
                  </div>
                  <div className="flex w-full flex-row">
                    <Field
                      id="isNotRequiredIdSelfie"
                      name="RequiredIdSelfie"
                      type="radio"
                      className="accent-accent-500"
                      value={false}
                      checked={
                        values.isRequiredIdSelfie === false ||
                        values.isRequiredIdSelfie === "false"
                      }
                      onClick={() => {
                        setFieldValue("isRequiredIdSelfie", false);
                      }}
                    />
                    <label htmlFor="isNotRequiredIdSelfie" className="ml-2">
                      No
                    </label>
                  </div>
                </div>
                <p className="text-xl font-semibold">
                  Auto Accept Invited Visitor
                </p>
                <div className="flex flex-row">
                  <div className="flex w-full flex-row md:w-52">
                    <Field
                      id="isAutoAcceptInvitedVisitor"
                      name="AutoAcceptInvitedVisitor"
                      className="accent-accent-500"
                      type="radio"
                      value={true}
                      checked={
                        values.isAutoAcceptInvitedVisitor === true ||
                        values.isAutoAcceptInvitedVisitor === "true"
                      }
                      onClick={() => {
                        setFieldValue("isAutoAcceptInvitedVisitor", true);
                      }}
                    />
                    <label
                      htmlFor="isAutoAcceptInvitedVisitor"
                      className="ml-2"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="flex w-full flex-row">
                    <Field
                      id="isNotAutoAcceptInvitedVisitor"
                      name="AutoAcceptInvitedVisitor"
                      type="radio"
                      className="accent-accent-500"
                      value={false}
                      checked={
                        values.isAutoAcceptInvitedVisitor === false ||
                        values.isAutoAcceptInvitedVisitor === "false"
                      }
                      onClick={() => {
                        setFieldValue("isAutoAcceptInvitedVisitor", false);
                      }}
                    />
                    <label
                      htmlFor="isNotAutoAcceptInvitedVisitor"
                      className="ml-2"
                    >
                      No
                    </label>
                  </div>
                </div>
                <div className="form-control flex w-full flex-col lg:w-1/3">
                  <label htmlFor="serverUrl" className="font-semibold">
                    Server URL
                  </label>
                  <Field
                    id="serverUrl"
                    name="serverUrl"
                    type="text"
                    className="text-md rounded-md border border-gray-300 bg-white p-2 font-semibold shadow-md disabled:text-gray-500"
                    placeholder="Server URL"
                    value={values.serverUrl}
                  />
                  <ErrorMessage
                    name="serverUrl"
                    component="div"
                    className="font-semibold text-red-500"
                  />
                </div>
                <div className="form-control flex w-full flex-col lg:w-1/3">
                  <label htmlFor="domainName" className="font-semibold">
                    Domain Name
                  </label>
                  <Field
                    id="domainName"
                    name="domainName"
                    type="text"
                    className="text-md rounded-md border border-gray-300 bg-white p-2 font-semibold shadow-md disabled:text-gray-500"
                    placeholder="Domain Name"
                    value={values.domainName}
                  />
                  <ErrorMessage
                    name="domainName"
                    component="div"
                    className="font-semibold text-red-500"
                  />
                </div>
                <div className="form-control flex w-full flex-col lg:w-1/3">
                  <label htmlFor="smtpHost" className="font-semibold">
                    SMTP Host
                  </label>
                  <Field
                    id="smtpHost"
                    name="smtpHost"
                    type="text"
                    className="text-md rounded-md border border-gray-300 bg-white p-2 font-semibold shadow-md disabled:text-gray-500"
                    placeholder="SMTP Host"
                    value={values.smtpHost}
                  />
                  <ErrorMessage
                    name="smtpHost"
                    component="div"
                    className="font-semibold text-red-500"
                  />
                </div>
                <div className="form-control flex w-full flex-col lg:w-1/3">
                  <label htmlFor="smtpName" className="font-semibold">
                    Email Sender Name
                  </label>
                  <Field
                    id="smtpName"
                    name="smtpName"
                    type="text"
                    className="text-md rounded-md border border-gray-300 bg-white p-2 font-semibold shadow-md disabled:text-gray-500"
                    placeholder="Email Sender Name"
                    value={values.smtpName}
                  />
                  <ErrorMessage
                    name="domainName"
                    component="div"
                    className="font-semibold text-red-500"
                  />
                </div>
                <div className="form-control flex w-full flex-col lg:w-1/3">
                  <label htmlFor="smtpUsername" className="font-semibold">
                    SMTP Email
                  </label>
                  <Field
                    id="smtpUsername"
                    name="smtpUsername"
                    type="text"
                    className="text-md rounded-md border border-gray-300 bg-white p-2 font-semibold shadow-md disabled:text-gray-500"
                    placeholder="SMTP Email"
                    value={values.smtpUsername}
                  />
                  <ErrorMessage
                    name="smtpUsername"
                    component="div"
                    className="font-semibold text-red-500"
                  />
                </div>
                <div className="form-control flex w-full flex-col lg:w-1/3">
                  <label htmlFor="smtpPassword" className="font-semibold">
                    SMTP Password
                  </label>
                  <Field
                    id="smtpPassword"
                    name="smtpPassword"
                    type="text"
                    className="text-md rounded-md border border-gray-300 bg-white p-2 font-semibold shadow-md disabled:text-gray-500"
                    placeholder="SMTP Password"
                    value={values.smtpPassword}
                  />
                  <ErrorMessage
                    name="smtpPassword"
                    component="div"
                    className="font-semibold text-red-500"
                  />
                </div>
                <div className="form-control flex w-full flex-col lg:w-1/3">
                  <label htmlFor="msTenantId" className="font-semibold">
                    Microsoft Tenant ID
                  </label>
                  <Field
                    id="msTenantId"
                    name="msTenantId"
                    type="text"
                    className="text-md rounded-md border border-gray-300 bg-white p-2 font-semibold shadow-md disabled:text-gray-500"
                    placeholder="Microsoft Tenant ID"
                    value={values.msTenantId}
                  />
                  <ErrorMessage
                    name="msTenantId"
                    component="div"
                    className="font-semibold text-red-500"
                  />
                </div>
                <div className="form-control flex w-full flex-col lg:w-1/3">
                  <label htmlFor="msClientId" className="font-semibold">
                    Microsoft Client ID
                  </label>
                  <Field
                    id="msClientId"
                    name="msClientId"
                    type="text"
                    className="text-md rounded-md border border-gray-300 bg-white p-2 font-semibold shadow-md disabled:text-gray-500"
                    placeholder="Microsoft Client ID"
                    value={values.msClientId}
                  />
                  <ErrorMessage
                    name="msClientId"
                    component="div"
                    className="font-semibold text-red-500"
                  />
                </div>
                <div className="form-control flex w-full flex-col lg:w-1/3">
                  <label htmlFor="msClientSecret" className="font-semibold">
                    Microsoft Client Secret
                  </label>
                  <Field
                    id="msClientSecret"
                    name="msClientSecret"
                    type="text"
                    className="text-md rounded-md border border-gray-300 bg-white p-2 font-semibold shadow-md disabled:text-gray-500"
                    placeholder="Microsoft Client Secret"
                    value={values.msClientSecret}
                  />
                  <ErrorMessage
                    name="msClientSecret"
                    component="div"
                    className="font-semibold text-red-500"
                  />
                </div>
                <div className="flex flex-row gap-x-4 md:w-72">
                  <Button
                    type="submit"
                    className="bg-accent-500 text-white hover:bg-accent-600"
                  >
                    {isSubmitting ? <ButtonLoad /> : <p>Save</p>}
                  </Button>
                  <Button
                    type="button"
                    className="border border-gray-400 border-opacity-40 bg-gray-100 text-black hover:bg-gray-200"
                    onClick={() => {
                      fetchDataSetting();
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default SettingsPage;
