import { useState } from "react";
import { FiMinusCircle } from "react-icons/fi";
import { HiIdentification, HiOutlineXCircle } from "react-icons/hi";
import { BsCameraFill } from "react-icons/bs";
import Button from "../../components/Button";
import { format } from "date-fns";
import { visitorApi, type Visitor } from "../../api/visitor";
import { areasApi, type AllowedArea, type Area } from "../../api/areas";
import ButtonLoad from "../../components/ButtonLoad";
import { ReactComponent as GoBackIcon } from "../../assets/icons/chevron_back.svg";
import { useNavigate } from "react-router-dom";
import { QRCode } from "react-qrcode-logo";
import Popup, { PopupVariant } from "../../components/Popup";
import { getSessionAccessToken } from "../../lib/session";
import Checkbox from "../../components/Checkbox";

const DashboardStatus = ({
  visitor,
  areas,
  allowedAreas,
  setAllowedAreas,
  isExpired,
  fetchData,
}: {
  visitor: Visitor;
  areas: Area[];
  allowedAreas: AllowedArea[];
  setAllowedAreas: React.Dispatch<React.SetStateAction<AllowedArea[]>>;
  isExpired: boolean;
  fetchData: () => void;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const acceptVisitor = async () => {
    setIsSubmitting(true);
    if (visitor) {
      if (!isExpired) {
        const result = await Popup(
          PopupVariant.CONFIRM,
          "Do you want to accept this visitor?",
        );

        try {
          if (result.isConfirmed) {
            if (allowedAreas) {
              const response = await visitorApi.updateVisitor(
                {
                  id: visitor.id,
                  status: 1,
                },
                {
                  headers: getSessionAccessToken(),
                },
              );
              const areaResponse = await areasApi.addAllowedAreas(
                allowedAreas,
                {
                  headers: getSessionAccessToken(),
                },
              );
              if (response.success && areaResponse) {
                Popup(PopupVariant.SUCCESS, "Successfully Accepted");
                fetchData();
              }
            }
          }
        } catch (err: any) {
          console.error(err);
          Popup(PopupVariant.ERROR, err.response?.data?.error?.message);
        }
      }
    }
    setIsSubmitting(false);
  };

  const denyVisitor = async () => {
    setIsSubmitting(true);

    if (visitor && !isExpired) {
      const result = await Popup(
        PopupVariant.CONFIRM,
        "Do you want to decline this visitor?",
      );

      try {
        if (result.isConfirmed) {
          const response = await visitorApi.updateVisitor(
            {
              id: visitor.id,
              status: -1,
            },
            {
              headers: getSessionAccessToken(),
            },
          );
          const areaResponse = await areasApi.addAllowedAreas(allowedAreas, {
            headers: getSessionAccessToken(),
          });
          if (response.success && areaResponse) {
            Popup(PopupVariant.SUCCESS, "Successfully Declined");
            setIsSubmitting(false);
            fetchData();
          }
        }
      } catch (err: any) {
        setIsSubmitting(false);
        console.error(err);
        Popup(PopupVariant.ERROR, err.response?.data?.error?.message);
      }
    }
  };

  const toggleCheckbox = (id: string) => {
    if (allowedAreas.some((checkedArea) => checkedArea.areaId === id)) {
      // If the area is already allowed, remove it
      const updatedAllowedAreas = allowedAreas.filter(
        (checkedArea) => checkedArea.areaId !== id,
      );
      setAllowedAreas(updatedAllowedAreas);
    } else {
      // If the area is not allowed, add it
      const updatedAllowedAreas = [
        ...allowedAreas,
        { areaId: id, visitorId: visitor.id },
      ];
      setAllowedAreas(updatedAllowedAreas);
    }
  };

  const form = () => {
    return (
      <div className="flex flex-col space-y-10">
        <div className="flex w-full flex-col">
          {visitor.status === 0 && !isExpired && (
            <div className="flex min-h-28 w-full items-center justify-around rounded-lg border border-gray-300 border-opacity-55 bg-white py-2 shadow-lg">
              <div className="flex flex-col items-center justify-center">
                <FiMinusCircle className="text-7xl text-gray-400" />
              </div>

              {visitor.createdAt && (
                <div>
                  <h2 className="text-md font-semibold text-gray-400">
                    Status: <span className="text-gray-500">Pending</span>
                  </h2>
                  <p className="text-md font-semibold text-gray-400">
                    Submitted at{" "}
                    <span className="text-gray-400">
                      {format(visitor.createdAt, "MM/dd/yyyy HH:mm")}
                    </span>
                  </p>
                </div>
              )}
            </div>
          )}
          {visitor.status === 1 && !isExpired && (
            <>
              <div className="flex min-h-28 w-full items-center justify-around gap-2 rounded-lg border border-gray-300 border-opacity-55 bg-white py-2 shadow-lg md:gap-0">
                <div className="flex items-center justify-center px-2 py-3">
                  <QRCode
                    value={visitor.id}
                    size={200}
                    fgColor="#D40511"
                    logoImage="/images/qr-code-logo.png"
                    logoPadding={5}
                    logoWidth={68}
                    logoHeight={23}
                    ecLevel="H"
                    removeQrCodeBehindLogo
                  />
                </div>

                {visitor.timeStart && visitor.timeEnd && (
                  <div>
                    <h2 className="text-md font-semibold text-gray-400">
                      Status: <span className="text-green-500">Accepted</span>
                    </h2>
                    <div className="flex flex-col gap-1">
                      <p className="text-md font-semibold text-gray-400">
                        Effective Time:
                      </p>
                      <p className="text-md font-semibold text-gray-400 max-lg:text-xs">
                        {format(visitor.timeStart, "MM/dd/yyyy HH:mm")}-{" "}
                        {format(visitor.timeEnd, "MM/dd/yyyy HH:mm")}
                      </p>
                    </div>
                    <div>
                      <p className="text-md font-semibold text-gray-400">
                        Access Credentials:
                      </p>
                      <p className="text-md font-semibold text-gray-400">
                        {/* Add real data here  */}
                        Face, QR Code
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
          {visitor.status === -1 && !isExpired && (
            <>
              <div className="flex min-h-28 w-full items-center justify-around rounded-lg border border-gray-300 border-opacity-55 bg-white py-2 shadow-lg">
                <div className="flex items-center justify-center">
                  {" "}
                  <HiOutlineXCircle className="text-7xl text-accent-500" />
                </div>

                {visitor.createdAt && (
                  <div>
                    <h2 className="text-md font-semibold text-gray-400">
                      Status: <span className="text-accent-500">Denied</span>
                    </h2>
                    <p className="text-md font-semibold text-gray-400 max-lg:text-xs">
                      Submitted at{" "}
                      {format(visitor.createdAt, "MM/dd/yyyy HH:mm")}
                    </p>
                  </div>
                )}
              </div>
            </>
          )}
          {isExpired && (
            <div className="flex min-h-28 w-full items-center justify-around rounded-lg border border-gray-300 border-opacity-55 bg-white py-2 shadow-lg">
              <FiMinusCircle className="text-7xl text-gray-400" />

              {visitor.createdAt && (
                <div>
                  <h2 className="text-md font-semibold text-gray-400">
                    Status: <span className="text-gray-500">Expired</span>
                  </h2>
                  <p className="text-md font-semibold text-gray-400 max-lg:text-xs">
                    Submitted at{" "}
                    <span className="text-gray-400">
                      {format(visitor.createdAt, "MM/dd/yyyy HH:mm")}
                    </span>
                  </p>
                </div>
              )}
            </div>
          )}

          <div className="mt-3 flex w-full flex-col shadow-lg">
            <div className="flex w-full">
              <div className="justify-cente aspect-w-9 aspect-h-16 flex min-h-6 w-full flex-col items-center gap-4 rounded-l-lg border border-gray-300 border-opacity-55 bg-white p-4">
                <div className="flex h-full w-full items-center justify-center">
                  {visitor.companyId ? (
                    <img
                      alt="Government Issued ID"
                      height={312}
                      width={312}
                      src={visitor.companyId}
                    />
                  ) : (
                    <HiIdentification className="h-12 w-full text-gray-400" />
                  )}
                </div>
                <p className="text-sm font-semibold max-lg:text-xs">
                  Government-Issued ID
                </p>
              </div>
              <div className="aspect-w-9 aspect-h-16 flex min-h-6 w-full flex-col items-center justify-center gap-4 rounded-r-lg border border-gray-300 border-opacity-55 bg-white p-4">
                <div className="flex h-full w-full items-center justify-center">
                  {visitor.selfie ? (
                    <img
                      alt="Selfie"
                      height={312}
                      width={312}
                      src={`${visitor.selfie}`}
                    />
                  ) : (
                    <BsCameraFill className="h-12 w-full text-gray-400" />
                  )}
                </div>
                <p className="text-sm font-semibold max-lg:text-xs">Selfie</p>
              </div>
            </div>
          </div>
          <div className="mt-4 flex min-h-6 w-full flex-col">
            <label
              className="label text-md font-semibold drop-shadow-sm md:text-lg"
              htmlFor="email"
            >
              Email
            </label>
            <input
              name="email"
              value={visitor.email}
              disabled
              readOnly
              className="text-md min-h-6 w-full justify-center rounded-lg border border-gray-400 border-opacity-40 bg-white p-2 font-semibold shadow-lg"
            />
          </div>
          <div className="mt-4 flex min-h-6 w-full flex-col">
            <label
              className="label text-md font-semibold drop-shadow-sm md:text-lg"
              htmlFor="firstName"
            >
              First Name
            </label>
            <input
              name="firstName"
              value={visitor.firstName}
              disabled
              readOnly
              className="text-md min-h-6 w-full justify-center rounded-lg border border-gray-400 border-opacity-40 bg-white p-2 font-semibold shadow-lg"
            />
          </div>
          <div className="mt-4 flex min-h-6 w-full flex-col">
            <label
              className="label text-md font-semibold drop-shadow-sm md:text-lg"
              htmlFor="lastName"
            >
              Last Name
            </label>
            <input
              name="lastName"
              value={visitor.lastName}
              disabled
              readOnly
              className="text-md min-h-6 w-full justify-center rounded-lg border border-gray-400 border-opacity-40 bg-white p-2 font-semibold shadow-lg"
            />
          </div>
          <div className="mt-4 flex min-h-6 w-full flex-col">
            <label
              className="label text-md font-semibold drop-shadow-sm md:text-lg"
              htmlFor="company"
            >
              Company
            </label>
            <input
              name="company"
              value={visitor?.company ? visitor.company : "N/A"}
              disabled
              readOnly
              className="text-md min-h-6 w-full justify-center rounded-lg border border-gray-400 border-opacity-40 bg-white p-2 font-semibold shadow-lg"
            />
          </div>

          <div className="mt-4 flex w-full flex-col">
            <label
              className="label text-md font-semibold drop-shadow-sm md:text-lg"
              htmlFor="vehiclePlateNumber"
            >
              Vehicle License Plate
            </label>
            <input
              name="vehiclePlateNumber"
              value={
                visitor.vehiclePlateNumber ? visitor.vehiclePlateNumber : "N/A"
              }
              readOnly
              disabled
              className="flex min-h-6 w-full items-center justify-center rounded-lg border border-gray-400 border-opacity-40 bg-white p-2 font-semibold shadow-lg"
            />
          </div>
          <div className="mt-4 flex w-full flex-col">
            <label
              className="label text-md font-semibold drop-shadow-sm md:text-lg"
              htmlFor="contactPerson"
            >
              Contact Person
            </label>
            <input
              name="contactPerson"
              value={visitor.contactPerson}
              readOnly
              disabled
              className="text-md flex w-full items-center justify-center rounded-lg border border-gray-400 border-opacity-40 bg-white p-2 font-semibold shadow-lg"
            />
          </div>
          <div className="mt-4 flex w-full flex-col">
            <label
              className="label text-md font-semibold drop-shadow-sm md:text-lg"
              htmlFor="reason"
            >
              Reason
            </label>
            <input
              name="reason"
              value={visitor.reason}
              readOnly
              disabled
              className="text-md flex w-full items-center justify-center rounded-lg border border-gray-400 border-opacity-40 bg-white p-2 font-semibold shadow-lg"
            />
          </div>
          <div className=" flex w-full flex-col gap-2 rounded-md border border-gray-100  bg-gray-50 p-4 pt-2 shadow-inner">
            <div className="flex justify-between">
              <p className="font-bold text-gray-500">Allowed Areas</p>
            </div>
            <div key="exclude" className="flex w-full flex-wrap gap-4">
              {areas.map((area) => {
                return (
                  <div
                    key={area.id}
                    className="group space-x-2 rounded-md border border-gray-300 bg-white p-2 font-semibold shadow-sm"
                  >
                    <Checkbox id={area.id}>
                      <Checkbox.Indicator
                        disabled={false}
                        onChange={() => toggleCheckbox(area.id)}
                      />
                      <Checkbox.Label className="max-w-[45em]">
                        {area.name}
                      </Checkbox.Label>
                    </Checkbox>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="mt-4 flex h-full w-full items-end justify-around gap-4">
          <Button
            onClick={denyVisitor}
            className={
              "border border-gray-400 border-opacity-55 bg-white py-2 text-black hover:bg-gray-300"
            }
            disabled={!isExpired ? isSubmitting : true}
          >
            {isSubmitting ? <ButtonLoad /> : <p>Decline</p>}
          </Button>
          <Button
            onClick={() => acceptVisitor()}
            className={
              "border border-accent-600 border-opacity-55 bg-accent-500 py-2 text-white hover:bg-accent-600"
            }
            disabled={!isExpired ? isSubmitting : true}
          >
            {isSubmitting ? <ButtonLoad /> : <p>Accept</p>}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="relative space-y-3 p-5 pt-12">
      <div className="absolute left-0 top-0 flex h-10 w-16 items-center justify-center rounded-br-md bg-accent-500 fill-white hover:bg-accent-600">
        <GoBackIcon className="h-6 w-6" onClick={() => navigate(-1)} />
      </div>

      {form()}
    </div>
  );
};

export default DashboardStatus;
