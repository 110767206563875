import { useState, useEffect } from "react";
import BgImage from "../../components/BgImage";
import RegistrationForm from "./RegistrationForm";
import { useSearchParams } from "react-router-dom";
import {
  meetingsApi,
  type Meeting,
  publicMeetingApi,
  PublicMeeting,
} from "../../api/meeting";
import Loading from "../../components/Loading";
import InvitedOnly from "../../components/InvitedOnly";
import Popup, { PopupVariant } from "../../components/Popup";
import { getSessionAccessToken } from "../../lib/session";
import { publicSettingsApi } from "../../api/settings";
interface RegistrationSetting {
  canRegister: string;
  isRequiredIdSelfie: boolean;
}

function RegistrationDetails() {
  const [searchParams] = useSearchParams();
  const [meeting, setMeeting] = useState<PublicMeeting | undefined>(undefined);
  const [participant, setParticipant] = useState("");
  const [isFetching, setIsFetching] = useState(true);
  const [registrationSetting, setRegistrationSetting] =
    useState<RegistrationSetting>({
      canRegister: "Invite-Only",
      isRequiredIdSelfie: true,
    });
  const meetingId = searchParams.get("meetingId");
  const participantId = searchParams.get("participantId");

  useEffect(() => {
    const fetchMeeting = async () => {
      try {
        const response = await publicSettingsApi.getPublicSettings();
        if (response.success) {
          if (response.settings) {
            setRegistrationSetting(response.settings);
          }
        }
        if (meetingId) {
          const meetingData = await publicMeetingApi.getPublicMeeting({
            params: { id: meetingId },
          });
          if (meetingData) {
            setMeeting(meetingData);
            const participantEmail = meetingData.participants.find(
              (participant) => participant.id === participantId,
            )?.email;
            setParticipant(participantEmail as string);
          }
        }
      } catch (err: any) {
        console.error(err);
        Popup(PopupVariant.ERROR, err.response?.data?.error?.message);
      }

      setIsFetching(false);
    };

    fetchMeeting();
  }, []);

  return (
    <div className="relative min-h-dvh w-full">
      <div className="">
        <BgImage />
      </div>

      <div className="flex min-h-dvh w-full items-center justify-center">
        {isFetching ? (
          <Loading />
        ) : (
          <>
            {registrationSetting.canRegister === "Invited Only" &&
              meetingId && (
                <RegistrationForm
                  meeting={meeting}
                  participant={participant}
                  registrationSetting={registrationSetting}
                />
              )}
            {registrationSetting.canRegister === "Invited Only" &&
              !meetingId && <InvitedOnly />}
            {registrationSetting.canRegister === "Everyone" && (
              <RegistrationForm
                meeting={meeting}
                participant={participant}
                registrationSetting={registrationSetting}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default RegistrationDetails;
