import {
  eachDayOfInterval,
  eachMonthOfInterval,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  isSameDay,
  isSameMonth,
  isSameYear,
  startOfMonth,
  startOfToday,
  startOfWeek,
  startOfYear,
} from "date-fns";
import { Dispatch, SetStateAction } from "react";
import { twMerge } from "tailwind-merge";

import { Meeting } from "../../api/meeting";

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

export default function YearView({
  today,
  meetings,
  selectedDate,
  setSelectedDate,
  currentDate,
  setCurrentDate,
  setSelectedView,
  setSelectedMeeting,
}: {
  today: Date;
  meetings: Meeting[];
  selectedDate: Date;
  setSelectedDate: Dispatch<SetStateAction<Date>>;
  currentDate: Date;
  setCurrentDate: Dispatch<SetStateAction<Date>>;
  setSelectedView: Dispatch<SetStateAction<string>>;
  setSelectedMeeting: Dispatch<SetStateAction<Meeting | undefined>>;
}) {
  let months = eachMonthOfInterval({
    start: startOfYear(currentDate),
    end: endOfYear(currentDate),
  });
  return (
    <div className="mx-auto grid max-w-3xl grid-cols-1 gap-x-8 gap-y-16 px-4 py-16 sm:grid-cols-2 sm:px-6 xl:max-w-none xl:grid-cols-3 xl:px-8 2xl:grid-cols-4">
      {months.map((month) => (
        <section key={month.toString()} className="text-center">
          <a
            className="group"
            onClick={() => {
              setCurrentDate(month);
              setSelectedView("Month");
            }}
          >
            <h2
              className={twMerge(
                isSameYear(today, month) && isSameMonth(today, month)
                  ? "text-primary-500"
                  : "text-gray-900",
                "text-sm font-semibold  group-hover:text-accent-500",
              )}
            >
              {format(month, "MMMM")}
            </h2>
          </a>

          <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
            <div>S</div>
            <div>M</div>
            <div>T</div>
            <div>W</div>
            <div>T</div>
            <div>F</div>
            <div>S</div>
          </div>
          <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
            {eachDayOfInterval({
              start: startOfWeek(startOfMonth(month)),
              end: endOfWeek(endOfMonth(month)),
            }).map((day: Date, dayIdx: number) => (
              <button
                key={day.toString()}
                type="button"
                className={classNames(
                  isSameMonth(month, day)
                    ? "bg-white text-gray-900"
                    : "bg-gray-50 text-gray-50",
                  dayIdx === 0 && "rounded-tl-lg",
                  dayIdx === 6 && "rounded-tr-lg",
                  dayIdx ===
                    eachDayOfInterval({
                      start: startOfMonth(month),
                      end: endOfMonth(month),
                    }).length -
                      7 && "rounded-bl-lg",
                  dayIdx ===
                    eachDayOfInterval({
                      start: startOfMonth(month),
                      end: endOfMonth(month),
                    }).length -
                      1 && "rounded-br-lg",
                  "py-1.5 hover:bg-gray-100 focus:z-10",
                )}
                disabled={!isSameMonth(month, day)}
                onClick={() => {
                  setCurrentDate(day);
                  setSelectedView("Day");
                }}
              >
                <time
                  dateTime={day.toString()}
                  className={classNames(
                    isSameDay(day, today) &&
                      "bg-primary-500 font-semibold text-white",
                    "mx-auto flex h-7 w-7 items-center justify-center rounded-full",
                  )}
                >
                  {format(day, "d")}
                </time>
              </button>
            ))}
          </div>
        </section>
      ))}
    </div>
  );
}
