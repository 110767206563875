import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { authApi } from "../../api/auth";
import ButtonLoad from "../../components/ButtonLoad";
import { handleForgetPassword } from "../../components/ChangePassword";
import { MagicMotion } from "react-magic-motion";
import FieldError from "../../components/FieldError";

import { ReactComponent as UserCircleIcon } from "../../assets/icons/user_circle.svg";
import { ReactComponent as PasswordIcon } from "../../assets/icons/secure_and_compliant.svg";
import Popup, { PopupVariant } from "../../components/Popup";
import { deleteSession, getSession, setSession } from "../../lib/session";

const LogInForm = () => {
  const navigate = useNavigate();

  const initialValues = {
    username: "",
    password: "",
  };

  const formikRef = useRef(initialValues);

  const [invalidCredentials, setInvalidCredentials] = useState("");

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Please enter your username."),
    password: Yup.string().required("Please enter your password."),
  });

  const signInWithMS = async () => {
    try {
      const res = await authApi.msLogin();

      if (res.success) {
        window.location.replace(res.msLoginUrl);
      }
    } catch (err) {
      console.log(err);
      Popup(PopupVariant.ERROR);
    }
  };

  const handleSubmit = async (
    values: any,
    { setSubmitting, resetForm }: any,
  ) => {
    deleteSession();
    let error;
    if (error && !values.password) {
      return;
    }
    try {
      setSubmitting(true);

      const response = await authApi.login(values);
      if (response.success && response.type === "username") {
        setSession(response.user);
        Popup(PopupVariant.SUCCESS, "Successfully Logged In");
        navigate("/dashboard");
      }

      setSubmitting(false);
    } catch (err: any) {
      setSubmitting(false);
      console.log(err);
      if (err.response && err.response.status === 401) {
        setInvalidCredentials("Invalid credentials. Please try again.");
      } else {
        Popup(PopupVariant.ERROR, err.response?.data?.error?.message);
      }
    }
  };

  return (
    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
      <Formik
        innerRef={formikRef as any}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ submitForm, isSubmitting, values, errors }) => (
          <Form className="flex flex-col gap-6">
            <MagicMotion>
              <div className="flex flex-col gap-2">
                <div className="group flex items-center justify-center gap-2 rounded-md bg-white px-2 py-1 text-lg font-semibold shadow-sm">
                  <UserCircleIcon className="mb-0.5 h-6 min-h-6 w-6 min-w-6 fill-gray-400" />
                  <Field
                    id="username"
                    name="username"
                    type="text"
                    placeholder="Username"
                    className="w-full bg-transparent text-gray-900 placeholder:font-semibold placeholder:text-gray-400 focus:outline-none group-focus:ring-2 group-focus:ring-inset group-focus:ring-gray-200"
                    required
                  />
                </div>
                {errors.username && <FieldError error={errors.username} />}
              </div>
            </MagicMotion>

            <MagicMotion>
              <div className="flex flex-col gap-2">
                <div className="group flex items-center justify-center gap-2 rounded-md bg-white px-2 py-1 text-lg font-semibold shadow-sm">
                  <PasswordIcon className="mb-0.5 h-6 min-h-6 w-6 min-w-6 fill-gray-400" />
                  <Field
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Password"
                    className="w-full bg-transparent text-gray-900 placeholder:font-semibold placeholder:text-gray-400 focus:outline-none group-focus:ring-2 group-focus:ring-inset group-focus:ring-gray-200"
                    required
                  />
                </div>
                {errors.password && <FieldError error={errors.password} />}
              </div>
            </MagicMotion>

            {invalidCredentials && <FieldError error={invalidCredentials} />}

            <MagicMotion>
              <Button
                className="bg-accent-500 text-lg text-white hover:bg-accent-600"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? <ButtonLoad /> : <p key="exclude">Login</p>}
              </Button>
            </MagicMotion>
            <MagicMotion>
              <Button
                className="bg-accent-500 text-lg text-white hover:bg-accent-600"
                type="button"
                onClick={() => signInWithMS()}
              >
                <p key="exclude">Sign In with Microsoft</p>
              </Button>
            </MagicMotion>

            <MagicMotion>
              <div className="mt-2 text-sm">
                <button
                  type="button"
                  onClick={handleForgetPassword}
                  className="font-semibold text-accent-400 hover:text-accent-500"
                >
                  Forgot password?
                </button>
              </div>
            </MagicMotion>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default LogInForm;
