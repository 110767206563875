import { z } from "zod";
import { Zodios, makeApi, makeEndpoint } from "@zodios/core";
import { VMS_API_SERVER_URL } from "../lib/env";
import { errors } from "../types/Errors";

export const areaSchema = z.object({
  id: z.string().cuid2(),
  name: z.string().optional(),
});

export type Area = z.infer<typeof areaSchema>;

export const allowedAreaSchema = z.object({
  areaId: z.string().cuid2().optional(),
  visitorId: z.string().cuid2().optional(),
});

export type AllowedArea = z.infer<typeof allowedAreaSchema>;

// Define API endpoints

const getAreasEndpoint = makeEndpoint({
  method: "get",
  path: "/areas",
  alias: "getAreas",
  description: "Get all areas",
  response: z.array(areaSchema),
  errors: errors,
});

const getAllowedAreasByVisitorIdEndpoint = makeEndpoint({
  method: "get",
  path: "/allowedAreas/:id",
  alias: "getAllowedAreasByVisitorId",
  description: "Get allowed areas by visitor id",
  parameters: [
    {
      name: "id",
      type: "Path",
      description: "Visitor Id",
      schema: z.string().cuid2(),
    },
  ],
  response: z.array(allowedAreaSchema),
  errors: errors,
});

const addAllowedAreasEndpoint = makeEndpoint({
  method: "post",
  path: "/allowedAreas",
  alias: "addAllowedAreas",
  description: "Create allowed areas",
  parameters: [
    {
      name: "allowedAreas",
      type: "Body",
      description: "Allowed Areas",
      schema: z.array(allowedAreaSchema),
    },
  ],
  response: z.array(allowedAreaSchema),
  errors: errors,
});

// Define API
const api = makeApi([
  getAreasEndpoint,
  getAllowedAreasByVisitorIdEndpoint,
  addAllowedAreasEndpoint,
]);

export const areasApi = new Zodios(VMS_API_SERVER_URL, api, {
  axiosConfig: {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  },
});

// Public

const getPublicAreasEndpoint = makeEndpoint({
  method: "get",
  path: "/public/areas",
  alias: "getPublicAreas",
  description: "Get all areas",
  response: z.array(areaSchema),
  errors: errors,
});

const getPublicAllowedAreasByVisitorIdEndpoint = makeEndpoint({
  method: "get",
  path: "/public/allowedAreas/:id",
  alias: "getPublicAllowedAreasByVisitorId",
  description: "Get allowed areas by visitor id",
  parameters: [
    {
      name: "id",
      type: "Path",
      description: "Visitor Id",
      schema: z.string().cuid2(),
    },
  ],
  response: z.array(allowedAreaSchema),
  errors: errors,
});

// Combine all public endpoints into the API
const publicApi = makeApi([
  getPublicAreasEndpoint,
  getPublicAllowedAreasByVisitorIdEndpoint,
]);

export const publicAreaApi = new Zodios(VMS_API_SERVER_URL, publicApi, {
  axiosConfig: {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  },
});
