export const setSession = (user: {
  username: string;
  name: string;
  role: string;
  accessToken?: string | undefined;
  refreshToken?: string | undefined;
}) => {
  localStorage.setItem("username", user.username);
  localStorage.setItem("name", user.name);
  localStorage.setItem("role", user.role);
  if (user.accessToken) localStorage.setItem("accessToken", user.accessToken);
  if (user.refreshToken)
    localStorage.setItem("refreshToken", user.refreshToken);
};

export const getSession: () => {
  username: string | null;
  name: string | null;
  role: string | null;
  accessToken: string | null;
  refreshToken: string | null;
} = () => {
  return {
    username: localStorage.getItem("username"),
    role: localStorage.getItem("role"),
    name: localStorage.getItem("name"),
    accessToken: localStorage.getItem("accessToken"),
    refreshToken: localStorage.getItem("refreshToken"),
  };
};

export const deleteSession = () => {
  localStorage.removeItem("username");
  localStorage.removeItem("role");
  localStorage.removeItem("name");
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
};

export const getSessionAccessToken = (accessTokenOnly: boolean = false) => {
  return {
    Authorization: "Bearer " + localStorage.getItem("accessToken") || undefined,
  };
};
