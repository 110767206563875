import { Link } from "react-router-dom";
import { BsInstagram, BsYoutube, BsLinkedin } from "react-icons/bs";
import { BiLogoFacebook } from "react-icons/bi";

const Footer = ({
  footerItems,
}: {
  footerItems: { to: string; name: string }[];
}) => {
  return (
    <footer className="flex w-full flex-col items-center justify-center bg-stone-100 py-10 text-sm">
      <div className="flex w-full flex-col items-start justify-between px-5 sm:flex-row sm:px-16 lg:px-64">
        <div className="mb-5 space-y-10">
          <img
            src="/images/logo_black.png"
            alt="logo"
            className="h-5 w-auto lg:h-7"
          />
          <div className="flex flex-col flex-wrap gap-x-10 gap-y-5 sm:flex-row">
            {footerItems.map((footerItem) => (
              <Link
                to={footerItem.to}
                key={footerItem.name}
                className="text-gray-400 hover:text-black hover:underline"
              >
                {footerItem.name}
              </Link>
            ))}
          </div>
        </div>

        <div className="space-y-2 font-semibold">
          <p>Follow Us</p>
          <div className="flex flex-wrap gap-3  text-gray-500 lg:gap-5">
            <Link
              to="https://www.youtube.com/user/dhl"
              target="_blank"
              className="rounded-md p-1 hover:outline hover:outline-1 hover:outline-black"
            >
              <BsYoutube className="fill-gray-400 text-2xl" />
            </Link>
            <Link
              to="https://web.facebook.com/dhl?_rdc=1&_rdr"
              target="_blank"
              className="rounded-md p-1 hover:outline hover:outline-1 hover:outline-black"
            >
              <BiLogoFacebook className="fill-gray-400 text-2xl" />
            </Link>
            <Link
              to="https://www.linkedin.com/company/dhl"
              target="_blank"
              className="rounded-md p-1 hover:outline hover:outline-1 hover:outline-black"
            >
              <BsLinkedin className="fill-gray-400 text-2xl" />
            </Link>
            <Link
              to="https://www.instagram.com/dhl_global/"
              target="_blank"
              className="rounded-md p-1 hover:outline hover:outline-1 hover:outline-black"
            >
              <BsInstagram className="fill-gray-400 text-2xl" />
            </Link>
          </div>
        </div>
      </div>
      <div className="mt-5 space-y-2 text-center text-sm text-gray-500">
        <p>2024 © - all rights reserved</p>
        <p className="font-gokaizen">POWERED BY GOKAIZEN</p>
      </div>
    </footer>
  );
};

export default Footer;
