import { useState, useEffect } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import Header, { HeaderItem } from "../../components/Header";
import { handleForgetPassword } from "../../components/ChangePassword";
import { twMerge } from "tailwind-merge";

import { ReactComponent as AgendaIcon } from "../../assets/icons/my_agenda.svg";
import { ReactComponent as NewsletterIcon } from "../../assets/icons/newsletter.svg";
import { ReactComponent as TabletIcon } from "../../assets/icons/tablet.svg";
import { ReactComponent as UserCircleIcon } from "../../assets/icons/user_circle.svg";
import { ReactComponent as WalkingIcon } from "../../assets/icons/walking.svg";
import { ReactComponent as SettingsIcon } from "../../assets/icons/settings.svg";
import { authApi } from "../../api/auth";
import {
  deleteSession,
  getSession,
  getSessionAccessToken,
} from "../../lib/session";

const Layout = () => {
  const [session, setSession] = useState(getSession());

  const location = useLocation();

  const dashboardNavItems: HeaderItem[] = [
    {
      icon: AgendaIcon,
      to: "/dashboard/meetings",
      name: "Meetings",
      hidden: false,
      onItemClick: undefined,
    },
    {
      icon: WalkingIcon,
      to: "/dashboard/visitors",
      name: session.role !== "visitor" ? "Visitor Requests" : "Visits",
      hidden: false,
      onItemClick: undefined,
    },
    {
      icon: NewsletterIcon,
      to: "/dashboard/emailtemplate",
      name: "Email Templates",
      hidden: session.role !== "admin",
      onItemClick: undefined,
    },
    {
      icon: TabletIcon,
      to: "/dashboard/meetingRooms",
      name: "Devices",
      hidden: session.role !== "admin",
      onItemClick: undefined,
    },
    {
      icon: UserCircleIcon,
      to: "/dashboard/users",
      name: "Users",
      hidden: session.role !== "admin",
      onItemClick: undefined,
    },
    {
      icon: SettingsIcon,
      to: "/dashboard/settings",
      name: "Settings",
      hidden: session.role !== "admin",
      onItemClick: undefined,
    },
  ];

  const headerItems: HeaderItem[] = [
    {
      icon: undefined,
      to: "#",
      name: "Change Password",
      hidden: session.role === "visitor" || session.role === "pending",
      onItemClick: () => {
        handleForgetPassword();
      },
    },
    {
      icon: undefined,
      to: "/login",
      name: "Logout",
      hidden: false,
      onItemClick: () => {
        try {
          authApi.logout({
            headers: getSessionAccessToken(),
          });
        } catch (error) {}
        deleteSession();
      },
    },
  ];

  return (
    <div className="flex h-full min-h-dvh w-full flex-col">
      <Header
        headerItems={headerItems}
        burgerMenuItems={dashboardNavItems.concat(headerItems)}
      />

      {session.role !== "pending" ? (
        <div className="flex h-full w-full flex-grow justify-between gap-x-5 bg-gray-50 px-2 py-5 md:px-6 lg:px-8">
          <div className="hidden w-1/6 gap-6 px-2 py-5 lg:flex lg:flex-col lg:px-5 ">
            {dashboardNavItems.map((navItem) => (
              <Link
                to={navItem.to}
                className={twMerge(
                  "flex items-center gap-4 rounded-md border-opacity-55 fill-gray-400 p-3 text-gray-600 hover:bg-gray-100 md:p-2",
                  location.pathname.includes(navItem.to) &&
                    "border border-gray-100 bg-gray-100 fill-gray-600 text-gray-800 shadow-inner",
                  navItem.hidden && "hidden",
                )}
                key={navItem.name}
                hidden={navItem.hidden}
              >
                {navItem.icon && (
                  <navItem.icon className="h-8 min-h-8 w-8 min-w-8" />
                )}
                {navItem.name}
              </Link>
            ))}
          </div>
          <div className="h-auto w-full overflow-hidden rounded-lg border border-gray-300 border-opacity-55 bg-white shadow-lg">
            <Outlet />
          </div>
        </div>
      ) : (
        <div className="h-auto w-full overflow-hidden ">
          <Outlet />
        </div>
      )}
    </div>
  );
};

export default Layout;
