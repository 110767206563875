function Card({
  title,
  time,
  place,
  host,
  description,
}: {
  title?: string | undefined;
  time?: string | undefined;
  place?: string | undefined;
  host?: string | undefined;
  description?: string | undefined;
}) {
  return (
    <div className="card rounded-lg border border-gray-300 border-opacity-55 bg-white p-10 text-center shadow-lg">
      {title && <h1 className="text-xl font-extrabold">{title}</h1>}
      {time && <h3 className="text-lg font-semibold text-gray-500">{time}</h3>}
      {place && (
        <h3 className="text-md font-semibold text-gray-500">{place}</h3>
      )}
      {host && <h5 className="text-md font-semibold text-gray-500">{host}</h5>}
      {description && (
        <p className="text-md font-semibold text-gray-800">{description}</p>
      )}
    </div>
  );
}

export default Card;
