const PendingAccount = () => {
  return (
    <div className="h-[65vh] px-10 py-32 lg:px-52">
      <p className="text-5xl font-extrabold text-accent-500">Sorry!</p>
      <hr className="my-6 h-[3px] w-16 bg-black" />
      <p className="text-lg text-gray-600">
        You are not allowed or your account is pending for confirmation to
        access this page. Please wait for the confirmation from admin or contact
        support.
      </p>
    </div>
  );
};

export default PendingAccount;
