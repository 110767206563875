import { motion } from "framer-motion";
import { twMerge } from "tailwind-merge";
import { Field } from "formik";

export default function Select({
  name,
  values,
}: {
  name: string;
  values: { displayName: string; value: string }[];
}) {
  return (
    <div className="flex w-full flex-col gap-2">
      <div role="group" className="flex w-full gap-4 max-md:flex-col">
        {values.map((value, i) => (
          <div
            key={i.toString()}
            className="group flex items-center gap-2 rounded-md border border-gray-300 bg-white p-2 font-semibold shadow-sm"
          >
            <div className="inline-flex items-center">
              <label
                className="relative flex cursor-pointer items-center rounded-full"
                htmlFor={name}
              >
                <Field
                  id={i.toString()}
                  name={name}
                  type="radio"
                  value={value.value}
                  className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border  text-accent-500 transition-all before:absolute before:left-2/4 before:top-2/4 before:block before:h-8 before:w-8 before:-translate-x-2/4 before:-translate-y-2/4 before:rounded-full before:bg-gray-500 before:opacity-0 before:transition-opacity checked:border-accent-500 checked:before:bg-accent-500 hover:before:opacity-10"
                />
                <span className="pointer-events-none absolute left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4 text-accent-500 opacity-0 transition-opacity peer-checked:opacity-100">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-3.5 w-3.5"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                  >
                    <circle data-name="ellipse" cx="8" cy="8" r="8"></circle>
                  </svg>
                </span>
              </label>

              <motion.label
                className={twMerge(
                  "relative ml-2 overflow-hidden",
                  "max-w-[45em]",
                )}
                htmlFor={i.toString()}
                initial={false}
                transition={{
                  duration: 0.3,
                  ease: "easeOut",
                }}
              >
                {value.displayName}
              </motion.label>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
