import {
  MenuButton,
  Transition,
  Menu,
  MenuItem,
  MenuItems,
} from "@headlessui/react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDownIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/20/solid";
import {
  add,
  endOfWeek,
  format,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from "date-fns";
import { Dispatch, SetStateAction } from "react";
import { twMerge } from "tailwind-merge";

import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/plus.svg";
import { motion } from "framer-motion";
import { MeetingRoom } from "../../api/meetingRooms";

function formatViewTimescale(date: Date, view: string) {
  switch (view) {
    case "Year":
      return format(date, "yyyy");
    case "Month":
      return format(date, "MMMM yyyy");
    case "Day":
      return format(date, "MMMM dd, yyyy");
    default:
      return "";
  }
}

function CalendarHeader({
  today,
  currentDate,
  setCurrentDate,
  selectedView,
  setSelectedView,
  meetingRooms,
  selectedMeetingRoom,
  setSelectedMeetingRoom,
}: {
  today: Date;
  currentDate: Date;
  setCurrentDate: Dispatch<SetStateAction<Date>>;
  selectedView: string;
  setSelectedView: Dispatch<SetStateAction<string>>;
  meetingRooms: MeetingRoom[];
  selectedMeetingRoom: MeetingRoom | undefined;
  setSelectedMeetingRoom: Dispatch<SetStateAction<MeetingRoom | undefined>>;
}) {
  function previous() {
    let previous = new Date();
    switch (selectedView) {
      case "Year":
        previous = add(startOfYear(currentDate), { years: -1 });
        break;
      case "Month":
        previous = add(startOfMonth(currentDate), { months: -1 });
        break;
      case "Day":
        previous = add(startOfDay(currentDate), { days: -1 });
        break;
      default:
        break;
    }
    setCurrentDate(previous);
  }

  function next() {
    let next = new Date();
    switch (selectedView) {
      case "Year":
        next = add(startOfYear(currentDate), { years: 1 });
        break;
      case "Month":
        next = add(startOfMonth(currentDate), { months: 1 });
        break;
      case "Day":
        next = add(startOfDay(currentDate), { days: 1 });
        break;
      default:
        break;
    }
    setCurrentDate(next);
  }

  return (
    <header className="sticky z-50 flex items-center justify-between border-b border-gray-200 bg-white px-6 py-4 lg:flex-none">
      <h1 className="flex text-base font-semibold leading-6 text-gray-900">
        <time className="flex" dateTime={format(currentDate, "MMMM-yyyy")}>
          {formatViewTimescale(currentDate, selectedView)}
        </time>
      </h1>
      <div className="flex items-center">
        <Menu as="div" className="relative mr-4">
          <MenuButton
            type="button"
            className="flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            {selectedMeetingRoom && selectedMeetingRoom.name}
            <ChevronDownIcon
              className="-mr-1 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </MenuButton>

          <Transition
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <MenuItems className="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {meetingRooms.map((meetingRoom) => (
                  <MenuItem key={meetingRoom.id}>
                    {({ focus }: { focus: boolean }) => (
                      <button
                        onClick={() => setSelectedMeetingRoom(meetingRoom)}
                        className={twMerge(
                          focus ? "bg-gray-100 text-gray-900" : "text-gray-700",
                          "block w-full px-4 py-2 text-left text-sm",
                        )}
                      >
                        {meetingRoom.name}
                      </button>
                    )}
                  </MenuItem>
                ))}
              </div>
            </MenuItems>
          </Transition>
        </Menu>
        <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch">
          <button
            type="button"
            onClick={previous}
            className="flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-gray-300 pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50"
          >
            <span className="sr-only">Previous month</span>
            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
          </button>
          <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
          <button
            type="button"
            onClick={next}
            className="flex h-9 w-12 items-center justify-center rounded-r-md border-y border-r border-gray-300 pl-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50"
          >
            <span className="sr-only">Next month</span>
            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden md:ml-4 md:flex md:items-center">
          <Menu as="div" className="relative">
            <MenuButton
              type="button"
              className="flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              {selectedView} view
              <ChevronDownIcon
                className="-mr-1 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </MenuButton>

            <Transition
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <MenuItems className="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <MenuItem>
                    {({ focus }: { focus: boolean }) => (
                      <button
                        onClick={() => setSelectedView("Day")}
                        className={twMerge(
                          focus ? "bg-gray-100 text-gray-900" : "text-gray-700",
                          "block w-full px-4 py-2 text-left text-sm",
                        )}
                      >
                        Day view
                      </button>
                    )}
                  </MenuItem>
                  <MenuItem>
                    {({ focus }: { focus: boolean }) => (
                      <button
                        onClick={() => setSelectedView("Month")}
                        className={twMerge(
                          focus ? "bg-gray-100 text-gray-900" : "text-gray-700",
                          "block w-full px-4 py-2 text-left text-sm",
                        )}
                      >
                        Month view
                      </button>
                    )}
                  </MenuItem>
                  <MenuItem>
                    {({ focus }: { focus: boolean }) => (
                      <button
                        onClick={() => setSelectedView("Year")}
                        className={twMerge(
                          focus ? "bg-gray-100 text-gray-900" : "text-gray-700",
                          "block w-full px-4 py-2 text-left text-sm",
                        )}
                      >
                        Year view
                      </button>
                    )}
                  </MenuItem>
                </div>
              </MenuItems>
            </Transition>
          </Menu>
        </div>
        <Menu as="div" className="relative ml-6 md:hidden">
          <MenuButton className="-mx-2 flex items-center rounded-full border border-transparent p-2 text-gray-400 hover:text-gray-500">
            <span className="sr-only">Open menu</span>
            <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
          </MenuButton>

          <Transition
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <MenuItems className="absolute right-0 z-10 mt-3 w-36 origin-top-right divide-y divide-gray-100 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                <MenuItem>
                  {({ focus }: { focus: boolean }) => (
                    <button
                      onClick={() => {
                        setCurrentDate(today);
                      }}
                      className={twMerge(
                        focus ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block w-full px-4 py-2 text-left text-sm",
                      )}
                    >
                      Go to today
                    </button>
                  )}
                </MenuItem>
              </div>
              <div className="py-1">
                <MenuItem>
                  {({ focus }: { focus: boolean }) => (
                    <button
                      onClick={() => setSelectedView("Day")}
                      className={twMerge(
                        focus ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block w-full px-4 py-2 text-left text-sm",
                      )}
                    >
                      Day view
                    </button>
                  )}
                </MenuItem>
                <MenuItem>
                  {({ focus }: { focus: boolean }) => (
                    <button
                      onClick={() => setSelectedView("Month")}
                      className={twMerge(
                        focus ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block w-full px-4 py-2 text-left text-sm",
                      )}
                    >
                      Month view
                    </button>
                  )}
                </MenuItem>
                <MenuItem>
                  {({ focus }: { focus: boolean }) => (
                    <button
                      onClick={() => setSelectedView("Year")}
                      className={twMerge(
                        focus ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block w-full px-4 py-2 text-left text-sm",
                      )}
                    >
                      Year view
                    </button>
                  )}
                </MenuItem>
              </div>
            </MenuItems>
          </Transition>
        </Menu>
      </div>
    </header>
  );
}

export default CalendarHeader;
